import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AgreementListItem,
  AgreementLogListItem,
  AgreementVersion,
  AgreementVersionListItem,
  CreateAgreementRequest,
  CreateVersionRequest,
  UpdateAgreementRequest,
  UpdateVersionRequest,
} from '@models/index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AgreementsService {
  private readonly url = `${environment.bffUrl}/agreements`;
  constructor(private http: HttpClient) {}

  getAgreements(): Observable<AgreementListItem[]> {
    return this.http.get<AgreementListItem[]>(this.url);
  }

  getAgreement(id: string): Observable<AgreementListItem> {
    return this.http.get<AgreementListItem>(`${this.url}/${id}`);
  }

  getAgreementVersion(agreementId: string, versionId: string): Observable<AgreementVersion> {
    return this.http.get<AgreementVersion>(`${this.url}/${agreementId}/versions/${versionId}`);
  }

  addAgreement(agreement: CreateAgreementRequest): Observable<AgreementListItem> {
    return this.http.post<AgreementListItem>(this.url, agreement);
  }

  addAgreementVersion(agreementId: string, version: CreateVersionRequest): Observable<AgreementVersion> {
    return this.http.post<AgreementVersion>(`${this.url}/${agreementId}/versions`, version);
  }

  deleteAgreement(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  deleteAgreementVersion(agreementId: string, versionId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${agreementId}/versions/${versionId}`);
  }

  publishAgreementVersion(agreementId: string, versionId: string): Observable<AgreementVersion> {
    return this.http.post<AgreementVersion>(`${this.url}/${agreementId}/versions/${versionId}/publish`, undefined);
  }

  updateAgreement(id: string, agreement: UpdateAgreementRequest): Observable<AgreementListItem> {
    return this.http.put<AgreementListItem>(`${this.url}/${id}`, agreement);
  }

  updateAgreementVersion(agreementId: string, versionId: string, version: UpdateVersionRequest): Observable<AgreementVersion> {
    return this.http.put<AgreementVersion>(`${this.url}/${agreementId}/versions/${versionId}`, version);
  }

  getLogs(id: string): Observable<AgreementLogListItem[]> {
    return this.http.get<AgreementLogListItem[]>(`${environment.bffUrl}/agreement-log/${id}`);
  }

  getVersions(id: string): Observable<AgreementVersionListItem[]> {
    return this.http.get<AgreementVersionListItem[]>(`${this.url}/${id}/versions`);
  }
}
