import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.topics);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsTopicCreateLoading = createSelector(selectModuleState, state => state.topicCreateLoading);
export const selectTopics = createSelector(selectModuleState, state => state.topics);

export const selectSelectedTopic = createSelector(selectModuleState, state => state.selectedTopic);
export const selectSelectedTopicId = createSelector(selectModuleState, state => state.selectedTopic && state.selectedTopic.id);
export const selectSelectedTopicLoading = createSelector(selectModuleState, state => state.selectedTopicLoading);

export const selectSubscribers = createSelector(selectModuleState, state => state.subscribers);
export const selectSubscribersLoading = createSelector(selectModuleState, state => state.subscribers);

export const selectOwners = createSelector(selectModuleState, state => state.owners);
export const selectOwnersLoading = createSelector(selectModuleState, state => state.owners);

export const selectSelectedTopicOwner = createSelector(selectModuleState, state => state.selectedOwner);
export const selectSelectedOwnerId = createSelector(selectModuleState, state => state.selectedOwner && state.selectedOwner.id);
export const selectIsTopicOwnerCreateLoading = createSelector(selectModuleState, state => state.topicOwnerCreateLoading);
export const selectSelectedOwnerLoading = createSelector(selectModuleState, state => state.selectedOwnerLoading);
