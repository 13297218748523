import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { permissionsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './permissions.actions';
import { PermissionService } from './permissions.service';

@Injectable()
export class PermissionsEffects {
  onGetPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.permissionService.getPermissions().pipe(
          map(permissions => fromActions.getAllComplete({ permissions })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onGetPermissionRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSelectedPermissionRoles),
      switchMap(({ id, filter }) =>
        this.permissionService.getPermissionRoles(id, filter).pipe(
          map(roles => fromActions.getSelectedPermissionRolesComplete({ roles })),
          catchError(err => of(fromActions.getSelectedPermissionRolesError({ err }))),
        ),
      ),
    ),
  );

  onAddNewPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewPermission),
      switchMap(({ permission }) =>
        this.permissionService.addPermission(permission).pipe(
          map(permission => fromActions.addNewPermissionComplete({ permission })),
          catchError(err => of(fromActions.addNewPermissionError({ err }))),
        ),
      ),
    ),
  );

  onAddNewPermissionComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewPermissionComplete),
        switchMap(({ permission }) => {
          return this.router.navigate(['permissions', permission.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onSelectPermissionListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectPermission),
      switchMap(({ id }) =>
        this.store.pipe(
          select(permissionsSelectors.selectPermissions),
          switchMap(permissions => {
            const permission = permissions.find(p => p.id === id);
            return of(fromActions.selectPermissionComplete({ permission: permission ? permission : null }));
          }),
        ),
      ),
    ),
  );

  onPatchSelectedPermissionRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.patchSelectedPermissionRoles),
      switchMap(({ roles, filter }) =>
        this.store.pipe(
          select(permissionsSelectors.selectSelectedPermissionId),
          take(1),
          switchMap(id => {
            return this.permissionService.patchPermissionRoles(id!, roles, filter).pipe(
              map(roles => fromActions.patchSelectedPermissionRolesComplete({ roles })),
              catchError(err => of(fromActions.patchSelectedPermissionRolesError({ err }))),
            );
          }),
        ),
      ),
    ),
  );

  onUpdatePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updatePermission),
      switchMap(({ permission }) =>
        this.permissionService.updatePermission(permission).pipe(
          map(permission => fromActions.updatePermissionComplete({ permission })),
          catchError(err => of(fromActions.updatePermissionError({ err }))),
        ),
      ),
    ),
  );

  onDeletePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedPermission),
      switchMap(() =>
        this.store.pipe(
          select(permissionsSelectors.selectSelectedPermissionId),
          take(1),
          switchMap(id =>
            this.permissionService.deletePermission(id!).pipe(
              map(() => fromActions.deleteSelectedPermissionComplete()),
              catchError(err => of(fromActions.deleteSelectedPermissionError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeletePermissionComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedPermissionComplete),
      tap(() => this.router.navigate(['permissions'])),
      map(() => fromActions.getAll()),
    ),
  );

  onUpdatePermissionDetailsComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updatePermissionComplete),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToPermissionActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToPermissionActions),
        tap(({ id }) => this.router.navigate(['permissions', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['permissions'])),
      map(() => fromActions.resetState({ selectedPermission: null })),
    ),
  );

  onGoToSelectedPermissionActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedPermissionActions),
        switchMap(() =>
          this.store.pipe(
            select(permissionsSelectors.selectSelectedPermissionId),
            take(1),
            tap(id => this.router.navigate(['permissions', id])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGetPermissionAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPermissionAttributes),
      switchMap(({ permissionId }) =>
        this.permissionService.getPermissionAttributes(permissionId).pipe(
          map(attributes => fromActions.getPermissionAttributesComplete({ attributes })),
          catchError(err => of(fromActions.getPermissionAttributesError({ err }))),
        ),
      ),
    ),
  );

  onAddPermissionAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addPermissionAttribute),
      switchMap(({ attribute }) =>
        this.permissionService.addPermissionAttribute(attribute).pipe(
          map(attribute => fromActions.addPermissionAttributeComplete({ attribute })),
          catchError(err => of(fromActions.addPermissionAttributeError({ err }))),
        ),
      ),
    ),
  );

  onAddPermissionAttributeComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addPermissionAttributeComplete),
        switchMap(({ attribute }) => {
          return this.router.navigate(['permissions', attribute.permissionId, 'attributes']);
        }),
      ),
    { dispatch: false },
  );

  onDeletePermissionAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deletePermissionAttribute),
      switchMap(({ attributeId }) =>
        this.permissionService.deletePermissionAttribute(attributeId).pipe(
          map(() => fromActions.deletePermissionAttributeComplete({ attributeId })),
          catchError(err => of(fromActions.deletePermissionAttributeError({ err }))),
        ),
      ),
    ),
  );

  onGetPermissionListItemForAttributePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPermissionForAttributePage),
      switchMap(({ id }) =>
        this.store.pipe(
          select(permissionsSelectors.selectPermissions),
          switchMap(permissions => {
            if (permissions.length) {
              const permission = permissions.find(p => p.id === id);
              return of(fromActions.getPermissionForAttributePageComplete({ permission: permission ? permission : null }));
            }
            return this.permissionService.getPermissions().pipe(
              map(permissions => {
                const permission = permissions.find(p => p.id === id);
                return fromActions.getPermissionForAttributePageComplete({ permission: permission ? permission : null });
              }),
              catchError(err => of(fromActions.getPermissionForAttributePageError({ err }))),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}
}
