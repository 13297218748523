import { PermissionTypes, RoleAttribute, RoleDefault, RoleInfo } from '@models/authorization';
import { createReducer, on } from '@ngrx/store';
import { sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './roles.actions';
export interface State {
  roles: RoleInfo[];
  isLoading: boolean;

  activeRole: RoleInfo | null;
  activeRoleLoading: boolean;

  permissions: PermissionTypes[];
  permissionsLoading: boolean;

  defaults: RoleDefault[];
  defaultsLoading: boolean;
  defaultsChangeInProgress: boolean;

  attributes: RoleAttribute[];
  attributesLoading: boolean;
  attributesChangeInProgress: boolean;

  activeAttribute: RoleAttribute | null;
  activeAttributeLoading: boolean;
}

export const initialState: State = {
  roles: [],
  isLoading: false,

  activeRole: null,
  activeRoleLoading: false,

  permissions: [],
  permissionsLoading: false,

  defaults: [],
  defaultsLoading: false,
  defaultsChangeInProgress: false,

  attributes: [],
  attributesLoading: false,
  attributesChangeInProgress: false,

  activeAttribute: null,
  activeAttributeLoading: false,
};

export const rolesReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true, activeRoleLoading: true })),
  on(fromActions.getAllComplete, (state, { roles }) => ({
    ...state,
    isLoading: false,
    activeRoleLoading: false,
    roles: sortAlphabetically([...roles], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false, activeRoleLoading: false })),

  on(fromActions.addNewRole, state => ({ ...state, isLoading: true })),
  on(fromActions.addNewRoleComplete, (state, { role }) => ({
    ...state,
    isLoading: false,
    roles: sortAlphabetically([...state.roles, role], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addNewRoleError, state => ({ ...state, isLoading: false })),

  on(fromActions.updateRole, state => ({ ...state, activeRoleLoading: true })),
  on(fromActions.updateRoleComplete, (state, { role }) => ({
    ...state,
    activeRole: role,
    activeRoleLoading: false,
  })),
  on(fromActions.updateRoleError, state => ({
    ...state,
    activeRole: { ...state.activeRole! },
    activeRoleLoading: false,
  })),

  on(fromActions.deleteSelectedRole, state => ({ ...state, activeRoleLoading: true })),
  on(fromActions.deleteSelectedRoleComplete, state => ({
    ...state,
    activeRoleLoading: false,
  })),
  on(fromActions.deleteSelectedRoleError, state => ({
    ...state,
    activeRoleLoading: false,
  })),

  on(fromActions.selectRole, state => ({ ...state, activeRoleLoading: true, permissions: [], defaults: [], attributes: [] })),
  on(fromActions.selectRoleComplete, (state, { role }) => ({
    ...state,
    activeRoleLoading: false,
    activeRole: role,
  })),
  on(fromActions.selectRoleError, state => ({
    ...state,
    activeRoleLoading: false,
  })),

  on(fromActions.selectRoleAttribute, state => ({ ...state, activeAttributeLoading: true })),
  on(fromActions.selectRoleAttributeComplete, (state, { attribute }) => ({
    ...state,
    activeAttributeLoading: false,
    activeAttribute: attribute,
  })),
  on(fromActions.selectRoleAttributeError, state => ({
    ...state,
    activeAttributeLoading: false,
  })),

  on(fromActions.getRoleDefaults, state => ({ ...state, defaultsLoading: true })),
  on(fromActions.getRoleDefaultsComplete, (state, { defaults }) => ({
    ...state,
    defaultsLoading: false,
    defaults,
  })),
  on(fromActions.getRoleDefaultsError, state => ({
    ...state,
    defaultsLoading: false,
  })),

  on(fromActions.addRoleDefault, state => ({ ...state, defaultsLoading: true, defaultsChangeInProgress: true })),
  on(fromActions.addRoleDefaultComplete, (state, { defaults }) => ({
    ...state,
    defaultsLoading: false,
    defaultsChangeInProgress: false,
    defaults,
  })),
  on(fromActions.addRoleDefaultError, state => ({
    ...state,
    defaultsChangeInProgress: false,
    defaultsLoading: false,
  })),

  on(fromActions.deleteRoleDefault, state => ({ ...state, defaultsLoading: true, defaultsChangeInProgress: true })),
  on(fromActions.deleteRoleDefaultComplete, (state, { roleDefaultId }) => ({
    ...state,
    defaultsLoading: false,
    defaultsChangeInProgress: false,
    defaults: state.defaults.filter(d => d.id !== roleDefaultId),
  })),
  on(fromActions.deleteRoleDefaultError, state => ({
    ...state,
    defaultsChangeInProgress: false,
    defaultsLoading: false,
  })),

  on(fromActions.getRoleAttributes, state => ({ ...state, attributesLoading: true })),
  on(fromActions.getRoleAttributesComplete, (state, { attributes }) => ({
    ...state,
    attributesLoading: false,
    attributes: sortAlphabetically<RoleAttribute>(attributes, (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getRoleAttributesError, state => ({
    ...state,
    attributesLoading: false,
  })),

  on(fromActions.addRoleAttribute, state => ({ ...state, attributesLoading: true, attributesChangeInProgress: true })),
  on(fromActions.addRoleAttributeComplete, (state, { attribute }) => ({
    ...state,
    attributesLoading: false,
    attributesChangeInProgress: false,

    attributes: sortAlphabetically<RoleAttribute>([...state.attributes, attribute], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addRoleAttributeError, state => ({
    ...state,

    attributesLoading: false,
    attributesChangeInProgress: false,
  })),

  on(fromActions.updateRoleAttribute, state => ({ ...state, activeAttributeLoading: true })),
  on(fromActions.updateRoleAttributeComplete, (state, { attribute }) => ({
    ...state,
    activeAttribute: attribute,
    activeAttributeLoading: false,
  })),
  on(fromActions.updateRoleAttributeError, state => ({
    ...state,
    activeAttribute: { ...state.activeAttribute! },
    activeAttributeLoading: false,
  })),

  on(fromActions.deleteActiveRoleAttribute, state => ({ ...state, attributesLoading: true, attributesChangeInProgress: true })),
  on(fromActions.deleteActiveRoleAttributeComplete, state => ({
    ...state,
    attributesLoading: false,
    attributesChangeInProgress: false,
  })),
  on(fromActions.deleteActiveRoleAttributeError, state => ({
    ...state,
    attributesLoading: false,
    attributesChangeInProgress: false,
  })),

  on(fromActions.getRolePermissions, state => ({ ...state, permissionsLoading: true })),
  on(fromActions.getRolePermissionsComplete, (state, { permissions }) => ({
    ...state,
    permissionsLoading: false,
    permissions: sortAlphabetically<PermissionTypes>(permissions, (a, b) => a.localeCompare(b)),
  })),
  on(fromActions.getRolePermissionsError, state => ({
    ...state,
    permissionsLoading: false,
  })),
);
