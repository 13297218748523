import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './customers.actions';
import { CustomersService } from './customers.service';

@Injectable()
export class CustomersEffects {
  onGetCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(({ partnerId, includeMarkedForDeletion }) =>
        this.customersService.getCustomers(partnerId, includeMarkedForDeletion).pipe(
          map(customers => fromActions.getAllComplete({ customers })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly customersService: CustomersService,
  ) {}
}
