import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.fileManagementFiles);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsFileCreateLoading = createSelector(selectModuleState, state => state.fileCreateLoading);
export const selectFiles = createSelector(selectModuleState, state => state.files);

export const selectSelectedFile = createSelector(selectModuleState, state => state.selectedFile);
export const selectSelectedFileId = createSelector(selectModuleState, state => state.selectedFile && state.selectedFile.id);
export const selectSelectedFileLoading = createSelector(selectModuleState, state => state.selectedFileLoading);
