import { HttpErrorResponse } from '@angular/common/http';
import { Topic, TopicCreate, TopicOwner, TopicUpdate } from '@models/marketing/topics/topics.model';
import { SubscriptionListItem } from '@models/subscriptions';
import { createAction, props } from '@ngrx/store';
import { State } from './topics.reducer';

const NAMESPACE = '[Topics]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ topics: Topic[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addTopic = createAction(`${NAMESPACE} AddTopic`, props<{ topic: TopicCreate }>());
export const addTopicComplete = createAction(`${NAMESPACE} AddTopicComplete`, props<{ topic: Topic }>());
export const addTopicError = createAction(`${NAMESPACE} AddTopicError`, props<{ err: HttpErrorResponse }>());

export const addTopicOwner = createAction(`${NAMESPACE} AddTopicOwner`, props<{ id: string; ownerEmail: string }>());
export const addTopicOwnerComplete = createAction(`${NAMESPACE} AddTopicOwnerComplete`, props<{ owner: TopicOwner; topicId: string }>());
export const addTopicOwnerError = createAction(`${NAMESPACE} AddTopicOwnerError`, props<{ err: HttpErrorResponse }>());

export const updateTopic = createAction(`${NAMESPACE} UpdateTopic`, props<{ id: string; topic: TopicUpdate }>());
export const updateTopicComplete = createAction(`${NAMESPACE} UpdateTopicComplete`, props<{ topic: Topic }>());
export const updateTopicError = createAction(`${NAMESPACE} UpdateTopicError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedTopic = createAction(`${NAMESPACE} DeleteSelectedTopic`);
export const deleteSelectedTopicComplete = createAction(`${NAMESPACE} DeleteSelectedTopicComplete`);
export const deleteSelectedTopicError = createAction(`${NAMESPACE} DeleteSelectedTopicError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedTopicOwner = createAction(`${NAMESPACE} DeleteSelectedTopicOwner`);
export const deleteSelectedTopicOwnerComplete = createAction(`${NAMESPACE} DeleteSelectedTopicOwnerComplete`, props<{ topicId: string }>());
export const deleteSelectedTopicOwnerError = createAction(
  `${NAMESPACE} DeleteSelectedTopicOwnerError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectTopic = createAction(`${NAMESPACE} SelectTopic`, props<{ id: string }>());
export const selectTopicComplete = createAction(`${NAMESPACE} SelectTopicComplete`, props<{ topic: Topic }>());
export const selectTopicError = createAction(`${NAMESPACE} SelectTopicError`, props<{ err: HttpErrorResponse }>());

export const selectTopicOwner = createAction(`${NAMESPACE} SelectTopicOwner`, props<{ topicId: string; ownerId: string }>());
export const selectTopicOwnerComplete = createAction(`${NAMESPACE} SelectTopicOwnerComplete`, props<{ owner: TopicOwner }>());
export const selectTopicOwnerError = createAction(`${NAMESPACE} SelectTopicOwnerError`, props<{ err: HttpErrorResponse }>());

export const getTopicSubscribers = createAction(`${NAMESPACE} GetTopicSubscribers`, props<{ id: string }>());
export const getTopicSubscribersComplete = createAction(
  `${NAMESPACE} GetTopicSubscribersComplete`,
  props<{ subscribers: SubscriptionListItem[] }>(),
);
export const getTopicSubscribersError = createAction(`${NAMESPACE} GetTopicSubscribersError`, props<{ err: HttpErrorResponse }>());

export const getTopicOwners = createAction(`${NAMESPACE} GetTopicOwners`, props<{ id: string }>());
export const getTopicOwnersComplete = createAction(`${NAMESPACE} GetTopicOwnersComplete`, props<{ owners: TopicOwner[] }>());
export const getTopicOwnersError = createAction(`${NAMESPACE} GetTopicOwnersError`, props<{ err: HttpErrorResponse }>());

export const goToTopicActions = createAction(`${NAMESPACE} GoToTopicActions`, props<{ id: string }>());
export const goToTopicOwnerActions = createAction(`${NAMESPACE} GoToTopicOwnerActions`, props<{ ownerId: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);
export const goToOwnersListPage = createAction(`${NAMESPACE} GoToOwnersListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
