import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { rolesSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, combineLatest, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './roles.actions';
import { RolesService } from './roles.service';

@Injectable()
export class RolesEffects {
  onGetRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.rolesService.getRoles().pipe(
          map(roles => fromActions.getAllComplete({ roles })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddNewRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewRole),
      switchMap(({ role }) =>
        this.rolesService.addNewRole(role).pipe(
          map(role => fromActions.addNewRoleComplete({ role })),
          catchError(err => of(fromActions.addNewRoleError({ err }))),
        ),
      ),
    ),
  );

  onAddNewRoleComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewRoleComplete),
        switchMap(({ role }) => {
          return this.router.navigate(['roles', role.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onSelectRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectRole),
      switchMap(({ id }) =>
        this.store.pipe(
          select(rolesSelectors.selectRoles),
          take(1),
          switchMap(roles => {
            if (roles.length) {
              const role = roles.find(t => t.id === id)!;
              return of(fromActions.selectRoleComplete({ role }));
            } else {
              return this.rolesService.getRole(id).pipe(
                map(role => fromActions.selectRoleComplete({ role })),
                catchError(err => of(fromActions.selectRoleError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onSelectRoleAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectRoleAttribute),
      switchMap(({ id }) =>
        this.store.pipe(
          select(rolesSelectors.selectAttributes),
          take(1),
          switchMap(attributes => {
            if (attributes.length) {
              const attribute = attributes.find(t => t.id === id)!;
              return of(fromActions.selectRoleAttributeComplete({ attribute }));
            } else {
              return this.rolesService.getRoleAttribute(id).pipe(
                map(attribute => fromActions.selectRoleAttributeComplete({ attribute })),
                catchError(err => of(fromActions.selectRoleAttributeError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onGetRolePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getRolePermissions),
      switchMap(({ id }) =>
        this.rolesService.getRolePermissions(id).pipe(
          map(permissions => fromActions.getRolePermissionsComplete({ permissions })),
          catchError(err => of(fromActions.getRolePermissionsError({ err }))),
        ),
      ),
    ),
  );

  onGetRoleDefaults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getRoleDefaults),
      switchMap(({ id }) =>
        this.rolesService.getRoleDefaults(id).pipe(
          map(defaults => fromActions.getRoleDefaultsComplete({ defaults })),
          catchError(err => of(fromActions.getRoleDefaultsError({ err }))),
        ),
      ),
    ),
  );

  onAddRoleDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addRoleDefault),
      switchMap(({ addRoleDefaultRequest }) =>
        this.rolesService.addRoleDefault(addRoleDefaultRequest).pipe(
          map(defaults => fromActions.addRoleDefaultComplete({ defaults })),
          catchError(err => of(fromActions.addRoleDefaultError({ err }))),
        ),
      ),
    ),
  );

  onDeleteRoleDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRoleDefault),
      switchMap(({ roleDefaultId, roleId }) =>
        this.rolesService.deleteRoleDefault(roleDefaultId, roleId).pipe(
          map(() => fromActions.deleteRoleDefaultComplete({ roleDefaultId })),
          catchError(err => of(fromActions.deleteRoleDefaultError({ err }))),
        ),
      ),
    ),
  );

  onGetRoleAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getRoleAttributes),
      switchMap(({ roleId: id }) =>
        this.rolesService.getRoleAttributes(id).pipe(
          map(attributes => fromActions.getRoleAttributesComplete({ attributes })),
          catchError(err => of(fromActions.getRoleAttributesError({ err }))),
        ),
      ),
    ),
  );

  onAddRoleAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addRoleAttribute),
      switchMap(({ addRoleAttributeRequest }) =>
        this.rolesService.createRoleAttribute(addRoleAttributeRequest).pipe(
          map(attribute => fromActions.addRoleAttributeComplete({ attribute })),
          catchError(err => of(fromActions.addRoleAttributeError({ err }))),
        ),
      ),
    ),
  );

  onAddRoleAttributeComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addRoleAttributeComplete),
        switchMap(({ attribute }) => {
          return this.router.navigate(['roles', attribute.roleId, 'attributes', attribute.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onDeleteActiveRoleAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteActiveRoleAttribute),
      switchMap(() =>
        combineLatest({
          roleId: this.store.pipe(select(rolesSelectors.selectActiveRoleId), filter(isNotNullOrUndefined), take(1)),
          attributeId: this.store.pipe(select(rolesSelectors.selectActiveAttributeId), filter(isNotNullOrUndefined), take(1)),
        }).pipe(
          switchMap(({ roleId, attributeId }) =>
            this.rolesService.deleteRoleAttribute(attributeId).pipe(
              map(() => fromActions.deleteActiveRoleAttributeComplete({ roleId })),
              catchError(err => of(fromActions.deleteActiveRoleAttributeError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteActiveRoleAttributeComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteActiveRoleAttributeComplete),
      tap(({ roleId }) => this.router.navigate(['roles', roleId, 'attributes'])),
      map(({ roleId }) => fromActions.getRoleAttributes({ roleId: roleId })),
    ),
  );

  onUpdateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRole),
      switchMap(({ role }) => {
        const { id, ...rest } = role;
        return this.rolesService.updateRole(id, rest).pipe(
          map(role => fromActions.updateRoleComplete({ role })),
          catchError(err => of(fromActions.updateRoleError({ err }))),
        );
      }),
    ),
  );

  onUpdateRoleComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRoleComplete),
      map(() => fromActions.getAll()),
    ),
  );

  onUpdateRoleAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRoleAttribute),
      switchMap(({ attribute, attributeId }) => {
        return this.rolesService.updateRoleAttribute(attributeId, attribute).pipe(
          map(attribute => fromActions.updateRoleAttributeComplete({ attribute })),
          catchError(err => of(fromActions.updateRoleAttributeError({ err }))),
        );
      }),
    ),
  );

  onUpdateRoleAttributeComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRoleAttributeComplete),
      map(({ attribute }) => fromActions.getRoleAttributes({ roleId: attribute.roleId })),
    ),
  );

  onDeleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedRole),
      switchMap(() =>
        this.store.pipe(
          select(rolesSelectors.selectActiveRoleId),
          take(1),
          switchMap(id =>
            this.rolesService.deleteRole(id!).pipe(
              map(() => fromActions.deleteSelectedRoleComplete()),
              catchError(err => of(fromActions.deleteSelectedRoleError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteRoleComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedRoleComplete),
      tap(() => this.router.navigate(['roles'])),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToRoleActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToRoleActions),
        tap(({ id }) => this.router.navigate(['roles', id])),
      ),
    { dispatch: false },
  );

  onGoToRoleAttributesList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToRoleAttributesList),
        tap(({ id }) => this.router.navigate(['roles', id, 'attributes'])),
      ),
    { dispatch: false },
  );

  onGoToRoleAttributeActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToRoleAttributeActions),
        switchMap(({ attributeId }) =>
          this.store.pipe(
            select(rolesSelectors.selectActiveRole),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['roles', id, 'attributes', attributeId])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['roles'])),
      map(() => fromActions.resetState({ activeRole: null })),
    ),
  );

  onGoToSelectedRoleActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedRoleActions),
        switchMap(() =>
          this.store.pipe(
            select(rolesSelectors.selectActiveRoleId),
            take(1),
            tap(id => this.router.navigate(['roles', id])),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly rolesService: RolesService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}
}
