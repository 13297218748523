import { CommonData } from '@models/common';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './common-data.actions';
import { CommonDataObj } from './common-data.service';
export interface State {
  commonData: CommonDataObj;
  isLoading: boolean;

  isSelectedCommonDataLoading: boolean;
  selectedCommonData: CommonData | null;
}

export const initialState: State = {
  commonData: {
    audience: [],
    region: [],
    membershipLevel: [],
    language: [],
    country: [],
    workload: [],
    others: [],
  },
  isLoading: false,

  isSelectedCommonDataLoading: false,
  selectedCommonData: null,
};

export const commonDataReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getLanguages, state => ({ ...state, isLoading: true })),
  on(fromActions.getLanguagesComplete, (state, { languages }) => ({
    ...state,
    isLoading: false,
    commonData: { ...state.commonData, language: languages },
  })),
  on(fromActions.getLanguagesError, state => ({ ...state, isLoading: false })),

  on(fromActions.getMembershipLevels, state => ({ ...state, isLoading: true })),
  on(fromActions.getMembershipLevelsComplete, (state, { membershipLevels }) => ({
    ...state,
    isLoading: false,
    commonData: { ...state.commonData, membershipLevel: membershipLevels },
  })),
  on(fromActions.getMembershipLevelsError, state => ({ ...state, isLoading: false })),

  on(fromActions.getRegions, state => ({ ...state, isLoading: true })),
  on(fromActions.getRegionsComplete, (state, { regions }) => ({
    ...state,
    isLoading: false,
    commonData: { ...state.commonData, region: regions },
  })),
  on(fromActions.getRegionsError, state => ({ ...state, isLoading: false })),

  on(fromActions.getAllCommonData, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllCommonDataComplete, (state, { commonData }) => ({ ...state, isLoading: false, commonData })),
  on(fromActions.getAllCommonDataError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectCommonData, state => ({
    ...state,
    isSelectedCommonDataLoading: true,
  })),
  on(fromActions.selectCommonDataComplete, (state, { commonData }) => ({
    ...state,
    selectedCommonData: commonData,
    isSelectedCommonDataLoading: false,
  })),
  on(fromActions.selectCommonDataError, state => ({ ...state, isSelectedCommonDataLoading: false })),
);
