import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { newsArticlesSelectors, topicsActions, topicsSelectors } from '@appState';
import { NewsArticle } from '@models/news-article';
import { Store, select } from '@ngrx/store';
import { formatDate, isNotNullOrUndefined } from 'cui-components';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatProgressSpinnerModule],
  selector: 'hui-news-article-preview',
  templateUrl: './news-article-preview.component.html',
  styleUrls: ['./news-article-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsArticlePreviewComponent {
  isLoading$: Observable<boolean> = combineLatest({
    newsArticleLoading: this.store.pipe(select(newsArticlesSelectors.selectSelectedNewsArticleLoading)),
    topicsLoading: this.store.pipe(select(topicsSelectors.selectIsLoading)),
  }).pipe(
    map(({ newsArticleLoading, topicsLoading }) => {
      return newsArticleLoading || topicsLoading;
    }),
  );

  newsArticle$: Observable<{ newsArticle: NewsArticle | null; topics: string[] }> = combineLatest({
    newsArticle: this.store.pipe(select(newsArticlesSelectors.selectSelectedNewsArticle), filter(isNotNullOrUndefined)),
    topics: this.store.pipe(select(topicsSelectors.selectTopics)),
  }).pipe(
    map(({ newsArticle, topics }) => {
      if (!topics.length) {
        this.store.dispatch(topicsActions.getAll());
      }
      let articleTopics: string[] = [];
      newsArticle.topics.forEach(item => {
        let topic = topics.find(t => t.id === item);
        if (topic) {
          articleTopics.push(topic.name.toUpperCase());
        }
      });
      this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml(newsArticle.content!);

      return { newsArticle, topics: articleTopics };
    }),
  );

  trustedHtml: SafeHtml = '';
  environment = environment;

  getDateString(date: Date): string {
    return formatDate(date, 'dd MMMM, yyyy');
  }

  constructor(
    private readonly store: Store<AppState>,
    public sanitizer: DomSanitizer,
  ) {}
}
