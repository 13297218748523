import { FuncRoleAssignment, RoleInfo } from '@models/authorization';
import { createReducer, on } from '@ngrx/store';
import { findAndRemove, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './functional-roles.actions';

export interface State {
  isLoading: boolean;
  addInProgress: boolean;
  functionalRolesAssignments: FuncRoleAssignment[];

  functionalRoles: RoleInfo[];
  funcRolesIsLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  addInProgress: false,
  functionalRolesAssignments: [],
  functionalRoles: [],
  funcRolesIsLoading: false,
};

export const functionalRolesReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { functionalRolesAssignments }) => ({
    ...state,
    isLoading: false,
    functionalRolesAssignments: sortAlphabetically([...functionalRolesAssignments], (a, b) => a.userEmail.localeCompare(b.userEmail)),
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.getAllFuncRoles, state => ({ ...state, funcRolesIsLoading: true })),
  on(fromActions.getAllFuncRolesComplete, (state, { functionalRoles }) => ({
    ...state,
    funcRolesIsLoading: false,
    functionalRoles: sortAlphabetically([...functionalRoles], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllFuncRolesError, state => ({ ...state, funcRolesIsLoading: false })),

  on(fromActions.addFuncRoleAssignment, state => ({ ...state, addInProgress: true })),
  on(fromActions.addFuncRoleAssignmentComplete, (state, { functionalRole }) => ({
    ...state,
    addInProgress: false,
    functionalRolesAssignments: sortAlphabetically([...state.functionalRolesAssignments, functionalRole], (a, b) =>
      a.userEmail.localeCompare(b.userEmail),
    ),
  })),
  on(fromActions.addFuncRoleAssignmentError, state => ({ ...state, addInProgress: false })),

  on(fromActions.deleteFuncRoleAssignment, state => ({ ...state, isLoading: true })),
  on(fromActions.deleteFuncRoleAssignmentComplete, (state, { id }) => ({
    ...state,
    functionalRolesAssignments: findAndRemove<FuncRoleAssignment>(state.functionalRolesAssignments, val => val.assignmentId === id),
    isLoading: false,
  })),
  on(fromActions.deleteFuncRoleAssignmentError, state => ({
    ...state,
    isLoading: false,
  })),
);
