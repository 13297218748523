import { HttpErrorResponse } from '@angular/common/http';
import { GroupedRoles, RoleType, UpdateRoleForEntityRequest } from '@models/authorization';
import { GUID } from '@models/common';
import { Partner, PartnerAdminUser, PartnerBusinessData, PartnerListItem } from '@models/partners';
import { createAction, props } from '@ngrx/store';
import { State } from './partners.reducer';

export const ToggleOptions = ['Operative', 'All'] as const;
export type ToggleOptionsName = (typeof ToggleOptions)[number];

const NAMESPACE = '[Partners]';

export const getAll = createAction(`${NAMESPACE} GetAll`, props<{ toggle: ToggleOptionsName }>());
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ partners: PartnerListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const selectPartner = createAction(`${NAMESPACE} SelectPartner`, props<{ id: GUID }>());
export const selectPartnerComplete = createAction(`${NAMESPACE} SelectPartnerComplete`, props<{ partner: Partner }>());
export const selectPartnerError = createAction(`${NAMESPACE} SelectPartnerError`, props<{ err: HttpErrorResponse }>());

export const getPartnerBusinessData = createAction(`${NAMESPACE} GetPartnerBusinessData`, props<{ id: GUID }>());
export const getPartnerBusinessDataComplete = createAction(
  `${NAMESPACE} GetPartnerBusinessDataComplete`,
  props<{ businessData: PartnerBusinessData }>(),
);
export const getPartnerBusinessDataError = createAction(`${NAMESPACE} GetPartnerBusinessDataError`, props<{ err: HttpErrorResponse }>());

export const getPartnerRoles = createAction(`${NAMESPACE} GetPartnerRoles`, props<{ partnerId: GUID; filter: RoleType }>());
export const getPartnerRolesComplete = createAction(`${NAMESPACE} GetPartnerRolesComplete`, props<{ roles: GroupedRoles[] }>());
export const getPartnerRolesError = createAction(`${NAMESPACE} GetPartnerRolesError`, props<{ err: HttpErrorResponse }>());

export const setPartnerRoles = createAction(
  `${NAMESPACE} SetPartnerRoles`,
  props<{ partnerId: GUID; roles: UpdateRoleForEntityRequest[]; filter: RoleType }>(),
);
export const setPartnerRolesComplete = createAction(`${NAMESPACE} SetPartnerRolesComplete`, props<{ roles: GroupedRoles[] }>());
export const setPartnerRolesError = createAction(`${NAMESPACE} SetPartnerRolesError`, props<{ err: HttpErrorResponse }>());

export const markForDeletion = createAction(`${NAMESPACE} MarkForDeletion`, props<{ id: GUID }>());
export const markForDeletionComplete = createAction(`${NAMESPACE} MarkForDeletionComplete`);
export const markForDeletionError = createAction(`${NAMESPACE} MarkForDeletionError`, props<{ err: HttpErrorResponse }>());

export const setCrmAccId = createAction(`${NAMESPACE} SetCrmAccId`, props<{ crmAccountId: GUID; partnerId: GUID }>());
export const setCrmAccIdComplete = createAction(`${NAMESPACE} SetCrmAccIdComplete`, props<{ partner: Partner }>());
export const setCrmAccIdError = createAction(`${NAMESPACE} SetCrmAccIdError`, props<{ err: HttpErrorResponse }>());

export const getAdminUsers = createAction(`${NAMESPACE} GetAdminUsers`, props<{ partnerId: GUID }>());
export const getAdminUsersComplete = createAction(`${NAMESPACE} GetAdminUsersComplete`, props<{ admins: PartnerAdminUser[] }>());
export const getAdminUsersError = createAction(`${NAMESPACE} GetAdminUsersError`, props<{ err: HttpErrorResponse }>());

export const goToPartnerActions = createAction(`${NAMESPACE} GoToPartnerActions`, props<{ id: GUID }>());
export const goToSelectedPartnerActions = createAction(`${NAMESPACE} GoToSelectedPartnerActions`);
export const goToPartnerList = createAction(`${NAMESPACE} GoToPartnerList`);
export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
