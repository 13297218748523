import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { roleGroupsSelectors } from '@appState';
import { AddRoleGroupRequest, GroupedRoles, RoleGroup, RoleType, UpdateRoleGroupRequest } from '@models/authorization';
import { select, Store } from '@ngrx/store';
import { Observable, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState } from '../../app.store';

@Injectable()
export class RoleGroupsService {
  constructor(
    private httpClient: HttpClient,
    private readonly store: Store<AppState>,
  ) {}

  private readonly url = `${environment.bffUrl}/authorization/role-groups`;

  private roleGroupsModified = false;

  getRoleGroups(): Observable<RoleGroup[]> {
    if (!this.roleGroupsModified) {
      return this.store.pipe(
        select(roleGroupsSelectors.selectRoleGroups),
        take(1),
        switchMap(roleGroups => (roleGroups.length !== 0 ? of(roleGroups) : this.httpClient.get<RoleGroup[]>(this.url))),
      );
    } else {
      this.roleGroupsModified = false;
      return this.httpClient.get<RoleGroup[]>(this.url);
    }
  }

  getGroupedRoles(filter: RoleType = 'Regular'): Observable<GroupedRoles[]> {
    return this.httpClient.get<GroupedRoles[]>(`${this.url}/grouped`, { params: { type: filter } });
  }

  getRoleGroupRoles(roleGroupId: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.url}/${roleGroupId}/roles`);
  }

  addNewRoleGroup(roleGroup: AddRoleGroupRequest): Observable<RoleGroup> {
    this.roleGroupsModified = true;
    return this.httpClient.post<RoleGroup>(this.url, roleGroup);
  }

  updateRoleGroup(id: string, updateRoleGroup: UpdateRoleGroupRequest): Observable<RoleGroup> {
    this.roleGroupsModified = true;
    return this.httpClient.put<RoleGroup>(`${this.url}/${id}`, updateRoleGroup);
  }

  deleteRoleGroup(id: string): Observable<void> {
    this.roleGroupsModified = true;
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }
}
