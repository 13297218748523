import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddRoleDefaultRequest,
  AddRoleRequest,
  CreateRoleAttrubuteRequest,
  PermissionTypes,
  RoleAttribute,
  RoleDefault,
  RoleInfo,
  UpdateRoleAttributeRequest,
  UpdateRoleRequest,
} from '@models/authorization';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RolesService {
  constructor(private httpClient: HttpClient) {}

  private readonly url = `${environment.bffUrl}/authorization`;

  getRoles(): Observable<RoleInfo[]> {
    return this.httpClient.get<RoleInfo[]>(`${this.url}/roles`);
  }

  getRole(id: string): Observable<RoleInfo> {
    return this.httpClient.get<RoleInfo>(`${this.url}/roles/${id}`);
  }

  getAttribute(id: string): Observable<RoleInfo> {
    return this.httpClient.get<RoleInfo>(`${this.url}/roles/${id}`);
  }

  addNewRole(role: AddRoleRequest): Observable<RoleInfo> {
    return this.httpClient.post<RoleInfo>(`${this.url}/roles`, role);
  }

  updateRole(id: string, role: UpdateRoleRequest): Observable<RoleInfo> {
    return this.httpClient.put<RoleInfo>(`${this.url}/roles/${id}`, role);
  }

  deleteRole(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/roles/${id}`);
  }

  getRolePermissions(id: string): Observable<PermissionTypes[]> {
    return this.httpClient.get<PermissionTypes[]>(`${this.url}/roles/${id}/permissions`);
  }

  getRoleDefaults(id: string): Observable<RoleDefault[]> {
    return this.httpClient.get<RoleDefault[]>(`${this.url}/roles/${id}/defaults`);
  }

  addRoleDefault(body: AddRoleDefaultRequest): Observable<RoleDefault[]> {
    return this.httpClient.post<RoleDefault[]>(`${this.url}/roles/${body.roleId}/defaults`, body);
  }

  deleteRoleDefault(roleDefaultId: string, roleId: string): Observable<RoleDefault[]> {
    return this.httpClient.delete<RoleDefault[]>(`${this.url}/roles/${roleId}/defaults/${roleDefaultId}`);
  }

  getRoleAttributes(id: string): Observable<RoleAttribute[]> {
    return this.httpClient.get<RoleAttribute[]>(`${this.url}/roles-attributes`, { params: { roleId: id } });
  }

  getRoleAttribute(id: string): Observable<RoleAttribute> {
    return this.httpClient.get<RoleAttribute>(`${this.url}/roles-attributes/${id}`);
  }

  createRoleAttribute(body: CreateRoleAttrubuteRequest): Observable<RoleAttribute> {
    return this.httpClient.post<RoleAttribute>(`${this.url}/roles-attributes`, body);
  }

  updateRoleAttribute(id: string, attribute: UpdateRoleAttributeRequest): Observable<RoleAttribute> {
    return this.httpClient.put<RoleAttribute>(`${this.url}/roles-attributes/${id}`, attribute);
  }

  deleteRoleAttribute(roleAttributeId: string): Observable<RoleDefault[]> {
    return this.httpClient.delete<RoleDefault[]>(`${this.url}/roles-attributes/${roleAttributeId}`);
  }
}
