import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { branchesSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './branches.actions';
import { BranchesService } from './branches.service';
@Injectable()
export class BranchesEffects {
  onGetBranches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.branchesService.getAll().pipe(
          map(branches => fromActions.getAllComplete({ branches })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addBranch),
      switchMap(({ branch }) =>
        this.branchesService.add(branch).pipe(
          map(branch => fromActions.addBranchComplete({ branch })),
          catchError(err => of(fromActions.addBranchError({ err }))),
        ),
      ),
    ),
  );

  onAddBranchComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addBranchComplete),
        switchMap(response => {
          return this.router.navigate(['file-management', 'branches', response.branch.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUpdateBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateBranch),
      switchMap(({ id, branch }) =>
        this.branchesService.update(id, branch).pipe(
          map(branch => fromActions.updateBranchComplete({ branch })),
          catchError(err => of(fromActions.updateBranchError({ err }))),
        ),
      ),
    ),
  );

  onSelectBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectBranch),
      switchMap(({ id }) =>
        this.store.pipe(
          select(branchesSelectors.selectBranches),
          take(1),
          switchMap(branches => {
            if (branches.length) {
              const branch = branches.find(s => s.id === id)!;
              return of(fromActions.selectBranchComplete({ branch }));
            } else {
              return this.branchesService.get(id).pipe(
                map(branch => fromActions.selectBranchComplete({ branch })),
                catchError(err => of(fromActions.selectBranchError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onDeleteBranch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedBranch),
      switchMap(() =>
        this.store.pipe(
          select(branchesSelectors.selectSelectedBranch),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(branch =>
            this.branchesService.delete(branch.id).pipe(
              map(() => fromActions.deleteSelectedBranchComplete()),
              catchError(err => of(fromActions.deleteSelectedBranchError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteBranchComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedBranchComplete),
      tap(() => this.router.navigate(['file-management', 'branches'])),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToBranchActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToBranchActions),
        tap(({ id }) => this.router.navigate(['file-management', 'branches', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['file-management', 'branches'])),
      map(() => fromActions.resetState({ selectedBranch: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private branchesService: BranchesService,
  ) {}
}
