import { NewsRegion } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace } from '../../helpers/helper';
import * as fromActions from './news-regions.actions';
export interface State {
  isLoading: boolean;
  newsRegions: NewsRegion[];

  isSelectedNewsRegionLoading: boolean;
  selectedNewsRegion: NewsRegion | null;
}

export const initialState: State = {
  isLoading: false,
  newsRegions: [],

  isSelectedNewsRegionLoading: false,
  selectedNewsRegion: null,
};

export const newsRegionsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllNewsRegions, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllNewsRegionsComplete, (state, { newsRegions }) => ({
    ...state,
    isLoading: false,
    newsRegions,
  })),
  on(fromActions.getAllNewsRegionsError, state => ({ ...state, isLoading: false })),

  on(fromActions.getOneNewsRegion, state => ({ ...state, isSelectedNewsRegionLoading: true })),
  on(fromActions.getOneNewsRegionComplete, (state, { newsRegion }) => ({
    ...state,
    isSelectedNewsRegionLoading: false,
    selectedNewsRegion: newsRegion,
  })),
  on(fromActions.getOneNewsRegionError, state => ({ ...state, isSelectedNewsRegionLoading: false })),

  on(fromActions.toggleNewsRegionAutomaticApproval, state => ({ ...state, isSelectedNewsRegionLoading: true })),
  on(fromActions.toggleNewsRegionAutomaticApprovalComplete, (state, { newsRegion }) => ({
    ...state,
    isSelectedNewsRegionLoading: false,
    selectedNewsRegion: newsRegion,
    newsRegions: findAndReplace<NewsRegion>(state.newsRegions, newsRegion, item => item.id === newsRegion.id),
  })),
  on(fromActions.toggleNewsRegionAutomaticApprovalError, state => ({ ...state, isSelectedNewsRegionLoading: false })),
);
