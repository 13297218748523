import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddAffectedUsersRequest,
  AffectedUserListItem,
  AffectedUsersCount,
  Announcement,
  AnnouncementListItem,
  AnnouncementRequestType,
  AnnouncementsToggleOptions,
  CopyAnnouncementRequest,
  CreateAnnouncementRequest,
  CreateUserListRequest,
  UpdateAnnouncementRequest,
} from '@models/index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AnnouncementsService {
  constructor(private httpClient: HttpClient) {}

  private readonly url = `${environment.bffUrl}/announcements`;

  getAnnouncements(toggle: AnnouncementsToggleOptions): Observable<AnnouncementListItem[]> {
    const params = toggle === 'All' ? undefined : { status: toggle };
    return this.httpClient.get<AnnouncementListItem[]>(this.url, { params });
  }

  getAnnouncement(id: string): Observable<Announcement> {
    return this.httpClient.get<Announcement>(`${this.url}/${id}`);
  }

  addAnnouncement(announcement: CreateAnnouncementRequest | CopyAnnouncementRequest): Observable<Announcement> {
    return this.httpClient.post<Announcement>(this.url, announcement);
  }

  updatePermission(id: string, announcement: UpdateAnnouncementRequest): Observable<Announcement> {
    return this.httpClient.put<Announcement>(`${this.url}/${id}`, announcement);
  }

  deleteAnnouncement(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  createUserListForAnnouncement(id: string, request: CreateUserListRequest): Observable<Announcement> {
    return this.httpClient.post<Announcement>(`${this.url}/${id}/create-user-list`, request);
  }

  requestForAnnouncement(id: string, requestType: AnnouncementRequestType): Observable<Announcement> {
    return this.httpClient.post<Announcement>(`${this.url}/${id}/${requestType}`, {});
  }

  getAffectedUsers(id: string): Observable<AffectedUserListItem[]> {
    return this.httpClient.get<AffectedUserListItem[]>(`${this.url}/${id}/users`);
  }

  addAffectedUser(id: string, usersIds: AddAffectedUsersRequest[]): Observable<AffectedUserListItem[]> {
    return this.httpClient.patch<AffectedUserListItem[]>(`${this.url}/${id}/users`, usersIds);
  }

  deleteAffectedUser(id: string, userId: string): Observable<Announcement> {
    return this.httpClient.delete<Announcement>(`${this.url}/${id}/users/${userId}`);
  }

  getAffectedUsersCount(id: string): Observable<AffectedUsersCount> {
    return this.httpClient.get<AffectedUsersCount>(`${this.url}/${id}/users/count`);
  }
}
