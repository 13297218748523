import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.roles);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectRoles = createSelector(selectModuleState, state => state.roles);

export const selectActiveRole = createSelector(selectModuleState, state => state.activeRole);
export const selectActiveRoleId = createSelector(selectModuleState, state => state.activeRole?.id ?? null);
export const isActiveRoleLoading = createSelector(selectModuleState, state => state.activeRoleLoading);

export const selectPermissions = createSelector(selectModuleState, state => state.permissions);
export const isPermissionsLoading = createSelector(selectModuleState, state => state.permissionsLoading);

export const selectDefaults = createSelector(selectModuleState, state => state.defaults);
export const isDefaultsLoading = createSelector(selectModuleState, state => state.defaultsLoading);
export const roleDefaultChangeInProgress = createSelector(selectModuleState, state => state.defaultsChangeInProgress);

export const selectAttributes = createSelector(selectModuleState, state => state.attributes);
export const isAttributesLoading = createSelector(selectModuleState, state => state.attributesLoading);
export const roleAttributeChangeInProgress = createSelector(selectModuleState, state => state.attributesChangeInProgress);

export const selectActiveAttribute = createSelector(selectModuleState, state => state.activeAttribute);
export const selectActiveAttributeId = createSelector(selectModuleState, state => state.activeAttribute?.id ?? null);
export const isActiveAttributeLoading = createSelector(selectModuleState, state => state.activeAttributeLoading);
