import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { topicsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, combineLatest, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './topics.actions';
import { TopicsService } from './topics.service';
@Injectable()
export class TopicsEffects {
  onGetTopics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.topicsService.getAll().pipe(
          map(topics => fromActions.getAllComplete({ topics })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTopic),
      switchMap(({ topic }) =>
        this.topicsService.add(topic).pipe(
          map(topic => fromActions.addTopicComplete({ topic })),
          catchError(err => of(fromActions.addTopicError({ err }))),
        ),
      ),
    ),
  );

  onAddTopicOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTopicOwner),
      switchMap(({ id, ownerEmail }) =>
        this.topicsService.addOwner(id, ownerEmail).pipe(
          map(owner => fromActions.addTopicOwnerComplete({ owner, topicId: id })),
          catchError(err => of(fromActions.addTopicOwnerError({ err }))),
        ),
      ),
    ),
  );

  onAddTopicComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addTopicComplete),
        switchMap(topic => {
          return this.router.navigate(['marketing', 'topics', topic.topic.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onAddTopicOwnerComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addTopicOwnerComplete),
        switchMap(({ topicId, owner }) => {
          return this.router.navigate(['marketing', 'topics', topicId, 'owners', owner.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUpdateTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTopic),
      switchMap(({ id, topic }) =>
        this.topicsService.update(id, topic).pipe(
          map(topic => fromActions.updateTopicComplete({ topic })),
          catchError(err => of(fromActions.updateTopicError({ err }))),
        ),
      ),
    ),
  );

  onSelectTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectTopic),
      switchMap(({ id }) =>
        this.store.pipe(
          select(topicsSelectors.selectTopics),
          take(1),
          switchMap(topics => {
            if (topics.length) {
              const topic = topics.find(t => t.id === id)!;
              return of(fromActions.selectTopicComplete({ topic }));
            } else {
              return this.topicsService.get(id).pipe(
                map(topic => fromActions.selectTopicComplete({ topic })),
                catchError(err => of(fromActions.selectTopicError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onSelectTopicOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectTopicOwner),
      switchMap(({ ownerId, topicId }) =>
        this.store.pipe(
          select(topicsSelectors.selectOwners),
          take(1),
          switchMap(owners => {
            if (owners.length) {
              const owner = owners.find(t => t.id === ownerId)!;
              return of(fromActions.selectTopicOwnerComplete({ owner }));
            } else {
              return this.topicsService.getOwner(topicId, ownerId).pipe(
                map(owner => fromActions.selectTopicOwnerComplete({ owner })),
                catchError(err => of(fromActions.selectTopicError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onGetTopicSubscribers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTopicSubscribers),
      switchMap(({ id }) =>
        this.topicsService.getSubscribers(id).pipe(
          map(subscribers => fromActions.getTopicSubscribersComplete({ subscribers })),
          catchError(err => of(fromActions.getTopicSubscribersError({ err }))),
        ),
      ),
    ),
  );

  onGetTopicOwners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTopicOwners),
      switchMap(({ id }) =>
        this.topicsService.getOwners(id).pipe(
          map(owners => fromActions.getTopicOwnersComplete({ owners })),
          catchError(err => of(fromActions.getTopicOwnersError({ err }))),
        ),
      ),
    ),
  );

  onDeleteTopicOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTopicOwner),
      switchMap(() =>
        combineLatest({
          ownerId: this.store.pipe(select(topicsSelectors.selectSelectedOwnerId), filter(isNotNullOrUndefined), take(1)),
          topicId: this.store.pipe(select(topicsSelectors.selectSelectedTopicId), filter(isNotNullOrUndefined), take(1)),
        }).pipe(
          switchMap(({ ownerId, topicId }) =>
            this.topicsService.deleteOwner(topicId, ownerId).pipe(
              map(() => fromActions.deleteSelectedTopicOwnerComplete({ topicId })),
              catchError(err => of(fromActions.deleteSelectedTopicOwnerError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTopic),
      switchMap(() =>
        this.store.pipe(
          select(topicsSelectors.selectSelectedTopic),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(topic =>
            this.topicsService.delete(topic.id).pipe(
              map(() => fromActions.deleteSelectedTopicComplete()),
              catchError(err => of(fromActions.deleteSelectedTopicError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteTopicComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTopicComplete),
      tap(() => this.router.navigate(['marketing', 'topics'])),
      map(() => fromActions.getAll()),
    ),
  );

  onDeleteTopicOwnerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTopicOwnerComplete),
      tap(({ topicId }) => this.router.navigate(['marketing', 'topics', topicId, 'owners'])),
      map(({ topicId }) => fromActions.getTopicOwners({ id: topicId })),
    ),
  );

  onGoToTopicActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTopicActions),
        tap(({ id }) => this.router.navigate(['marketing', 'topics', id])),
      ),
    { dispatch: false },
  );

  onGoToTopicOwnerActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTopicOwnerActions),
        switchMap(({ ownerId }) =>
          this.store.pipe(
            select(topicsSelectors.selectSelectedTopic),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['marketing', 'topics', id, 'owners', ownerId])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['marketing', 'topics'])),
      map(() => fromActions.resetState({ selectedTopic: null, selectedOwner: null, subscribers: [], owners: [] })),
    ),
  );

  onGoToOwnersListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToOwnersListPage),
        switchMap(() =>
          this.store.pipe(
            select(topicsSelectors.selectSelectedTopic),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['marketing', 'topics', id, 'owners'])),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private topicsService: TopicsService,
  ) {}
}
