import { Injectable } from '@angular/core';
import { HighlanderHttpService } from '@highlander/services/http.service';
import { UserModel } from '@models/authentication/auth';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class IdentityService {
  url = `${environment.bffUrl}/auth`;

  constructor(private readonly http: HighlanderHttpService) {}

  login(): Observable<void> {
    return this.http.get<void>(`${this.url}/login`);
  }

  logout(): Observable<void> {
    return this.http.get<void>(`${this.url}/logout`);
  }

  refresh(): Observable<{ expires_at: number }> {
    return this.http.get<{ expires_at: number }>(`${this.url}/refresh`);
  }

  checkAuth(): Observable<UserModel> {
    return this.http.get<UserModel>(`${this.url}/authStatus`, {
      headers: { 'x-skip-interceptor': '' },
    });
  }
}
