import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.documents);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectDocuments = createSelector(selectModuleState, state => state.documents);

export const selectCurrentDocument = createSelector(selectModuleState, state => state.selectedDocument);
export const selectCurrentDocumentId = createSelector(selectModuleState, state => state.selectedDocument && state.selectedDocument.id);
export const selectCurrentDocumentLoading = createSelector(selectModuleState, state => state.isSelectedDocumentLoading);
export const selectCurrentDocumentActionLoading = createSelector(selectModuleState, state => state.isActionLoading);
export const selectIsFileUploadInProgress = createSelector(selectModuleState, state => state.isFileUploadInProgress);
