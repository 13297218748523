import { CombinedEmail, GroupResponse } from '@models/notifications/receiver-groups/receiver-groups.model';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './receiver-groups.actions';

export interface State {
  isLoading: boolean;
  groupCreateLoading: boolean;
  receiverGroups: GroupResponse[];

  selectedReceiverGroup: GroupResponse | null;
  selectedReceiverGroupLoading: boolean;

  combinedEmailCreateLoading: boolean;
  selectedCombinedEmail: CombinedEmail | null;
  selectedCombinedEmailLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  groupCreateLoading: false,
  receiverGroups: [],

  selectedReceiverGroup: null,
  selectedReceiverGroupLoading: false,

  combinedEmailCreateLoading: false,
  selectedCombinedEmail: null,
  selectedCombinedEmailLoading: false,
};

export const groupsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { groups }) => ({
    ...state,
    isLoading: false,
    receiverGroups: sortAlphabetically([...groups], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addGroup, state => ({ ...state, groupCreateLoading: true, isLoading: true })),
  on(fromActions.addGroupComplete, (state, { group }) => ({
    ...state,
    isLoading: false,
    groupCreateLoading: false,
    receiverGroups: sortAlphabetically([...state.receiverGroups, group], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addGroupError, state => ({ ...state, isLoading: false, groupCreateLoading: false })),

  on(fromActions.updateGroup, state => ({ ...state, selectedReceiverGroupLoading: true, isLoading: true })),
  on(fromActions.updateGroupComplete, (state, { group }) => ({
    ...state,
    isLoading: false,
    selectedReceiverGroupLoading: false,
    selectedReceiverGroup: group,
    receiverGroups: sortAlphabetically(
      findAndReplace<GroupResponse>(state.receiverGroups, group, item => item.id === group.id),
      (a, b) => a.name.localeCompare(b.name),
    ),
  })),
  on(fromActions.updateGroupError, state => ({
    ...state,
    selectedReceiverGroup: { ...state.selectedReceiverGroup! },
    isLoading: false,
    selectedReceiverGroupLoading: false,
  })),

  on(fromActions.selectGroup, state => ({ ...state, selectedReceiverGroupLoading: true })),
  on(fromActions.selectGroupComplete, (state, { group }) => ({
    ...state,
    selectedReceiverGroupLoading: false,
    selectedReceiverGroup: group,
  })),
  on(fromActions.selectGroupError, state => ({ ...state, selectedReceiverGroupLoading: false })),

  on(fromActions.deleteSelectedGroup, state => ({ ...state, selectedReceiverGroupLoading: true })),
  on(fromActions.deleteSelectedGroupComplete, state => ({
    ...state,
    selectedReceiverGroupLoading: false,
    selectedReceiverGroup: null,
  })),
  on(fromActions.deleteSelectedGroupError, state => ({
    ...state,
    selectedReceiverGroupLoading: false,
  })),

  on(fromActions.addCombinedEmail, state => ({ ...state, combinedEmailCreateLoading: true, isLoading: true })),
  on(fromActions.addCombinedEmailComplete, (state, { group }) => ({
    ...state,
    isLoading: false,
    combinedEmailCreateLoading: false,
    selectedReceiverGroup: group,
    receiverGroups: sortAlphabetically(
      findAndReplace<GroupResponse>(state.receiverGroups, group, item => item.id === group.id),
      (a, b) => a.name.localeCompare(b.name),
    ),
  })),
  on(fromActions.addCombinedEmailError, state => ({ ...state, isLoading: false, combinedEmailCreateLoading: false })),

  on(fromActions.updateCombinedEmail, state => ({ ...state, selectedCombinedEmailLoading: true, isLoading: true })),
  on(fromActions.updateCombinedEmailComplete, (state, { group, combinedEmail }) => ({
    ...state,
    isLoading: false,
    selectedCombinedEmailLoading: false,
    selectedReceiverGroup: group,
    selectedCombinedEmail: combinedEmail,
  })),
  on(fromActions.updateCombinedEmailError, state => ({
    ...state,
    selectedCombinedEmail: { ...state.selectedCombinedEmail! },
    isLoading: false,
    selectedCombinedEmailLoading: false,
  })),

  on(fromActions.deleteSelectedCombinedEmail, state => ({ ...state, selectedCombinedEmailLoading: true })),
  on(fromActions.deleteSelectedCombinedEmailComplete, (state, { group }) => ({
    ...state,
    selectedCombinedEmailLoading: false,
    selectedCombinedEmail: null,
    selectedReceiverGroup: group,
  })),
  on(fromActions.deleteSelectedCombinedEmailError, state => ({
    ...state,
    selectedCombinedEmailLoading: false,
  })),

  on(fromActions.selectCombinedEmail, state => ({ ...state, selectedCombinedEmailLoading: true })),
  on(fromActions.selectCombinedEmailComplete, (state, { combinedEmail }) => ({
    ...state,
    selectedCombinedEmailLoading: false,
    selectedCombinedEmail: combinedEmail,
  })),
  on(fromActions.selectCombinedEmailError, state => ({ ...state, selectedCombinedEmailLoading: false })),
);
