import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerListItem } from '@models/customers';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomersService {
  private readonly url = `${environment.bffUrl}/customers`;
  constructor(private httpClient: HttpClient) {}

  getCustomers(partnerId: string, includeMarkedForDeletion = false): Observable<CustomerListItem[]> {
    return this.httpClient.get<CustomerListItem[]>(this.url, { params: { partnerId, includeMarkedForDeletion } });
  }
}
