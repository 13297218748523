import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonData, CommonDataType } from '@models/common';
import { Observable, map } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/internal/operators/tap';
import { environment } from 'src/environments/environment';

export type CommonDataObj = Record<CommonDataType, CommonData<CommonDataType>[]>;
@Injectable()
export class CommonDataService {
  constructor(private httpClient: HttpClient) {}

  private commonCache: CommonDataObj = {
    audience: [],
    region: [],
    membershipLevel: [],
    language: [],
    country: [],
    workload: [],
    others: [],
  };

  getSpecific<T extends CommonDataType>(type: CommonDataType): Observable<CommonData<T>[]> {
    if (this.commonCache[type].length) {
      return of(this.commonCache[type] as CommonData<T>[]);
    } else {
      return this.httpClient.get<CommonData<T>[]>(`/${environment.bffUrl}/common-data`, { params: { type } });
    }
  }

  getAll<T extends CommonDataType>(): Observable<CommonDataObj> {
    return this.httpClient.get<CommonData<T>[]>(`/${environment.bffUrl}/common-data`).pipe(
      tap(data => {
        const audience: CommonData[] = [];
        const region: CommonData[] = [];
        const membershipLevel: CommonData[] = [];
        const language: CommonData[] = [];
        const country: CommonData[] = [];
        const workload: CommonData[] = [];
        const others: CommonData[] = [];
        data.forEach(cData => {
          switch (cData.type) {
            case 'audience':
              audience.push(cData);
              break;
            case 'region':
              region.push(cData);
              break;
            case 'membershipLevel':
              membershipLevel.push(cData);
              break;
            case 'language':
              language.push(cData);
              break;
            case 'country':
              country.push(cData);
              break;
            case 'workload':
              workload.push(cData);
              break;
            default:
              others.push(cData);
              break;
          }
        });

        this.commonCache = { audience, region, membershipLevel, language, country, workload, others };
      }),
      map(() => this.commonCache),
    );
  }

  // Work with typescript to assure the type in the future, create a separate task in the future to investigate on that, for now this is good enough
  // isSpecificCommonDataObject(value: CommonData<unknown>, type: CommonDataType): value is CommonData<unknown> {
  //   return value.type === type;
  // }
}
