import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { identitySelectors } from '@appState';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AuthorizedRouteData } from '../app-routing.module';
import { AppState } from '../app-state/app.store';

@Injectable()
export class PermissionsGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const { permissions }: AuthorizedRouteData = route.data as AuthorizedRouteData;

    if (!permissions.menuAccess?.length && permissions.oneAtLeast?.length) {
      return true;
    }

    return this.store.select(identitySelectors.selectIsLoading).pipe(
      filter(isLoading => !isLoading),
      first(),
      switchMap(() =>
        this.store
          .select(
            identitySelectors.selectHasPermissionToAccessPage({
              menuAccess: permissions.menuAccess,
              oneAtLeast: permissions.oneAtLeast,
            }),
          )
          .pipe(
            first(),
            map((hasUserPermissions: boolean) => {
              // TODO: Strange behaviour needs investigation.
              // If console log is used here then it is triggered in development console ( not browser ) after refresh button is clicked on browser.
              if (!hasUserPermissions) {
                this.router.navigate(['dashboard']);
              }

              return hasUserPermissions;
            }),
          ),
      ),
    );
  }
}
