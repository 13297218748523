import { HttpErrorResponse } from '@angular/common/http';
import { AddFuncRoleAssignment, Attribute, FuncRoleAssignment, RoleInfo } from '@models/authorization';
import { createAction, props } from '@ngrx/store';
import { State } from './functional-roles.reducer';

const NAMESPACE = '[Authorization Functional Role Assignments]';

export const getAll = createAction(`${NAMESPACE} GetAll`, props<{ subtype: string; attributes: Attribute[] }>());
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ functionalRolesAssignments: FuncRoleAssignment[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const getAllFuncRoles = createAction(`${NAMESPACE} GetAllFuncRoles`, props<{ subtype: string }>());
export const getAllFuncRolesComplete = createAction(`${NAMESPACE} GetAllFuncRolesComplete`, props<{ functionalRoles: RoleInfo[] }>());
export const getAllFuncRolesError = createAction(`${NAMESPACE} GetAllFuncRolesError`, props<{ err: HttpErrorResponse }>());

export const addFuncRoleAssignment = createAction(
  `${NAMESPACE} AddFuncRoleAssignment`,
  props<{ addFuncRoleAssignment: AddFuncRoleAssignment; subtype: string }>(),
);
export const addFuncRoleAssignmentComplete = createAction(
  `${NAMESPACE} AddFuncRoleAssignmentComplete`,
  props<{ functionalRole: FuncRoleAssignment }>(),
);
export const addFuncRoleAssignmentError = createAction(`${NAMESPACE} AddFuncRoleAssignmentError`, props<{ err: HttpErrorResponse }>());

export const deleteFuncRoleAssignment = createAction(`${NAMESPACE} DeleteFuncRoleAssignment`, props<{ id: string }>());
export const deleteFuncRoleAssignmentComplete = createAction(`${NAMESPACE} DeleteFuncRoleAssignmentComplete`, props<{ id: string }>());
export const deleteFuncRoleAssignmentError = createAction(
  `${NAMESPACE} DeleteFuncRoleAssignmentError`,
  props<{ err: HttpErrorResponse }>(),
);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
