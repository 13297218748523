import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NotificationsService } from 'cui-components';

@Injectable()
export class GuidGuard {
  constructor(
    private router: Router,
    private notificationService: NotificationsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    for (let i = 0; i < route.paramMap.keys.length; i++) {
      const guidToCheck = route.paramMap.get(route.paramMap.keys[i]);
      if (guidToCheck) {
        const matches = guidToCheck.match(/^[0-9a-fA-F]{8}(?:-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$/);
        if (!matches) {
          this.notificationService.showNotification('Entity identifier in URL is not a valid GUID', 'Dismiss', { duration: 10000 });
          this.router.navigate(['page', 'page-not-found']);
          return false;
        }
      }
    }
    return true;
  }
}
