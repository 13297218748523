import { CustomerListItem } from '@models/customers';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './customers.actions';

export interface State {
  isLoading: boolean;
  customers: CustomerListItem[];
}

export const initialState: State = {
  isLoading: false,
  customers: [],
};

export const customersReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { customers }) => ({ ...state, customers, isLoading: false })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),
);
