import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ApplicationReloadNotificationComponent, NotificationsService } from 'cui-components';
import { AppInsightsLoggingService } from './logging.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    private readonly notifications: NotificationsService,
    private readonly loggingService: AppInsightsLoggingService,
    private readonly zone: NgZone,
  ) {
    super();
  }

  override handleError(error: Error): void {
    const chunkFailedMessageRegxp = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessageRegxp.test(error?.message)) {
      this.handleChunkLoadError();
    } else {
      console.error(error);
      this.loggingService.logException(error);
    }
  }

  private handleChunkLoadError(): void {
    this.zone.run(() => {
      this.notifications.showNotificationFromComponent(ApplicationReloadNotificationComponent, { duration: 10000 });
    });
  }
}
