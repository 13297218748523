import { Injectable } from '@angular/core';
import { LogsQueryParams } from '@models/notifications';
import { formatToISODate } from 'cui-components';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private filterDatesSubject = new BehaviorSubject<LogsQueryParams>(this.getInitialFilterDates());
  filterDates$ = this.filterDatesSubject.asObservable();

  private getInitialFilterDates(): LogsQueryParams {
    const storedData = localStorage.getItem('logFilter');
    if (storedData) {
      return JSON.parse(storedData);
    } else {
      const createdFrom = new Date();
      createdFrom.setDate(createdFrom.getDate() - 3);

      const createdTo = new Date();
      createdTo.setDate(createdTo.getDate() + 1);

      const initialFilterDates: LogsQueryParams = {
        createdFrom: formatToISODate(createdFrom),
        createdTo: formatToISODate(createdTo),
      };

      localStorage.setItem('logFilter', JSON.stringify(initialFilterDates));

      return initialFilterDates;
    }
  }

  updateFilterDates(logsQuery: LogsQueryParams): void {
    localStorage.setItem('logFilter', JSON.stringify(logsQuery));
    this.filterDatesSubject.next(logsQuery);
  }
}
