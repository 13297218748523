import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.newsRegions);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectNewsRegions = createSelector(selectModuleState, state => state.newsRegions);
export const selectNewsRegionsCount = createSelector(selectModuleState, state => state.newsRegions.length);

export const selectIsSelectedNewsRegionLoading = createSelector(selectModuleState, state => state.isSelectedNewsRegionLoading);
export const selectSelectedNewsRegion = createSelector(selectModuleState, state => state.selectedNewsRegion);
