import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.agreements);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectAgreements = createSelector(selectModuleState, state => state.agreements);

export const selectSelectedAgreement = createSelector(selectModuleState, state => state.selectedAgreement);
export const selectSelectedAgreementId = createSelector(selectModuleState, state => state.selectedAgreement?.id ?? null);
export const selectSelectedAgreementLoading = createSelector(selectModuleState, state => state.isSelectedAgreementLoading);

export const selectLogs = createSelector(selectModuleState, state => state.logs);
export const isAgreementSignedOnce = createSelector(selectModuleState, state => !!state.logs.length);
export const selectLogsLoading = createSelector(selectModuleState, state => state.isLogsLoading);

export const selectVersions = createSelector(selectModuleState, state => state.versions);
export const selectVersionsLoading = createSelector(selectModuleState, state => state.isVersionsLoading);
export const selectSelectedVersion = createSelector(selectModuleState, state => state.selectedVersion);
export const selectSelectedVersionId = createSelector(selectModuleState, state => state.selectedVersion?.id ?? null);
export const selectSelectedVersionLoading = createSelector(selectModuleState, state => state.isSelectedAgreementVersionLoading);
