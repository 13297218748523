import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { logsSelectors } from '@appState';
import { LogsQueryParams } from '@models/notifications';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isNotNullOrUndefined, NotificationsService } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { NotificationLogsFiltersComponent } from 'src/app/app-pages/notifications/logs/notification-logs-filters.component';
import { AppState } from '../../app.store';
import * as fromActions from './logs.actions';
import { NotificationLogsService } from './logs.service';

@Injectable()
export class NotificationLogsEffects {
  onGetLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllLogs),
      switchMap(({ logsQuery }) =>
        this.service.getAll(logsQuery).pipe(
          map(logs => fromActions.getAllLogsComplete({ logs })),
          catchError(err => of(fromActions.getAllLogsError({ err }))),
        ),
      ),
    ),
  );

  onSelectLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectLog),
      switchMap(({ logId }) =>
        this.store.pipe(
          select(logsSelectors.selectSelectedLog),
          take(1),
          switchMap(log => {
            if (log && log.id === logId) {
              return of(fromActions.selectLogComplete({ log }));
            } else {
              return this.service.getOne(logId).pipe(
                map(log => fromActions.selectLogComplete({ log })),
                catchError(err => of(fromActions.selectLogError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onResend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendNotification),
      switchMap(({ logId }) =>
        this.service.resend(logId).pipe(
          map(log => {
            this.notificationService.showNotification('Accepted. Request added to a queue and will be executed later');
            return fromActions.resendNotificationComplete({ log });
          }),
          catchError(err => of(fromActions.resendNotificationError({ err }))),
        ),
      ),
    ),
  );

  onSendToAnotherEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sendNotificationToAnotherEmail),
      switchMap(({ logId, email }) =>
        this.service.sendToAnotherEmail(logId, email).pipe(
          map(() => {
            this.notificationService.showNotification('Accepted. Request added to a queue and will be executed later');
            return fromActions.sendNotificationToAnotherEmailComplete();
          }),
          catchError(err => of(fromActions.sendNotificationToAnotherEmailError({ err }))),
        ),
      ),
    ),
  );

  onGoToLogActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToLogActions),
        tap(({ logId }) => this.router.navigate(['notifications', 'logs', logId])),
      ),
    { dispatch: false },
  );

  onGoToLogsListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToLogsListPage),
      tap(() => this.router.navigate(['notifications', 'logs'])),
      map(() => fromActions.resetState({ selectedLog: null })),
    ),
  );

  onOpenLogsFilterModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openFilterModal),
      switchMap(() =>
        this.dialog
          .open(NotificationLogsFiltersComponent, {
            data: JSON.parse(localStorage.getItem('logFilter')!),
            panelClass: 'custom-modal-container',
          })
          .afterClosed()
          .pipe(
            take(1),
            // "undefined" = closed via backdrop, "null" = closed via "Cancel" button
            filter(isNotNullOrUndefined),
            map((logsQuery: LogsQueryParams) => fromActions.getAllLogs({ logsQuery })),
          ),
      ),
    ),
  );

  onSubmitLogsFilterModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.submitFilterModal),
        map(({ logsQuery }) => localStorage.setItem('logFilter', JSON.stringify(logsQuery))),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private service: NotificationLogsService,
    private notificationService: NotificationsService,
    private dialog: MatDialog,
  ) {}
}
