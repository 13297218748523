import { HttpErrorResponse } from '@angular/common/http';
import {
  CreateExternalTagRequest,
  CreateExternalTemplateRequest,
  ExternalTagResponse,
  ExternalTemplateResponse,
  UpdateExternalTagRequest,
  UpdateExternalTemplateRequest,
} from '@models/notifications/external-templates/external-templates.model';
import { createAction, props } from '@ngrx/store';
import { State } from './external-templates.reducer';

const NAMESPACE = '[Notifications External Templates]';

export const getAllTemplates = createAction(`${NAMESPACE} GetAllTemplates`);
export const getAllTemplatesComplete = createAction(
  `${NAMESPACE} GetAllTemplatesComplete`,
  props<{ templates: ExternalTemplateResponse[] }>(),
);
export const getAllTemplatesError = createAction(`${NAMESPACE} GetAllTemplatesError`, props<{ err: HttpErrorResponse }>());

export const addTemplate = createAction(`${NAMESPACE} AddTemplate`, props<{ template: CreateExternalTemplateRequest }>());
export const addTemplateComplete = createAction(`${NAMESPACE} AddTemplateComplete`, props<{ template: ExternalTemplateResponse }>());
export const addTemplateError = createAction(`${NAMESPACE} AddTemplateError`, props<{ err: HttpErrorResponse }>());

export const updateTemplate = createAction(
  `${NAMESPACE} UpdateTemplate`,
  props<{ templateId: string; template: UpdateExternalTemplateRequest }>(),
);
export const updateTemplateComplete = createAction(`${NAMESPACE} UpdateTemplateComplete`, props<{ template: ExternalTemplateResponse }>());
export const updateTemplateError = createAction(`${NAMESPACE} UpdateTemplateError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedTemplate = createAction(`${NAMESPACE} DeleteSelectedTemplate`);
export const deleteSelectedTemplateComplete = createAction(`${NAMESPACE} DeleteSelectedTemplateComplete`);
export const deleteSelectedTemplateError = createAction(`${NAMESPACE} DeleteSelectedTemplateError`, props<{ err: HttpErrorResponse }>());

export const selectTemplate = createAction(`${NAMESPACE} SelectTemplate`, props<{ templateId: string }>());
export const selectTemplateComplete = createAction(`${NAMESPACE} SelectTemplateComplete`, props<{ template: ExternalTemplateResponse }>());
export const selectTemplateError = createAction(`${NAMESPACE} SelectTemplateError`, props<{ err: HttpErrorResponse }>());

export const getAllTags = createAction(`${NAMESPACE} GetAllTags`, props<{ templateId: string }>());
export const getAllTagsComplete = createAction(`${NAMESPACE} GetAllTagsComplete`, props<{ tags: ExternalTagResponse[] }>());
export const getAllTagsError = createAction(`${NAMESPACE} GetAllTagsError`, props<{ err: HttpErrorResponse }>());

export const addTag = createAction(`${NAMESPACE} AddTag`, props<{ templateId: string; tag: CreateExternalTagRequest }>());
export const addTagComplete = createAction(`${NAMESPACE} AddTagComplete`, props<{ templateId: string; tag: ExternalTagResponse }>());
export const addTagError = createAction(`${NAMESPACE} AddTagError`, props<{ err: HttpErrorResponse }>());

export const updateTag = createAction(
  `${NAMESPACE} UpdateTag`,
  props<{ templateId: string; tagId: string; tag: UpdateExternalTagRequest }>(),
);
export const updateTagComplete = createAction(`${NAMESPACE} UpdateTagComplete`, props<{ tag: ExternalTagResponse }>());
export const updateTagError = createAction(`${NAMESPACE} UpdateTagError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedTag = createAction(`${NAMESPACE} DeleteSelectedTag`);
export const deleteSelectedTagComplete = createAction(`${NAMESPACE} DeleteSelectedTagComplete`, props<{ templateId: string }>());
export const deleteSelectedTagError = createAction(`${NAMESPACE} DeleteSelectedTagError`, props<{ err: HttpErrorResponse }>());

export const selectTag = createAction(`${NAMESPACE} SelectTag`, props<{ templateId: string; tagId: string }>());
export const selectTagComplete = createAction(`${NAMESPACE} SelectTagComplete`, props<{ tag: ExternalTagResponse }>());
export const selectTagError = createAction(`${NAMESPACE} SelectTagError`, props<{ err: HttpErrorResponse }>());

export const goToTemplateActions = createAction(`${NAMESPACE} GoToTemplateActions`, props<{ templateId: string }>());
export const goToTemplatesListPage = createAction(`${NAMESPACE} GoToTemplatesListPage`);
export const goToTagActions = createAction(`${NAMESPACE} GoToTagActions`, props<{ tagId: string }>());
export const goToTagsListPage = createAction(`${NAMESPACE} GoToTagsListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
