import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

type HttpOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
};

@Injectable({
  providedIn: 'root',
})
export class HighlanderHttpService {
  constructor(private http: HttpClient) {}

  get<TResponse>(url: string, options?: HttpOptions): Observable<TResponse> {
    return this.http.get<TResponse>(url, options);
  }

  post<TBody, TResponse>(url: string, body: TBody, options?: HttpOptions): Observable<TResponse> {
    return this.http.post<TResponse>(url, body, options);
  }

  put<TBody, TResponse>(url: string, body: TBody, options?: HttpOptions): Observable<TResponse> {
    return this.http.put<TResponse>(url, body, options);
  }

  patch<TBody, TResponse>(url: string, body: TBody, options?: HttpOptions): Observable<TResponse> {
    return this.http.patch<TResponse>(url, body, options);
  }

  delete<TResponse>(url: string, options?: HttpOptions): Observable<TResponse> {
    return this.http.delete<TResponse>(url, options);
  }
}
