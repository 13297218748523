import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.commonData);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectLanguages = createSelector(selectModuleState, state => state.commonData.language);
export const selectMembershipLevels = createSelector(selectModuleState, state => state.commonData.membershipLevel);
export const selectRegions = createSelector(selectModuleState, state => state.commonData.region);
export const selectCommonData = createSelector(selectModuleState, state => state.commonData);
export const selectIsCommonDataNotFullyFetched = createSelector(selectModuleState, state =>
  Object.values(state.commonData).some(array => array.length === 0),
);

export const selectIsSelectedCommonDataLoading = createSelector(selectModuleState, state => state.isSelectedCommonDataLoading);
export const selectSelectedCommonData = createSelector(selectModuleState, state => state.selectedCommonData);
