import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { logsActions } from '@appState';
import { ActionLogsListQuery, LogsQueryParams } from '@models/index';
import { Store } from '@ngrx/store';
import { CuiButtonsModule, CuiCardModule, CuiDatepickerComponent, CuiTableModule, formatToISODate } from 'cui-components';
import { FilterService } from './notification-log-filters.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CuiTableModule,
    RouterModule,
    CuiCardModule,
    FormsModule,
    ReactiveFormsModule,
    CuiDatepickerComponent,
    CuiButtonsModule,
  ],
  template: `
    <cui-card>
      <cui-card-header [showCloseBtn]="true" (close)="onCancel()">
        <h2>Notifications Logs Filter</h2>
      </cui-card-header>
      <cui-card-body>
        <form [formGroup]="form">
          <div class="date-fields">
            <cui-date-picker
              [label]="'Start Date'"
              formControlName="createdFrom"
              [required]="true"
              [maxDate]="createdToControl?.value"
            ></cui-date-picker>
            <span class="flex-spacer"></span>
            <cui-date-picker
              [label]="'End Date'"
              formControlName="createdTo"
              [required]="true"
              [maxDate]="todaysDate"
              [minDate]="createdFromControl?.value"
            ></cui-date-picker>
          </div>
        </form>
      </cui-card-body>
      <cui-card-footer>
        <cui-button (clicked)="onCancel()">Cancel</cui-button>
        <cui-button-cta [disabled]="!form.valid" (clicked)="onConfirm()">Submit</cui-button-cta>
      </cui-card-footer>
    </cui-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationLogsFiltersComponent implements OnInit {
  form!: FormGroup;
  todaysDate = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActionLogsListQuery,
    private store: Store,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NotificationLogsFiltersComponent>,
    private filterService: FilterService,
  ) {}

  ngOnInit() {
    const filterSettings = localStorage.getItem('logFilter');
    if (filterSettings) {
      const parsedSettings = JSON.parse(filterSettings);
      this.form = this.fb.group({
        createdFrom: [parsedSettings.createdFrom, [Validators.required]],
        createdTo: [
          new Date(new Date(parsedSettings.createdTo).setDate(new Date(parsedSettings.createdTo).getDate() - 1)),
          [Validators.required],
        ],
      });
    }
  }

  get createdFromControl() {
    return this.form.get('createdFrom');
  }

  get createdToControl() {
    return this.form.get('createdTo');
  }

  onConfirm(): void {
    if (this.form.valid) {
      const logsQuery: LogsQueryParams = {
        ...this.form.value,
        createdFrom: formatToISODate(this.form.value.createdFrom),
        createdTo: formatToISODate(new Date(this.form.value.createdTo.setDate(this.form.value.createdTo.getDate() + 1))),
      };
      this.filterService.updateFilterDates(logsQuery);
      this.store.dispatch(logsActions.submitFilterModal({ logsQuery }));
      this.dialogRef.close(logsQuery);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
