import { Injectable } from '@angular/core';
import { HighlanderHttpService } from '@highlander/services/http.service';
import { AppConfig } from '@models/common/config';
import { lastValueFrom, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  config!: AppConfig;
  constructor(private http: HighlanderHttpService) {}

  loadConfig(): Promise<AppConfig> {
    return lastValueFrom(this.http.get<AppConfig>(`${environment.bffUrl}/config`).pipe(tap(config => (this.config = config))));
  }
}
