import { Template, TemplateAdditionalReceiver, TemplateMapping, TemplateMappingContent } from '@models/notifications/templates';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './templates.actions';

export interface State {
  isLoading: boolean;
  templates: Template[];

  selectedTemplate: Template | null;
  selectedTemplateLoading: boolean;
  templateCreateLoading: boolean;

  additionalReceivers: TemplateAdditionalReceiver[];
  additionalReceiversLoading: boolean;
  selectedAdditionalReceiver: TemplateAdditionalReceiver | null;
  selectedAdditionalReceiverLoading: boolean;
  additionalReceiverCreateLoading: boolean;

  mappings: TemplateMapping[];
  mappingsLoading: boolean;
  selectedMapping: TemplateMapping | null;
  selectedMappingLoading: boolean;
  mappingCreateLoading: boolean;

  mappingContents: TemplateMappingContent[];
  mappingContentsLoading: boolean;
  selectedMappingContent: TemplateMappingContent | null;
  selectedMappingContentLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  templates: [],
  selectedTemplate: null,
  selectedTemplateLoading: false,
  templateCreateLoading: false,

  additionalReceivers: [],
  additionalReceiversLoading: false,
  selectedAdditionalReceiver: null,
  selectedAdditionalReceiverLoading: false,
  additionalReceiverCreateLoading: false,

  mappings: [],
  mappingsLoading: false,
  selectedMapping: null,
  selectedMappingLoading: false,
  mappingCreateLoading: false,

  mappingContents: [],
  mappingContentsLoading: false,
  selectedMappingContent: null,
  selectedMappingContentLoading: false,
};

export const templatesReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { templates }) => ({
    ...state,
    templates: sortAlphabetically(templates, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addTemplate, state => ({ ...state, templateCreateLoading: true, isLoading: true })),
  on(fromActions.addTemplateComplete, (state, { template }) => ({
    ...state,
    isLoading: false,
    templateCreateLoading: false,
    templates: sortAlphabetically([...state.templates, template], predicateForSorting('name')),
  })),
  on(fromActions.addTemplateError, state => ({ ...state, isLoading: false, templateCreateLoading: false })),

  on(fromActions.updateTemplate, state => ({ ...state, selectedTemplateLoading: true, isLoading: true })),
  on(fromActions.updateTemplateComplete, (state, { template }) => ({
    ...state,
    isLoading: false,
    selectedTemplateLoading: false,
    selectedTemplate: template,
    templates: sortAlphabetically(
      findAndReplace<Template>(state.templates, template, item => item.id === template.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.updateTemplateError, state => ({
    ...state,
    selectedTemplate: { ...state.selectedTemplate! },
    isLoading: false,
    selectedTemplateLoading: false,
  })),
  on(fromActions.selectTemplate, state => ({ ...state, selectedTemplateLoading: true })),
  on(fromActions.selectTemplateComplete, (state, { template }) => ({
    ...state,
    selectedTemplateLoading: false,
    selectedTemplate: template,
  })),
  on(fromActions.selectTemplateError, state => ({ ...state, selectedTemplateLoading: false })),

  on(fromActions.getAdditionalReceivers, state => ({ ...state, additionalReceiversLoading: true })),
  on(fromActions.getAdditionalReceiversComplete, (state, { additionalReceivers }) => ({
    ...state,
    additionalReceiversLoading: false,
    additionalReceivers: sortAlphabetically(additionalReceivers, predicateForSorting('groupName')),
  })),
  on(fromActions.getAdditionalReceiversError, state => ({
    ...state,
    additionalReceiversLoading: false,
  })),

  on(fromActions.addAdditionalReceiver, state => ({
    ...state,
    additionalReceiversLoading: true,
    additionalReceiverCreateLoading: true,
  })),
  on(fromActions.addAdditionalReceiverComplete, (state, { additionalReceiver }) => ({
    ...state,
    additionalReceiverCreateLoading: false,
    additionalReceiversLoading: false,
    additionalReceivers: sortAlphabetically([...state.additionalReceivers, additionalReceiver], predicateForSorting('groupName')),
  })),
  on(fromActions.addAdditionalReceiverError, state => ({
    ...state,
    additionalReceiversLoading: false,
    additionalReceiverCreateLoading: false,
  })),
  on(fromActions.deleteSelectedAdditionalReceiver, state => ({ ...state, additionalReceiversLoading: true })),
  on(fromActions.deleteSelectedAdditionalReceiverComplete, state => ({
    ...state,
    additionalReceiversLoading: false,
  })),
  on(fromActions.deleteSelectedAdditionalReceiverError, state => ({
    ...state,
    additionalReceiversLoading: false,
  })),

  on(fromActions.selectAdditionalReceiver, state => ({ ...state, selectedAdditionalReceiverLoading: true })),
  on(fromActions.selectAdditionalReceiverComplete, (state, { additionalReceiver }) => ({
    ...state,
    selectedAdditionalReceiverLoading: false,
    selectedAdditionalReceiver: additionalReceiver,
  })),
  on(fromActions.selectAdditionalReceiverError, state => ({ ...state, selectedAdditionalReceiverLoading: false })),

  on(fromActions.updateAdditionalReceiver, state => ({ ...state, selectedAdditionalReceiverLoading: true, isLoading: true })),
  on(fromActions.updateAdditionalReceiverComplete, (state, { additionalReceiver }) => ({
    ...state,
    isLoading: false,
    selectedAdditionalReceiverLoading: false,
    selectedAdditionalReceiver: additionalReceiver,
    additionalReceivers: sortAlphabetically(
      findAndReplace<TemplateAdditionalReceiver>(state.additionalReceivers, additionalReceiver, item => item.id === additionalReceiver.id),
      predicateForSorting<TemplateAdditionalReceiver>('groupName'),
    ),
  })),
  on(fromActions.updateAdditionalReceiverError, state => ({
    ...state,
    selectedAdditionalReceiver: { ...state.selectedAdditionalReceiver! },
    isLoading: false,
    selectedAdditionalReceiverLoading: false,
  })),

  on(fromActions.getMappings, state => ({ ...state, mappingsLoading: true })),
  on(fromActions.getMappingsComplete, (state, { mappings }) => ({
    ...state,
    mappingsLoading: false,
    mappings,
  })),
  on(fromActions.getMappingsError, state => ({
    ...state,
    mappingsLoading: false,
  })),

  on(fromActions.addMapping, state => ({
    ...state,
    mappingsLoading: true,
    mappingCreateLoading: true,
  })),
  on(fromActions.addMappingComplete, (state, { mapping }) => ({
    ...state,
    mappingCreateLoading: false,
    mappingsLoading: false,
    mappings: [...state.mappings, mapping],
  })),
  on(fromActions.addMappingError, state => ({
    ...state,
    mappingsLoading: false,
    mappingCreateLoading: false,
  })),
  on(fromActions.deleteSelectedMapping, state => ({ ...state, mappingsLoading: true })),
  on(fromActions.deleteSelectedMappingComplete, state => ({
    ...state,
    mappingsLoading: false,
  })),
  on(fromActions.deleteSelectedMappingError, state => ({
    ...state,
    mappingsLoading: false,
  })),

  on(fromActions.selectMapping, state => ({ ...state, selectedMappingLoading: true })),
  on(fromActions.selectMappingComplete, (state, { mapping }) => ({
    ...state,
    selectedMappingLoading: false,
    selectedMapping: mapping,
  })),
  on(fromActions.selectMappingError, state => ({ ...state, selectedMappingLoading: false })),

  on(fromActions.updateMapping, state => ({ ...state, selectedMappingLoading: true, mappingsLoading: true })),
  on(fromActions.updateMappingComplete, (state, { mapping }) => ({
    ...state,
    mappingsLoading: false,
    selectedMappingLoading: false,
    selectedMapping: mapping,
    mappings: findAndReplace<TemplateMapping>(state.mappings, mapping, item => item.id === mapping.id),
  })),
  on(fromActions.updateMappingError, state => ({
    ...state,
    selectedMapping: { ...state.selectedMapping! },
    mappingsLoading: false,
    selectedMappingLoading: false,
  })),
  on(fromActions.activationOfSelectedMapping, state => ({ ...state, selectedMappingLoading: true, mappingsLoading: true })),
  on(fromActions.activationOfSelectedMappingComplete, (state, { mapping }) => ({
    ...state,
    mappingsLoading: false,
    selectedMappingLoading: false,
    selectedMapping: mapping,
    mappings: findAndReplace(state.mappings, mapping, item => item.id === mapping.id),
  })),
  on(fromActions.activationOfSelectedMappingError, state => ({
    ...state,
    mappingsLoading: false,
    selectedMappingLoading: false,
  })),

  on(fromActions.getMappingContents, state => ({ ...state, mappingContentsLoading: true })),
  on(fromActions.getMappingContentsComplete, (state, { contents }) => ({
    ...state,
    mappingContentsLoading: false,
    mappingContents: contents,
  })),
  on(fromActions.getMappingContentsError, state => ({
    ...state,
    mappingContentsLoading: false,
  })),
  on(fromActions.deleteSelectedMappingContent, state => ({ ...state, mappingContentsLoading: true })),
  on(fromActions.deleteSelectedMappingContentComplete, state => ({
    ...state,
    mappingContentsLoading: false,
  })),
  on(fromActions.deleteSelectedMappingContentError, state => ({
    ...state,
    mappingContentsLoading: false,
  })),

  on(fromActions.selectMappingContent, state => ({ ...state, selectedMappingContentLoading: true })),
  on(fromActions.selectMappingContentComplete, (state, { content }) => ({
    ...state,
    selectedMappingContentLoading: false,
    selectedMappingContent: content,
  })),
  on(fromActions.selectMappingContentError, state => ({ ...state, selectedMappingContentLoading: false })),

  on(fromActions.updateMappingContent, state => ({ ...state, selectedMappingContentLoading: true, mappingContentsLoading: true })),
  on(fromActions.updateMappingContentComplete, (state, { content }) => ({
    ...state,
    mappingContentsLoading: false,
    selectedMappingContentLoading: false,
    selectedMappingContent: content,
    mappingContents: findAndReplace<TemplateMappingContent>(state.mappingContents, content, item => item.name === content.name),
  })),
  on(fromActions.updateMappingContentError, state => ({
    ...state,
    selectedMappingContent: { ...state.selectedMappingContent! },
    mappingContentsLoading: false,
    selectedMappingContentLoading: false,
  })),
);
