import { HttpErrorResponse } from '@angular/common/http';
import {
  AddAffectedUsersRequest,
  AffectedUserListItem,
  Announcement,
  AnnouncementListItem,
  AnnouncementRequestType,
  AnnouncementsToggleOptions,
  CopyAnnouncementRequest,
  CreateAnnouncementRequest,
  CreateUserListRequest,
  UpdateAnnouncementRequest,
} from '@models/index';
import { createAction, props } from '@ngrx/store';
import { State } from './announcements.reducer';

const NAMESPACE = '[Announcements]';

export const getAll = createAction(`${NAMESPACE} GetAll`, props<{ toggle: AnnouncementsToggleOptions }>());
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ announcements: AnnouncementListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const getAnnouncement = createAction(`${NAMESPACE} GetAnnouncement`, props<{ id: string }>());
export const getAnnouncementComplete = createAction(`${NAMESPACE} GetAnnouncementComplete`, props<{ announcement: Announcement }>());
export const getAnnouncementError = createAction(`${NAMESPACE} GetAnnouncementError`, props<{ err: HttpErrorResponse }>());

export const addNewAnnouncement = createAction(
  `${NAMESPACE} AddNewAnnouncement`,
  props<{ announcement: CreateAnnouncementRequest | CopyAnnouncementRequest }>(),
);
export const addNewAnnouncementComplete = createAction(`${NAMESPACE} AddNewAnnouncementComplete`, props<{ announcement: Announcement }>());
export const addNewAnnouncementError = createAction(`${NAMESPACE} AddNewAnnouncementError`, props<{ err: HttpErrorResponse }>());

export const updateAnnouncement = createAction(
  `${NAMESPACE} UpdateAnnouncement`,
  props<{ id: string; announcement: UpdateAnnouncementRequest }>(),
);
export const updateAnnouncementComplete = createAction(`${NAMESPACE} UpdateAnnouncementComplete`, props<{ announcement: Announcement }>());
export const updateAnnouncementError = createAction(`${NAMESPACE} UpdateAnnouncementError`, props<{ err: HttpErrorResponse }>());

export const deleteAnnouncement = createAction(`${NAMESPACE} DeleteAnnouncement`, props<{ id: string }>());
export const deleteAnnouncementComplete = createAction(`${NAMESPACE} DeleteAnnouncementComplete`, props<{ id: string }>());
export const deleteAnnouncementError = createAction(`${NAMESPACE} DeleteAnnouncementError`, props<{ err: HttpErrorResponse }>());

export const updateAnnouncementConfiguration = createAction(
  `${NAMESPACE} UpdateAnnouncementConfiguration`,
  props<{ id: string; announcement: UpdateAnnouncementRequest }>(),
);

export const goToAnnouncements = createAction(`${NAMESPACE} GoToAnnouncements`);
export const goToAnnouncementActions = createAction(`${NAMESPACE} GoToAnnouncement`, props<{ id: string }>());

export const createUserListForAnnouncement = createAction(
  `${NAMESPACE} CreateUserListForAnnouncement`,
  props<{ id: string; request: CreateUserListRequest }>(),
);
export const createUserListForAnnouncementComplete = createAction(
  `${NAMESPACE} CreateUserListForAnnouncementComplete`,
  props<{ announcement: Announcement }>(),
);
export const createUserListForAnnouncementError = createAction(
  `${NAMESPACE} CreateUserListForAnnouncementError`,
  props<{ err: HttpErrorResponse }>(),
);

export const requestForAnnouncement = createAction(
  `${NAMESPACE} RequestForAnnouncement`,
  props<{ id: string; requestType: AnnouncementRequestType }>(),
);
export const requestForAnnouncementComplete = createAction(
  `${NAMESPACE} RequestForAnnouncementComplete`,
  props<{ announcement: Announcement }>(),
);
export const requestForAnnouncementError = createAction(`${NAMESPACE} RequestForAnnouncementError`, props<{ err: HttpErrorResponse }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());

export const toggleAnnouncement = createAction(`${NAMESPACE} ToggleAnnouncement`, props<{ toggle: AnnouncementsToggleOptions.All }>());

export const getAffectedUsers = createAction(`${NAMESPACE} GetAffectedUsers`, props<{ id: string }>());
export const getAffectedUsersComplete = createAction(
  `${NAMESPACE} GetAffectedUsersComplete`,
  props<{ affectedUsers: AffectedUserListItem[] }>(),
);
export const getAffectedUsersError = createAction(`${NAMESPACE} GetAffectedUsersError`, props<{ err: HttpErrorResponse }>());

export const addAffectedUser = createAction(`${NAMESPACE} AddAffectedUser`, props<{ id: string; usersIds: AddAffectedUsersRequest[] }>());

export const addAffectedUserComplete = createAction(
  `${NAMESPACE} AddAffectedUserComplete`,
  props<{ affectedUsers: AffectedUserListItem[] }>(),
);
export const addAffectedUserError = createAction(`${NAMESPACE} AddAffectedUserError`, props<{ err: HttpErrorResponse }>());

export const deleteAffectedUser = createAction(`${NAMESPACE} DeleteAffectedUser`, props<{ id: string; userId: string }>());
export const deleteAffectedUserComplete = createAction(`${NAMESPACE} DeleteAffectedUserComplete`, props<{ userId: string }>());
export const deleteAffectedUserError = createAction(`${NAMESPACE} DeleteAffectedUserError`, props<{ err: HttpErrorResponse }>());

export const getAffectedUsersCount = createAction(`${NAMESPACE} GetAffectedUsersCount`, props<{ id: string }>());
export const getAffectedUsersCountComplete = createAction(`${NAMESPACE} GetAffectedUsersCountComplete`, props<{ count: number }>());
export const getAffectedUsersCountError = createAction(`${NAMESPACE} GetAffectedUsersCountError`, props<{ err: HttpErrorResponse }>());
