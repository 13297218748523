import { HttpErrorResponse } from '@angular/common/http';

import {
  CreateTemplateGroupAdditionalReceiverRequest,
  CreateTemplateGroupRequest,
  TemplateGroup,
  TemplateGroupAdditionalReceiver,
  UpdateTemplateGroupAdditionalReceiverRequest,
  UpdateTemplateGroupRequest,
} from '@models/notifications';
import { createAction, props } from '@ngrx/store';
import { State } from './template-groups.reducer';

const NAMESPACE = '[TemplateGroups]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ templateGroups: TemplateGroup[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addTemplateGroup = createAction(`${NAMESPACE} AddTemplateGroup`, props<{ templateGroup: CreateTemplateGroupRequest }>());
export const addTemplateGroupComplete = createAction(`${NAMESPACE} AddTemplateGroupComplete`, props<{ templateGroup: TemplateGroup }>());
export const addTemplateGroupError = createAction(`${NAMESPACE} AddTemplateGroupError`, props<{ err: HttpErrorResponse }>());

export const updateTemplateGroup = createAction(
  `${NAMESPACE} UpdateTemplateGroup`,
  props<{ id: string; templateGroup: UpdateTemplateGroupRequest }>(),
);
export const updateTemplateGroupComplete = createAction(
  `${NAMESPACE} UpdateTemplateGroupComplete`,
  props<{ templateGroup: TemplateGroup }>(),
);
export const updateTemplateGroupError = createAction(`${NAMESPACE} UpdateTemplateGroupError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedTemplateGroup = createAction(`${NAMESPACE} DeleteSelectedTemplateGroup`);
export const deleteSelectedTemplateGroupComplete = createAction(`${NAMESPACE} DeleteSelectedTemplateGroupComplete`);
export const deleteSelectedTemplateGroupError = createAction(
  `${NAMESPACE} DeleteSelectedTemplateGroupError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectTemplateGroup = createAction(`${NAMESPACE} SelectTemplateGroup`, props<{ id: string }>());
export const selectTemplateGroupComplete = createAction(
  `${NAMESPACE} SelectTemplateGroupComplete`,
  props<{ templateGroup: TemplateGroup }>(),
);
export const selectTemplateGroupError = createAction(`${NAMESPACE} SelectTemplateGroupError`, props<{ err: HttpErrorResponse }>());

export const goToTemplateGroupActions = createAction(`${NAMESPACE} GoToTemplateGroupActions`, props<{ templateGroupId: string }>());
export const goToTemplateGroupListPage = createAction(`${NAMESPACE} GoToTemplateGroupListPage`);

export const getAdditionalReceivers = createAction(`${NAMESPACE} GetAdditionalReceivers`, props<{ templateGroupId: string }>());
export const getAdditionalReceiversComplete = createAction(
  `${NAMESPACE} GetAdditionalReceiversComplete`,
  props<{ additionalReceivers: TemplateGroupAdditionalReceiver[] }>(),
);
export const getAdditionalReceiversError = createAction(`${NAMESPACE} GetAdditionalReceiversError`, props<{ err: HttpErrorResponse }>());

export const addAdditionalReceiver = createAction(
  `${NAMESPACE} AddAdditionalReceiver`,
  props<{ templateGroupId: string; additionalReceiver: CreateTemplateGroupAdditionalReceiverRequest }>(),
);
export const addAdditionalReceiverComplete = createAction(
  `${NAMESPACE} AddAdditionalReceiverComplete`,
  props<{ additionalReceiver: TemplateGroupAdditionalReceiver }>(),
);
export const addAdditionalReceiverError = createAction(`${NAMESPACE} AddAdditionalReceiverError`, props<{ err: HttpErrorResponse }>());

export const updateAdditionalReceiver = createAction(
  `${NAMESPACE} updateAdditionalReceiver`,
  props<{ templateGroupId: string; additionalReceiverId: string; additionalReceiver: UpdateTemplateGroupAdditionalReceiverRequest }>(),
);
export const updateAdditionalReceiverComplete = createAction(
  `${NAMESPACE} updateAdditionalReceiverComplete`,
  props<{ additionalReceiver: TemplateGroupAdditionalReceiver }>(),
);
export const updateAdditionalReceiverError = createAction(
  `${NAMESPACE} updateAdditionalReceiverError`,
  props<{ err: HttpErrorResponse }>(),
);

export const deleteSelectedAdditionalReceiver = createAction(
  `${NAMESPACE} DeleteAdditionalReceiver`,
  props<{ templateGroupId: string; additionalReceiverId: string }>(),
);
export const deleteSelectedAdditionalReceiverComplete = createAction(
  `${NAMESPACE} DeleteAdditionalReceiverComplete`,
  props<{ templateGroupId: string }>(),
);
export const deleteSelectedAdditionalReceiverError = createAction(
  `${NAMESPACE} DeleteAdditionalReceiverError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectAdditionalReceiver = createAction(
  `${NAMESPACE} SelectAdditionalReceiver`,
  props<{ templateGroupId: string; additionalReceiverId: string }>(),
);
export const selectAdditionalReceiverComplete = createAction(
  `${NAMESPACE} SelectAdditionalReceiverComplete`,
  props<{ additionalReceiver: TemplateGroupAdditionalReceiver }>(),
);
export const selectAdditionalReceiverError = createAction(
  `${NAMESPACE} SelectAdditionalReceiverError`,
  props<{ err: HttpErrorResponse }>(),
);

export const goToAdditionalReceiverActions = createAction(
  `${NAMESPACE} GoToAdditionalReceiverActions`,
  props<{ templateGroupId: string; additionalReceiverId: string }>(),
);
export const goToAdditionalReceiverListPage = createAction(
  `${NAMESPACE} GoToAdditionalReceiverListPage`,
  props<{ templateGroupId: string }>(),
);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
