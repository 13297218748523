import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { roleGroupsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './role-groups.actions';
import { RoleGroupsService } from './role-groups.service';

@Injectable()
export class RoleGroupsEffects {
  onGetRoleGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.roleGroupsService.getRoleGroups().pipe(
          map(roleGroups => fromActions.getAllComplete({ roleGroups })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onGetGroupedRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getGroupedRoles),
      switchMap(({ filter }) =>
        this.roleGroupsService.getGroupedRoles(filter).pipe(
          map(groupedRoles => fromActions.getGroupedRolesComplete({ groupedRoles })),
          catchError(err => of(fromActions.getGroupedRolesError({ err }))),
        ),
      ),
    ),
  );

  onSelectRoleGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectRoleGroup),
      switchMap(({ id }) =>
        this.store.pipe(
          select(roleGroupsSelectors.selectRoleGroups),
          map(roleGroups => {
            const roleGroup = roleGroups.find(r => r.id === id)!;
            return fromActions.selectRoleGroupComplete({ roleGroup });
          }),
        ),
      ),
    ),
  );

  onGetRoleGroupRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getRoleGroupRoles),
      switchMap(({ id }) =>
        this.roleGroupsService.getRoleGroupRoles(id).pipe(
          map(roleNames => fromActions.getRoleGroupRolesComplete({ roleNames })),
          catchError(err => of(fromActions.getRoleGroupRolesError({ err }))),
        ),
      ),
    ),
  );

  onAddNewRoleGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewRoleGroup),
      switchMap(({ roleGroup }) =>
        this.roleGroupsService.addNewRoleGroup(roleGroup).pipe(
          map(addedRoleGroup => fromActions.addNewRoleGroupComplete({ roleGroup: addedRoleGroup })),
          catchError(err => of(fromActions.addNewRoleGroupError({ err }))),
        ),
      ),
    ),
  );

  onAddNewRoleGroupComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewRoleGroupComplete),
        switchMap(({ roleGroup }) => {
          return this.router.navigate(['role-groups', roleGroup.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUpdateRoleGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRoleGroup),
      switchMap(({ roleGroup }) => {
        const { id, ...rest } = roleGroup;
        return this.roleGroupsService.updateRoleGroup(id, rest).pipe(
          map(updatedRoleGroup => fromActions.updateRoleGroupComplete({ roleGroup: updatedRoleGroup })),
          catchError(err => of(fromActions.updateRoleGroupError({ err }))),
        );
      }),
    ),
  );

  onUpdateRoleGroupComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRoleGroupComplete),
      map(() => fromActions.getAll()),
    ),
  );

  onDeleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedRoleGroup),
      switchMap(() =>
        this.store.pipe(
          select(roleGroupsSelectors.selectActiveRoleGroupId),
          take(1),
          switchMap(id =>
            this.roleGroupsService.deleteRoleGroup(id!).pipe(
              map(() => fromActions.deleteSelectedRoleGroupComplete()),
              catchError(err => of(fromActions.deleteSelectedRoleGroupError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteRoleComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedRoleGroupComplete),
      tap(() => this.router.navigate(['role-groups'])),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToRoleGroupActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToRoleGroupActions),
        tap(({ id }) => this.router.navigate(['role-groups', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['role-groups'])),
      map(() => fromActions.resetState({ selectedRoleGroup: null })),
    ),
  );

  onGoToSelectedRoleGroupActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedRoleGroupActions),
        switchMap(() =>
          this.store.pipe(
            select(roleGroupsSelectors.selectActiveRoleGroupId),
            take(1),
            tap(id => this.router.navigate(['role-groups', id])),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly roleGroupsService: RoleGroupsService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}
}
