import { HttpErrorResponse } from '@angular/common/http';
import { Product, ProductUpdateRequest } from '@models/products-and-orders/products';
import { createAction, props } from '@ngrx/store';
import { State } from './products.reducer';

const NAMESPACE = '[Products]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ products: Product[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const updateProduct = createAction(`${NAMESPACE} UpdateProduct`, props<{ id: string; product: ProductUpdateRequest }>());
export const updateProductComplete = createAction(`${NAMESPACE} UpdateProductComplete`, props<{ product: Product }>());
export const updateProductError = createAction(`${NAMESPACE} UpdateProductError`, props<{ err: HttpErrorResponse }>());

export const activateProduct = createAction(`${NAMESPACE} ActivateProduct`, props<{ id: string }>());
export const activateProductComplete = createAction(`${NAMESPACE} ActivateProductComplete`, props<{ product: Product }>());
export const activateProductError = createAction(`${NAMESPACE} ActivateProductError`, props<{ err: HttpErrorResponse }>());

export const deactivateProduct = createAction(`${NAMESPACE} DeactivateProduct`, props<{ id: string }>());
export const deactivateProductComplete = createAction(`${NAMESPACE} DeactivateProductComplete`, props<{ product: Product }>());
export const deactivateProductError = createAction(`${NAMESPACE} DeactivateProductError`, props<{ err: HttpErrorResponse }>());

export const selectProduct = createAction(`${NAMESPACE} SelectProduct`, props<{ id: string }>());
export const selectProductComplete = createAction(`${NAMESPACE} SelectProductComplete`, props<{ product: Product }>());
export const selectProductError = createAction(`${NAMESPACE} SelectProductError`, props<{ err: HttpErrorResponse }>());

export const goToProductActions = createAction(`${NAMESPACE} GoToProductActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
