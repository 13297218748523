import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogsQueryParams, NotificationLog, NotificationLogListItem } from '@models/notifications';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificationLogsService {
  private readonly url = `${environment.bffUrl}/notification-logs`;
  constructor(private httpClient: HttpClient) {}

  getAll(params: LogsQueryParams): Observable<NotificationLogListItem[]> {
    return this.httpClient.get<NotificationLogListItem[]>(this.url, { params });
  }

  getOne(logId: string): Observable<NotificationLog> {
    return this.httpClient.get<NotificationLog>(`${this.url}/${logId}`);
  }

  resend(logId: string): Observable<NotificationLog> {
    return this.httpClient.post<NotificationLog>(`${this.url}/${logId}/resend`, undefined);
  }

  sendToAnotherEmail(logId: string, email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${logId}/send-to-another-email`, { email });
  }
}
