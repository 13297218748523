export const findAndReplace = <T>(array: T[], newItem: T, predicate: (item: T) => boolean): T[] => {
  const newArray: T[] = [];

  for (const item of array) {
    if (predicate(item)) {
      newArray.push(newItem);
    } else {
      newArray.push(item);
    }
  }

  return newArray;
};

export function findAndRemove<T>(array: T[], predicate: (item: T) => boolean): T[] {
  const index = array.findIndex(predicate);
  const newArray = [...array];
  if (index !== -1) {
    newArray.splice(index, 1);
  }
  return newArray;
}

export const sortAlphabetically = <T>(array: T[], sorting: (a: T, b: T) => number): T[] => {
  return array.slice().sort((a, b) => sorting(a, b));
};

export const predicateForSorting = <T>(propertyToCompare: keyof T): ((a: T, b: T) => number) => {
  return (a: T, b: T) => {
    const propA = a[propertyToCompare];
    const propB = b[propertyToCompare];
    if (propA === undefined && propB === undefined) {
      return 0; // If both names are undefined, consider them equal
    } else if (propA === undefined) {
      return 1; // Place objects with undefined names after objects with defined names
    } else if (propB === undefined) {
      return -1; // Place objects with defined names before objects with undefined names
    } else if (typeof propA === 'string' && typeof propB === 'string') {
      return (propA as string).localeCompare(propB as string); // Compare names if both are defined
    } else {
      return 0;
    }
  };
};
