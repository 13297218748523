import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Topic, TopicCreate, TopicOwner, TopicUpdate } from '@models/marketing/topics/topics.model';
import { SubscriptionListItem } from '@models/subscriptions';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TopicsService {
  private readonly url = `${environment.bffUrl}/topics`;
  private readonly subscribersUrl = `${environment.bffUrl}/subscriptions`;
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<Topic[]> {
    return this.httpClient.get<Topic[]>(this.url);
  }

  get(id: string): Observable<Topic> {
    return this.httpClient.get<Topic>(`${this.url}/${id}`);
  }

  getSubscribers(id: string): Observable<SubscriptionListItem[]> {
    return this.httpClient.get<SubscriptionListItem[]>(`${this.subscribersUrl}/topics/${id}`);
  }

  getOwners(id: string): Observable<TopicOwner[]> {
    return this.httpClient.get<TopicOwner[]>(`${this.url}/${id}/owners`);
  }

  getOwner(topicId: string, ownerId: string): Observable<TopicOwner> {
    return this.httpClient.get<TopicOwner>(`${this.url}/${topicId}/owners/${ownerId}`);
  }

  addOwner(id: string, ownerEmail: string): Observable<TopicOwner> {
    return this.httpClient.post<TopicOwner>(`${this.url}/${id}/owners`, { ownerEmail });
  }

  add(topic: TopicCreate): Observable<Topic> {
    return this.httpClient.post<Topic>(this.url, topic);
  }

  update(id: string, topic: TopicUpdate): Observable<Topic> {
    return this.httpClient.put<Topic>(`${this.url}/${id}`, topic);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  deleteOwner(topicId: string, ownerId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${topicId}/owners/${ownerId}`);
  }
}
