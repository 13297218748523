import { GroupedRoles, Permission, PermissionAttribute } from '@models/authorization';
import { createReducer, on } from '@ngrx/store';
import { sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './permissions.actions';
export interface State {
  permissions: Permission[];
  isLoading: boolean;

  selectedPermission: Permission | null;
  selectPermissionLoading: boolean;

  roles: GroupedRoles[];
  rolesLoading: boolean;

  attributes: PermissionAttribute[];
  attributesLoading: boolean;
}

export const initialState: State = {
  permissions: [],
  isLoading: false,

  selectedPermission: null,
  selectPermissionLoading: false,

  roles: [],
  rolesLoading: false,

  attributes: [],
  attributesLoading: false,
};

export const permissionsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.getAll, state => ({ ...state, isLoading: true, selectPermissionLoading: true })),
  on(fromActions.getAllComplete, (state, { permissions }) => ({
    ...state,
    isLoading: false,
    selectPermissionLoading: false,
    permissions: sortAlphabetically([...permissions], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false, selectPermissionLoading: false })),

  on(fromActions.addNewPermission, state => ({ ...state, isLoading: true })),
  on(fromActions.addNewPermissionComplete, (state, { permission }) => ({
    ...state,
    isLoading: false,
    permissions: sortAlphabetically([...state.permissions, permission], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addNewPermissionError, state => ({ ...state, isLoading: false })),

  on(fromActions.updatePermission, state => ({ ...state, selectPermissionLoading: true })),
  on(fromActions.updatePermissionComplete, (state, { permission }) => ({
    ...state,
    selectedPermission: permission,
    selectPermissionLoading: false,
  })),
  on(fromActions.updatePermissionError, state => ({
    ...state,
    selectedPermission: { ...state.selectedPermission! },
    selectPermissionLoading: false,
  })),

  on(fromActions.patchSelectedPermissionRoles, state => ({ ...state, rolesLoading: true })),
  on(fromActions.patchSelectedPermissionRolesComplete, (state, { roles }) => ({
    ...state,
    roles: roles,
    rolesLoading: false,
  })),
  on(fromActions.patchSelectedPermissionRolesError, state => ({
    ...state,
    roles: [...state.roles],
    rolesLoading: false,
  })),

  on(fromActions.selectPermission, state => ({ ...state, selectPermissionLoading: true, attributesLoading: true })),
  on(fromActions.selectPermissionComplete, (state, { permission }) => ({
    ...state,
    selectPermissionLoading: false,
    selectedPermission: permission,
    attributesLoading: false,
  })),
  on(fromActions.selectPermissionError, state => ({
    ...state,
    selectPermissionLoading: false,
    attributesLoading: false,
  })),
  on(fromActions.getSelectedPermissionRoles, state => ({ ...state, rolesLoading: true })),
  on(fromActions.getSelectedPermissionRolesComplete, (state, { roles }) => ({
    ...state,
    rolesLoading: false,
    roles: roles,
  })),
  on(fromActions.getSelectedPermissionRolesError, state => ({
    ...state,
    rolesLoading: false,
  })),

  on(fromActions.deleteSelectedPermission, state => ({ ...state, selectPermissionLoading: true })),
  on(fromActions.deleteSelectedPermissionComplete, state => ({
    ...state,
    selectPermissionLoading: false,
  })),
  on(fromActions.deleteSelectedPermissionError, state => ({
    ...state,
    selectPermissionLoading: false,
  })),

  on(fromActions.getPermissionAttributes, state => ({ ...state, attributesLoading: true })),
  on(fromActions.getPermissionAttributesComplete, (state, { attributes }) => ({
    ...state,
    attributesLoading: false,
    attributes: sortAlphabetically<PermissionAttribute>(attributes, (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getPermissionAttributesError, state => ({
    ...state,
    attributesLoading: false,
  })),

  on(fromActions.addPermissionAttribute, state => ({ ...state, attributesLoading: true, attributesChangeInProgress: true })),
  on(fromActions.addPermissionAttributeComplete, (state, { attribute }) => ({
    ...state,
    attributesLoading: false,
    attributes: sortAlphabetically<PermissionAttribute>([...state.attributes, attribute], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addPermissionAttributeError, state => ({
    ...state,
    attributesLoading: false,
  })),
  on(fromActions.deletePermissionAttribute, state => ({ ...state, attributesLoading: true })),
  on(fromActions.deletePermissionAttributeComplete, (state, { attributeId }) => ({
    ...state,
    attributesLoading: false,
    attributes: state.attributes.filter(attribute => attribute.id !== attributeId),
  })),
  on(fromActions.deletePermissionAttributeError, state => ({
    ...state,
    attributesLoading: false,
  })),
  on(fromActions.getPermissionForAttributePage, state => ({ ...state, selectPermissionLoading: true, attributesLoading: true })),
  on(fromActions.getPermissionForAttributePageComplete, (state, { permission }) => ({
    ...state,
    selectPermissionLoading: false,
    selectedPermission: permission,
    attributesLoading: false,
  })),
  on(fromActions.getPermissionForAttributePageError, state => ({
    ...state,
    selectPermissionLoading: false,
    attributesLoading: false,
  })),
);
