import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.notificationReceiverGroups);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsGroupCreateLoading = createSelector(selectModuleState, state => state.groupCreateLoading);
export const selectGroups = createSelector(selectModuleState, state => state.receiverGroups);

export const selectSelectedGroup = createSelector(selectModuleState, state => state.selectedReceiverGroup);
export const selectSelectedGroupId = createSelector(
  selectModuleState,
  state => state.selectedReceiverGroup && state.selectedReceiverGroup.id,
);
export const selectSelectedGroupLoading = createSelector(selectModuleState, state => state.selectedReceiverGroupLoading);

export const selectIsCombinedEmailCreateLoading = createSelector(selectModuleState, state => state.combinedEmailCreateLoading);
export const selectCombinedEmails = createSelector(
  selectModuleState,
  state => state.selectedReceiverGroup && state.selectedReceiverGroup.combinedEmails,
);

export const selectSelectedCombinedEmail = createSelector(selectModuleState, state => state.selectedCombinedEmail);
export const selectSelectedCombinedEmailEmail = createSelector(
  selectModuleState,
  state => state.selectedCombinedEmail && state.selectedCombinedEmail.email,
);
export const selectSelectedCombinedEmailLoading = createSelector(selectModuleState, state => state.selectedCombinedEmailLoading);
