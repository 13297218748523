import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupedRoles, RoleType, UpdateRoleForEntityRequest } from '@models/authorization';
import { Partner, PartnerAdminUser, PartnerAdminUserResponse, PartnerBusinessData, PartnerListItem } from '@models/partners';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class PartnersService {
  private readonly url = `${environment.bffUrl}/partners`;
  constructor(private httpClient: HttpClient) {}

  getPartners(includeMarkedForDeletion = false): Observable<PartnerListItem[]> {
    return this.httpClient.get<PartnerListItem[]>(this.url, { params: { includeMarkedForDeletion } });
  }

  getPartner(id: string): Observable<Partner> {
    return this.httpClient.get<Partner>(`${this.url}/${id}`);
  }

  getPartnerBusinessData(id: string): Observable<PartnerBusinessData> {
    return this.httpClient.get<PartnerBusinessData>(`${this.url}/${id}/business-data`);
  }

  setPartnerRoles(id: string, roles: UpdateRoleForEntityRequest[], filter: RoleType): Observable<GroupedRoles[]> {
    return this.httpClient.patch<GroupedRoles[]>(`${this.url}/${id}/roles`, roles, {
      params: { type: filter },
    });
  }

  getPartnerRoles(id: string, filter: RoleType): Observable<GroupedRoles[]> {
    return this.httpClient.get<GroupedRoles[]>(`${this.url}/${id}/roles`, { params: { type: filter } });
  }

  markForDeletion(id: string): Observable<void> {
    return this.httpClient.post<void>(`${this.url}/${id}/mark-for-deletion`, undefined);
  }

  setCrmAccId(partnerId: string, crmAccountId: string): Observable<Partner> {
    return this.httpClient.post<Partner>(`${this.url}/${partnerId}/set-crm-account-id`, { crmAccountId });
  }

  getAdminUsers(partnerId: string): Observable<PartnerAdminUser[]> {
    return this.httpClient.get<PartnerAdminUserResponse[]>(`${this.url}/${partnerId}/administrators`);
  }
}
