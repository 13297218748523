import { ActionLog } from '@models/action-log/action-log.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './action-logs.actions';

export interface State extends EntityState<ActionLog> {
  selectedActionLogId: string | null;
  isLoadingList: boolean;
  isLoadingItem: boolean;
}

export const adapter: EntityAdapter<ActionLog> = createEntityAdapter<ActionLog>({
  selectId: (actionLog: ActionLog) => actionLog.id,
});

export const initialState: State = adapter.getInitialState({
  selectedActionLogId: null,
  isLoadingList: false,
  isLoadingItem: false,
});

export const actionLogsReducer = createReducer(
  initialState,
  on(fromActions.query, state => ({ ...state, isLoadingList: true })),
  on(fromActions.queryComplete, (state, { actionLogs }) => adapter.setAll(actionLogs, { ...state, isLoadingList: false })),
  on(fromActions.queryError, state => adapter.removeAll({ ...state, isLoadingList: false })),

  on(fromActions.getActionLogById, (state, { actionId }) => ({
    ...state,
    selectedActionLogId: actionId,
    isLoadingItem: true,
  })),
  on(fromActions.getActionLogComplete, (state, { actionLog }) => adapter.upsertOne(actionLog, { ...state, isLoadingItem: false })),
  on(fromActions.getActionLogError, state => ({
    ...state,
    selectedActionLogId: null,
    isLoadingItem: false,
  })),
);
