import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.functionalRoles);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsFuncRolesLoading = createSelector(selectModuleState, state => state.funcRolesIsLoading);
export const selectFuncRoles = createSelector(selectModuleState, state => state.functionalRoles);
export const selectAddInProgress = createSelector(selectModuleState, state => state.addInProgress);
export const selectFunctionalRoleAssignments = createSelector(selectModuleState, state => state.functionalRolesAssignments);
