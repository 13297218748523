import { GroupedRoles } from '@models/authorization';
import { Partner, PartnerAdminUser, PartnerBusinessData, PartnerListItem } from '@models/partners';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './partners.actions';

export interface State {
  isLoading: boolean;
  partners: PartnerListItem[];

  toggle: fromActions.ToggleOptionsName;

  isSelectPartnerLoading: boolean;
  selectedPartner: Partner | null;

  isBusinessDataLoading: boolean;
  partnerBusinessData: PartnerBusinessData | null;

  isRolesLoading: boolean;
  partnerRoles: GroupedRoles[];

  isPartnerAdminUsersLoading: boolean;
  partnerAdminUsers: PartnerAdminUser[];
}

export const initialState: State = {
  isLoading: false,
  partners: [],

  isSelectPartnerLoading: false,
  selectedPartner: null,

  isBusinessDataLoading: false,
  partnerBusinessData: null,

  isRolesLoading: false,
  partnerRoles: [],

  toggle: 'Operative',

  isPartnerAdminUsersLoading: false,
  partnerAdminUsers: [],
};

export const partnersReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.getAll, (state, { toggle }) => ({ ...state, toggle: toggle ?? state.toggle, isLoading: true })),
  on(fromActions.getAllComplete, (state, { partners }) => ({ ...state, isLoading: false, partners })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectPartner, state => ({ ...state, isSelectPartnerLoading: true, partnerRoles: [], partnerBusinessData: null })),
  on(fromActions.selectPartnerComplete, (state, { partner }) => ({
    ...state,
    isSelectPartnerLoading: false,
    selectedPartner: partner,
  })),
  on(fromActions.selectPartnerError, state => ({ ...state, isSelectPartnerLoading: false })),

  on(fromActions.getPartnerBusinessData, state => ({ ...state, isBusinessDataLoading: true })),
  on(fromActions.getPartnerBusinessDataComplete, (state, { businessData }) => ({
    ...state,
    isBusinessDataLoading: false,
    partnerBusinessData: businessData,
  })),
  on(fromActions.getPartnerBusinessDataError, state => ({ ...state, isBusinessDataLoading: false })),

  on(fromActions.setCrmAccId, state => ({ ...state, isSelectPartnerLoading: true })),
  on(fromActions.setCrmAccIdComplete, (state, { partner }) => ({
    ...state,
    isSelectPartnerLoading: false,
    selectedPartner: partner,
  })),
  on(fromActions.setCrmAccIdError, state => ({ ...state, isSelectPartnerLoading: false })),

  on(fromActions.setPartnerRoles, state => ({ ...state, isRolesLoading: true })),
  on(fromActions.setPartnerRolesComplete, (state, { roles }) => ({
    ...state,
    partnerRoles: roles,
    isRolesLoading: false,
  })),
  on(fromActions.setPartnerRolesError, state => ({ ...state, isRolesLoading: false })),

  on(fromActions.getPartnerRoles, state => ({ ...state, isRolesLoading: true })),
  on(fromActions.getPartnerRolesComplete, (state, { roles }) => ({
    ...state,
    partnerRoles: roles,
    isRolesLoading: false,
  })),
  on(fromActions.getPartnerRolesError, state => ({ ...state, isRolesLoading: false })),

  on(fromActions.markForDeletion, state => ({ ...state, isSelectPartnerLoading: true })),
  on(fromActions.markForDeletionComplete, state => ({
    ...state,
    selectedPartner: null,
    isSelectPartnerLoading: false,
  })),
  on(fromActions.markForDeletionError, state => ({
    ...state,
    isSelectPartnerLoading: false,
  })),

  on(fromActions.getAdminUsers, state => ({ ...state, isPartnerAdminUsersLoading: true })),
  on(fromActions.getAdminUsersComplete, (state, { admins }) => ({
    ...state,
    isPartnerAdminUsersLoading: false,
    partnerAdminUsers: admins,
  })),
  on(fromActions.getAdminUsersError, state => ({ ...state, isPartnerAdminUsersLoading: false })),
);
