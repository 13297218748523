import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { receiverGroupsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './receiver-groups.actions';
import { ReceiverGroupsService } from './receiver-groups.service';

@Injectable()
export class ReceiverGroupsEffects {
  onGetGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.receiverGroupsService.getAll().pipe(
          map(groups => fromActions.getAllComplete({ groups })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addGroup),
      switchMap(({ group }) =>
        this.receiverGroupsService.add(group).pipe(
          map(group => fromActions.addGroupComplete({ group })),
          catchError(err => of(fromActions.addGroupError({ err }))),
        ),
      ),
    ),
  );

  onAddGroupComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addGroupComplete),
        switchMap(response => {
          return this.router.navigate(['notifications', 'groups', response.group.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUpdateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateGroup),
      switchMap(({ id, updatedGroup }) =>
        this.receiverGroupsService.update(id, updatedGroup).pipe(
          map(group => fromActions.updateGroupComplete({ group })),
          catchError(err => of(fromActions.updateGroupError({ err }))),
        ),
      ),
    ),
  );

  onSelectGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectGroup),
      switchMap(({ id }) =>
        this.store.pipe(
          select(receiverGroupsSelectors.selectGroups),
          take(1),
          switchMap(groups => {
            if (groups.length) {
              const group = groups.find(g => g.id === id)!;
              return of(fromActions.selectGroupComplete({ group }));
            } else {
              return this.receiverGroupsService.get(id).pipe(
                map(group => fromActions.selectGroupComplete({ group })),
                catchError(err => of(fromActions.selectGroupError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onDeleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedGroup),
      switchMap(() =>
        this.store.pipe(
          select(receiverGroupsSelectors.selectSelectedGroup),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(group =>
            this.receiverGroupsService.delete(group.id).pipe(
              map(() => fromActions.deleteSelectedGroupComplete()),
              catchError(err => of(fromActions.deleteSelectedGroupError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteGroupComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedGroupComplete),
      tap(() => this.router.navigate(['notifications', 'groups'])),
      map(() => fromActions.getAll()),
    ),
  );

  onSelectCombinedEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectCombinedEmail),
      switchMap(({ email }) =>
        this.store.pipe(
          select(receiverGroupsSelectors.selectCombinedEmails),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(combinedEmails => {
            const combinedEmail = combinedEmails.find(e => e.email === email)!;
            return of(fromActions.selectCombinedEmailComplete({ combinedEmail }));
          }),
        ),
      ),
    ),
  );

  onDeleteCombinedEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedCombinedEmail),
      switchMap(({ id, updatedGroup }) =>
        this.receiverGroupsService.update(id, updatedGroup).pipe(
          map(group => fromActions.deleteSelectedCombinedEmailComplete({ id, group })),
          catchError(err => of(fromActions.deleteSelectedCombinedEmailError({ err }))),
        ),
      ),
    ),
  );

  onDeleteSelectedCombinedEmailComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedCombinedEmailComplete),
      tap(({ id }) => this.router.navigate(['notifications', 'groups', id, 'emails'])),
      map(() => fromActions.getAll()),
    ),
  );

  onAddCombinedEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCombinedEmail),
      switchMap(({ id, updatedGroup, combinedEmail }) =>
        this.receiverGroupsService.update(id, updatedGroup).pipe(
          map(group => fromActions.addCombinedEmailComplete({ id, group, combinedEmail })),
          catchError(err => of(fromActions.addCombinedEmailError({ err }))),
        ),
      ),
    ),
  );

  onUpdateCombinedEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCombinedEmail),
      switchMap(({ id, updatedGroup, combinedEmail }) =>
        this.receiverGroupsService.update(id, updatedGroup).pipe(
          map(group => fromActions.updateCombinedEmailComplete({ id, group, combinedEmail })),
          catchError(err => of(fromActions.updateCombinedEmailError({ err }))),
        ),
      ),
    ),
  );

  onAddCombinedEmailComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addCombinedEmailComplete),
        tap(({ id, combinedEmail }) => this.router.navigate(['notifications', 'groups', id, 'emails', combinedEmail.email, 'details'])),
      ),
    { dispatch: false },
  );

  onGoToGroupActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToGroupActions),
        tap(({ id }) => this.router.navigate(['notifications', 'groups', id])),
      ),
    { dispatch: false },
  );

  onGoToCombinedEmailActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCombinedEmailActions),
        tap(({ id, email }) => this.router.navigate(['notifications', 'groups', id, 'emails', email])),
        map(() => fromActions.resetState({ selectedCombinedEmail: null })),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToGroupsListPage),
      tap(() => this.router.navigate(['notifications', 'groups'])),
      map(() => fromActions.resetState({ selectedReceiverGroup: null })),
    ),
  );

  onGoToGroupEmailsListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToCombinedEmailsListPage),
      tap(({ id }) => this.router.navigate(['notifications', 'groups', id, 'emails'])),
      map(() => fromActions.resetState({ selectedCombinedEmail: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private receiverGroupsService: ReceiverGroupsService,
  ) {}
}
