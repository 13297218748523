import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as fromActions from './announcements.actions';
import { AnnouncementsService } from './announcements.service';

@Injectable()
export class AnnouncementsEffects {
  onGetAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(({ toggle }) =>
        this.announcementsService.getAnnouncements(toggle).pipe(
          map(announcements => fromActions.getAllComplete({ announcements })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onGetAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAnnouncement),
      switchMap(({ id }) =>
        this.announcementsService.getAnnouncement(id).pipe(
          map(announcement => fromActions.getAnnouncementComplete({ announcement })),
          catchError(err => of(fromActions.getAnnouncementError({ err }))),
        ),
      ),
    ),
  );

  onAddNewAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewAnnouncement),
      switchMap(({ announcement }) =>
        this.announcementsService.addAnnouncement(announcement).pipe(
          map(announcement => fromActions.addNewAnnouncementComplete({ announcement })),
          catchError(err => of(fromActions.addNewAnnouncementError({ err }))),
        ),
      ),
    ),
  );

  onAddNewAnnouncementComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewAnnouncementComplete),
        tap(({ announcement }) => this.router.navigate(['marketing', 'announcements', announcement.id, 'details'])),
      ),
    { dispatch: false },
  );

  onUpdateAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAnnouncement),
      switchMap(({ id, announcement }) =>
        this.announcementsService.updatePermission(id, announcement).pipe(
          map(announcement => fromActions.updateAnnouncementComplete({ announcement })),
          catchError(err => of(fromActions.updateAnnouncementError({ err }))),
        ),
      ),
    ),
  );

  onUpdateAnnouncementComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.updateAnnouncementComplete),
        switchMap(({ announcement }) => this.router.navigate(['marketing', 'announcements', announcement.id, 'details'])),
      ),
    { dispatch: false },
  );

  onDeleteAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteAnnouncement),
      switchMap(({ id }) =>
        this.announcementsService.deleteAnnouncement(id).pipe(
          map(() => fromActions.deleteAnnouncementComplete({ id })),
          catchError(err => of(fromActions.deleteAnnouncementError({ err }))),
        ),
      ),
    ),
  );

  onCreateUserListForAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createUserListForAnnouncement),
      switchMap(({ id, request }) =>
        this.announcementsService.createUserListForAnnouncement(id, request).pipe(
          map(announcement => fromActions.updateAnnouncementComplete({ announcement })),
          catchError(err => of(fromActions.updateAnnouncementError({ err }))),
        ),
      ),
    ),
  );

  onRequestForAnnouncement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.requestForAnnouncement),
      switchMap(({ id, requestType }) =>
        this.announcementsService.requestForAnnouncement(id, requestType).pipe(
          map(announcement => fromActions.requestForAnnouncementComplete({ announcement })),
          catchError(err => of(fromActions.requestForAnnouncementError({ err }))),
        ),
      ),
    ),
  );

  onGetAffectedUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAffectedUsers),
      switchMap(({ id }) =>
        this.announcementsService.getAffectedUsers(id).pipe(
          map(affectedUsers => fromActions.getAffectedUsersComplete({ affectedUsers })),
          catchError(err => of(fromActions.getAffectedUsersError({ err }))),
        ),
      ),
    ),
  );

  onAddAffectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAffectedUser),
      switchMap(({ id, usersIds }) =>
        this.announcementsService.addAffectedUser(id, usersIds).pipe(
          map(affectedUsers => fromActions.addAffectedUserComplete({ affectedUsers })),
          catchError(err => of(fromActions.addAffectedUserError({ err }))),
        ),
      ),
    ),
  );

  onDeleteAffectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteAffectedUser),
      switchMap(({ id, userId }) =>
        this.announcementsService.deleteAffectedUser(id, userId).pipe(
          map(() => fromActions.deleteAffectedUserComplete({ userId })),
          catchError(err => of(fromActions.deleteAffectedUserError({ err }))),
        ),
      ),
    ),
  );

  onGetAffectedUsersCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAffectedUsersCount),
      switchMap(({ id }) =>
        this.announcementsService.getAffectedUsersCount(id).pipe(
          map(count => fromActions.getAffectedUsersCountComplete(count)),
          catchError(err => of(fromActions.getAffectedUsersCountError({ err }))),
        ),
      ),
    ),
  );

  onGoToAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToAnnouncements, fromActions.deleteAnnouncementComplete, fromActions.getAnnouncementError),
      tap(() => this.router.navigate(['marketing', 'announcements'])),
      map(() => fromActions.resetState({ selectedAnnouncement: null, affectedUsers: [], affectedUsersCount: 0 })),
    ),
  );

  onGoToAnnouncementActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToAnnouncementActions),
        switchMap(({ id }) => this.router.navigate(['marketing', 'announcements', id])),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly announcementsService: AnnouncementsService,
    private readonly router: Router,
  ) {}
}
