import { HttpErrorResponse } from '@angular/common/http';
import {
  AddPermissionRequest,
  CreatePermissionAttributeRequest,
  GroupedRoles,
  Permission,
  PermissionAttribute,
  RoleType,
  UpdatePermissionRequest,
  UpdateRoleForEntityRequest,
} from '@models/authorization';
import { createAction, props } from '@ngrx/store';
import { State } from './permissions.reducer';

const NAMESPACE = '[Authorization Permissions]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ permissions: Permission[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addNewPermission = createAction(`${NAMESPACE} AddNewPermission`, props<{ permission: AddPermissionRequest }>());
export const addNewPermissionComplete = createAction(`${NAMESPACE} AddNewPermissionComplete`, props<{ permission: Permission }>());
export const addNewPermissionError = createAction(`${NAMESPACE} AddNewPermissionError`, props<{ err: HttpErrorResponse }>());

export const selectPermission = createAction(`${NAMESPACE} SelectPermission`, props<{ id: string }>());
export const selectPermissionComplete = createAction(`${NAMESPACE} SelectPermissionComplete`, props<{ permission: Permission | null }>());
export const selectPermissionError = createAction(`${NAMESPACE} SelectPermissionError`, props<{ err: HttpErrorResponse }>());

export const getSelectedPermissionRoles = createAction(
  `${NAMESPACE} GetSelectedPermissionRoles`,
  props<{ id: string; filter: RoleType }>(),
);
export const getSelectedPermissionRolesComplete = createAction(
  `${NAMESPACE} GetSelectedPermissionRolesComplete`,
  props<{ roles: GroupedRoles[] }>(),
);
export const getSelectedPermissionRolesError = createAction(
  `${NAMESPACE} GetSelectedPermissionRolesError`,
  props<{ err: HttpErrorResponse }>(),
);

export const updatePermission = createAction(`${NAMESPACE} UpdatePermission`, props<{ permission: UpdatePermissionRequest }>());
export const updatePermissionComplete = createAction(`${NAMESPACE} UpdatePermissionComplete`, props<{ permission: Permission }>());
export const updatePermissionError = createAction(`${NAMESPACE} UpdatePermissionError`, props<{ err: HttpErrorResponse }>());

export const patchSelectedPermissionRoles = createAction(
  `${NAMESPACE} PatchSelectedPermissionRoles`,
  props<{ roles: UpdateRoleForEntityRequest[]; filter: RoleType }>(),
);
export const patchSelectedPermissionRolesComplete = createAction(
  `${NAMESPACE} PatchSelectedPermissionRolesComplete`,
  props<{ roles: GroupedRoles[] }>(),
);
export const patchSelectedPermissionRolesError = createAction(
  `${NAMESPACE} PatchSelectedPermissionRolesError`,
  props<{ err: HttpErrorResponse }>(),
);

export const deleteSelectedPermission = createAction(`${NAMESPACE} DeleteSelectedPermission`);
export const deleteSelectedPermissionComplete = createAction(`${NAMESPACE} DeleteSelectedPermissionComplete`);
export const deleteSelectedPermissionError = createAction(
  `${NAMESPACE} DeleteSelectedPermissionError`,
  props<{ err: HttpErrorResponse }>(),
);

export const goToPermissionActions = createAction(`${NAMESPACE} GoToPermissionActions`, props<{ id: string }>());
export const goToSelectedPermissionActions = createAction(`${NAMESPACE} GoToSelectedPermissionActions`);
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());

export const getPermissionAttributes = createAction(`${NAMESPACE} GetPermissionAttributes`, props<{ permissionId: string }>());
export const getPermissionAttributesComplete = createAction(
  `${NAMESPACE} GetPermissionAttributesComplete`,
  props<{ attributes: PermissionAttribute[] }>(),
);
export const getPermissionAttributesError = createAction(`${NAMESPACE} GetPermissionAttributesError`, props<{ err: HttpErrorResponse }>());

export const addPermissionAttribute = createAction(
  `${NAMESPACE} AddPermissionAttribute`,
  props<{ attribute: CreatePermissionAttributeRequest }>(),
);
export const addPermissionAttributeComplete = createAction(
  `${NAMESPACE} AddPermissionAttributeComplete`,
  props<{ attribute: PermissionAttribute }>(),
);
export const addPermissionAttributeError = createAction(`${NAMESPACE} AddPermissionAttributeError`, props<{ err: HttpErrorResponse }>());

export const deletePermissionAttribute = createAction(`${NAMESPACE} DeletePermissionAttribute`, props<{ attributeId: string }>());
export const deletePermissionAttributeComplete = createAction(
  `${NAMESPACE} DeletePermissionAttributeComplete`,
  props<{ attributeId: string }>(),
);
export const deletePermissionAttributeError = createAction(
  `${NAMESPACE} DeletePermissionAttributeError`,
  props<{ err: HttpErrorResponse }>(),
);

export const getPermissionForAttributePage = createAction(`${NAMESPACE} GetPermissionForAttributePage`, props<{ id: string }>());
export const getPermissionForAttributePageComplete = createAction(
  `${NAMESPACE} GetPermissionForAttributePageComplete`,
  props<{ permission: Permission | null }>(),
);
export const getPermissionForAttributePageError = createAction(
  `${NAMESPACE} GetPermissionForAttributePageError`,
  props<{ err: HttpErrorResponse }>(),
);
