import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ActionLog } from '@models/action-log/action-log.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ActionLogsService {
  constructor(private httpClient: HttpClient) {}

  getActionLogs(query: any): Observable<ActionLog[]> {
    let queryParams: Params = {};

    if (query) {
      queryParams = this.setParameter(query);
    }

    return this.httpClient.get<ActionLog[]>(`/${environment.bffUrl}/action-logs`, {
      params: queryParams,
    });
  }

  getActionLog(id: string): Observable<ActionLog> {
    return this.httpClient.get<ActionLog>(`/${environment.bffUrl}/action-logs/${id}`);
  }

  private setParameter(routerParams: Params): HttpParams {
    let queryParams = new HttpParams();
    for (const [key, value] of Object.entries(routerParams)) {
      if (!!value) {
        queryParams = queryParams.set(key, routerParams[key]);
      }
    }
    return queryParams;
  }
}
