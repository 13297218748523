import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './news-regions.actions';
import { NewsRegionsService } from './news-regions.service';
@Injectable()
export class NewsRegionsEffects {
  onGetNewsRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllNewsRegions),
      switchMap(() =>
        this.newsRegionsService.getAll().pipe(
          map(newsRegions => fromActions.getAllNewsRegionsComplete({ newsRegions })),
          catchError(err => of(fromActions.getAllNewsRegionsError({ err }))),
        ),
      ),
    ),
  );

  onSelectNewsRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getOneNewsRegion),
      switchMap(({ id }) =>
        this.newsRegionsService.getOne(id).pipe(
          map(newsRegion => fromActions.getOneNewsRegionComplete({ newsRegion })),
          catchError(err => of(fromActions.getOneNewsRegionError({ err }))),
        ),
      ),
    ),
  );

  onToggleNewsRegionAutomaticApproval = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.toggleNewsRegionAutomaticApproval),
      switchMap(({ id, req }) =>
        this.newsRegionsService.toggleAutomaticApproval(id, req).pipe(
          map(newsRegion => fromActions.toggleNewsRegionAutomaticApprovalComplete({ newsRegion })),
          catchError(err => of(fromActions.toggleNewsRegionAutomaticApprovalError({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly newsRegionsService: NewsRegionsService,
  ) {}
}
