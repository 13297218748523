import { UserInfo, UserModel } from '@models/authentication/auth';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './identity.actions';

export interface State {
  isAuthenticated: boolean;
  isSystemAdmin: boolean;
  isLoading: boolean;
  user: UserModel;
}

const initialState: State = {
  isAuthenticated: false,
  isSystemAdmin: true,
  isLoading: false,
  user: {
    userinfo: {} as UserInfo,
    permissions: [],
    expires_at: 0,
    featureFlags: { 'admin-partners-enabled': false },
  } as UserModel,
};

export const identityReducer = createReducer(
  initialState,
  on(fromActions.checkAuth, state => ({
    ...state,
    isAuthenticated: false,
    isLoading: true,
  })),
  on(fromActions.checkAuthCompleted, (state, { userinfo, permissions, expires_at }) => ({
    ...state,
    user: { userinfo, permissions, expires_at },
    isAuthenticated: true,
    isLoading: false,
    isSystemAdmin: true,
  })),
  on(fromActions.refreshCompleted, (state, { expires_at }) => ({
    ...state,
    user: { ...state.user, expires_at },
    isAuthenticated: true,
    isLoading: false,
    isSystemAdmin: true,
  })),
  on(fromActions.userUnauthorized, state => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
    isSystemAdmin: false,
  })),
  on(fromActions.userNotSystemAdmin, state => ({
    ...state,
    isAuthenticated: true,
    isLoading: false,
    isSystemAdmin: false,
  })),
);
