import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { actionLogsActions } from '@appState';
import { ActionLogsListQuery } from '@models/action-log/action-log.model';
import { Store } from '@ngrx/store';
import { formatToISODate } from 'cui-components';

@Component({
  templateUrl: './action-logs-filters.component.html',
  styleUrls: ['./action-logs-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionLogsFiltersComponent implements OnInit {
  form!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActionLogsListQuery,
    private store: Store,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ActionLogsFiltersComponent>,
  ) {}

  ngOnInit() {
    const startDate = new Date(new Date().setDate(new Date().getDate() - 3));
    const endDate = new Date();

    this.form = this.fb.group({
      partnerId: [this.data?.partnerId, [Validators.required, Validators.maxLength(200)]],
      startDate: [startDate, [Validators.required]],
      endDate: [endDate, [Validators.required]],
      customerId: [this.data?.customerId, [Validators.maxLength(200)]],
    });
  }

  onConfirm(): void {
    if (this.form.valid) {
      const actionLogsQuery: ActionLogsListQuery = {
        ...this.form.value,
        startDate: formatToISODate(this.form.value.startDate),
        endDate: formatToISODate(new Date(this.form.value.endDate.setDate(this.form.value.endDate.getDate() + 1))),
      };
      this.store.dispatch(actionLogsActions.submitFilterModal({ actionLogsQuery }));
      this.dialogRef.close(actionLogsQuery);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
