import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.notificationTemplates);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectTemplates = createSelector(selectModuleState, state => state.templates);

export const selectSelectedTemplate = createSelector(selectModuleState, state => state.selectedTemplate);
export const selectSelectedTemplateId = createSelector(selectModuleState, state => state.selectedTemplate && state.selectedTemplate.id);
export const selectSelectedTemplateLoading = createSelector(selectModuleState, state => state.selectedTemplateLoading);
export const selectIsTemplateCreateLoading = createSelector(selectModuleState, state => state.templateCreateLoading);

export const selectAdditionalReceivers = createSelector(selectModuleState, state => state.additionalReceivers);
export const selectAdditionalReceiversLoading = createSelector(selectModuleState, state => state.additionalReceiversLoading);
export const selectSelectedAdditionalReceiver = createSelector(selectModuleState, state => state.selectedAdditionalReceiver);
export const selectSelectedAdditionalReceiverId = createSelector(
  selectModuleState,
  state => state.selectedAdditionalReceiver && state.selectedAdditionalReceiver.id,
);
export const selectSelectedAdditionalReceiverLoading = createSelector(selectModuleState, state => state.selectedAdditionalReceiverLoading);
export const selectIsAdditionalReceiverCreateLoading = createSelector(selectModuleState, state => state.additionalReceiverCreateLoading);

export const selectMappings = createSelector(selectModuleState, state => state.mappings);
export const selectMappingsLoading = createSelector(selectModuleState, state => state.mappingsLoading);
export const selectSelectedMapping = createSelector(selectModuleState, state => state.selectedMapping);
export const selectSelectedMappingId = createSelector(selectModuleState, state => state.selectedMapping && state.selectedMapping.id);
export const selectSelectedMappingLoading = createSelector(selectModuleState, state => state.selectedMappingLoading);
export const selectIsMappingCreateLoading = createSelector(selectModuleState, state => state.mappingCreateLoading);

export const selectMappingContents = createSelector(selectModuleState, state => state.mappingContents);
export const selectMappingContentsLoading = createSelector(selectModuleState, state => state.mappingContentsLoading);
export const selectSelectedMappingContent = createSelector(selectModuleState, state => state.selectedMappingContent);
export const selectSelectedMappingContentName = createSelector(
  selectModuleState,
  state => state.selectedMappingContent && state.selectedMappingContent.name,
);
export const selectSelectedMappingContentLoading = createSelector(selectModuleState, state => state.selectedMappingContentLoading);
