import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, tap } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as fromActions from './identity.actions';
import { IdentityService } from './identity.service';

@Injectable()
export class IdentityEffects {
  private actions$ = inject(Actions);
  onCheckAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkAuth),
      switchMap(() => {
        return this.identityService.checkAuth().pipe(
          map(({ userinfo, permissions, expires_at }) => fromActions.checkAuthCompleted({ userinfo, permissions, expires_at })),
          catchError(err => of(err.status === 403 ? fromActions.userNotSystemAdmin() : fromActions.userUnauthorized(err))),
        );
      }),
    ),
  );

  onRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refresh),
      switchMap(() =>
        this.identityService.refresh().pipe(
          map(({ expires_at }) => fromActions.refreshCompleted({ expires_at })),
          catchError(err => of(fromActions.refreshError({ err }))),
        ),
      ),
    ),
  );

  onRefreshError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.refreshError),
        tap(() => (document.location.href = `${environment.bffUrl}/auth/logout`)),
      ),
    { dispatch: false },
  );

  onUserUnauthorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.userUnauthorized),
        tap(() => (document.location.href = `${environment.bffUrl}/auth/login`)),
      ),
    { dispatch: false },
  );

  constructor(private readonly identityService: IdentityService) {}
}
