import { UserResponse } from '@models/user';
import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';
import * as fromReducer from './users.reducer';

const selectModuleState = createSelector(getRootState, rootState => rootState.user);
export const { selectAll, selectEntities } = fromReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoadingUser = createSelector(selectModuleState, state => state.isLoadingUser);
export const selectIsLoadingUserList = createSelector(selectModuleState, state => state.isLoadingUserList);
export const selectIsChangingAdditionalPartnerForUser = createSelector(
  selectModuleState,
  state => state.changingAdditionalPartnerListInProgress,
);
export const selectUserList = createSelector(selectAll, users => users ?? []);
export const selectCurrentUserId = createSelector(selectModuleState, state => state.selectedUserId);
export const selectCurrentUser = createSelector(
  selectEntities,
  selectCurrentUserId,
  (entities, id) => (id && entities?.[id]) as UserResponse | null | undefined,
);

export const selectCurrentUserRoles = createSelector(selectModuleState, state => state.selectedUserRoles);
export const selectCurrentUserRolesLoading = createSelector(selectModuleState, state => state.isUserRolesLoading);
