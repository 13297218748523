import { HttpErrorResponse } from '@angular/common/http';
import { CommonDataType, GUID } from '@models/common';
import { CreateDocumentRequest, Document, DocumentCommonDataObject, DocumentListItem, UpdateDocumentRequest } from '@models/documents';
import { createAction, props } from '@ngrx/store';
import { State } from './documents.reducer';

const NAMESPACE = '[Documents]';

export const getAllDocuments = createAction(`${NAMESPACE} GetAllDocuments`);
export const getAllDocumentsComplete = createAction(`${NAMESPACE} GetAllDocumentsComplete`, props<{ documents: DocumentListItem[] }>());
export const getAllDocumentsError = createAction(`${NAMESPACE} GetAllDocumentsError`, props<{ err: HttpErrorResponse }>());

export const createDocument = createAction(`${NAMESPACE} CreateDocument`, props<{ docDto: CreateDocumentRequest; file: File }>());
export const createDocumentComplete = createAction(`${NAMESPACE} CreateDocumentComplete`);
export const createDocumentError = createAction(`${NAMESPACE} CreateDocumentError`, props<{ err: HttpErrorResponse }>());

// Remove fromDocumentCreate if it will be not needed. Remove after 2023-12-20
export const uploadFileToDocument = createAction(
  `${NAMESPACE} UploadFileToDocument`,
  props<{ url: string; file: File; fromDocumentCreate: boolean; document?: Document; docId: GUID }>(),
);
export const uploadFileToDocumentComplete = createAction(
  `${NAMESPACE} UploadFileToDocumentComplete`,
  props<{ fromDocumentCreate: boolean; document?: Document; docId: string }>(),
);
export const uploadFileToDocumentError = createAction(
  `${NAMESPACE} UploadFileToDocumentError`,
  props<{ err: HttpErrorResponse; docId?: GUID }>(),
);

export const getFileUploadURL = createAction(`${NAMESPACE} GetFileUploadURL`, props<{ id: GUID; file: File; fileName: string }>());
export const getFileUploadURLError = createAction(`${NAMESPACE} GetFileUploadURLError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedDocument = createAction(`${NAMESPACE} DeleteSelectedDocument`);
export const deleteSelectedDocumentComplete = createAction(`${NAMESPACE} DeleteSelectedDocumentComplete`);
export const deleteSelectedDocumentError = createAction(`${NAMESPACE} DeleteSelectedDocumentError`, props<{ err: HttpErrorResponse }>());

export const downloadSelectedDocument = createAction(`${NAMESPACE} DownloadSelectedDocument`);
export const downloadSelectedDocumentComplete = createAction(`${NAMESPACE} DownloadSelectedDocumentComplete`, props<{ url: string }>());
export const downloadSelectedDocumentError = createAction(`${NAMESPACE} DownloadSelectedDocumentError`);

export const copyActiveDocExternalLink = createAction(`${NAMESPACE} CopyActiveDocExternalLink`);

export const selectDocument = createAction(`${NAMESPACE} SelectDocument`, props<{ id: GUID }>());
export const selectDocumentComplete = createAction(`${NAMESPACE} SelectDocumentComplete`, props<{ doc: Document }>());
export const selectDocumentError = createAction(`${NAMESPACE} SelectDocumentError`, props<{ err: HttpErrorResponse }>());

export const updateDocument = createAction(`${NAMESPACE} UpdateDocument`, props<{ doc: UpdateDocumentRequest }>());
export const updateDocumentComplete = createAction(`${NAMESPACE} UpdateDocumentComplete`, props<{ doc: Document }>());
export const updateDocumentError = createAction(`${NAMESPACE} UpdateDocumentError`, props<{ err: HttpErrorResponse }>());

export const publishOrUnpublishDocument = createAction(
  `${NAMESPACE} PublishOrUnpublishDocument`,
  props<{ id: GUID; publishDate: string | undefined; wantToPublish: boolean }>(),
);
export const publishOrUnpublishDocumentComplete = createAction(
  `${NAMESPACE} PublishOrUnpublishDocumentComplete`,
  props<{ doc: Document }>(),
);
export const publishOrUnpublishDocumentError = createAction(
  `${NAMESPACE} PublishOrUnpublishDocumentError`,
  props<{ err: HttpErrorResponse }>(),
);

// Categories, Regions, MembershipLevels
export const updateDocumentCommonData = createAction(
  `${NAMESPACE} UpdateDocumentCommonData`,
  props<{ docId: GUID; commonDataType: CommonDataType; data: DocumentCommonDataObject[] }>(),
);
export const updateDocumentCommonDataComplete = createAction(`${NAMESPACE} UpdateDocumentCommonDataComplete`, props<{ doc: Document }>());
export const updateDocumentCommonDataError = createAction(
  `${NAMESPACE} UpdateDocumentCommonDataError`,
  props<{ err: HttpErrorResponse }>(),
);

export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);
export const goToDocumentActions = createAction(`${NAMESPACE} GoToDocumentActions`, props<{ docId: GUID }>());
export const goToSelectedDocumentActions = createAction(`${NAMESPACE} GoToSelectedDocumentActions`);
export const goToDocumentInformation = createAction(`${NAMESPACE} GoToDocumentInformation`, props<{ docId: GUID }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
