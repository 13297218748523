import { Product } from '@models/products-and-orders/products';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './products.actions';

export interface State {
  isLoading: boolean;
  products: Product[];

  selectedProduct: Product | null;
  selectedProductLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  products: [],

  selectedProduct: null,
  selectedProductLoading: false,
};

export const productsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { products }) => ({
    ...state,
    products: sortAlphabetically(products, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.updateProduct, state => ({ ...state, selectedProductLoading: true, isLoading: true })),
  on(fromActions.updateProductComplete, (state, { product }) => ({
    ...state,
    isLoading: false,
    selectedProductLoading: false,
    selectedProduct: product,
    products: sortAlphabetically(
      findAndReplace<Product>(state.products, product, item => item.id === product.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.updateProductError, state => ({
    ...state,
    selectedProduct: { ...state.selectedProduct! },
    isLoading: false,
    selectedProductLoading: false,
  })),

  on(fromActions.activateProduct, state => ({ ...state, selectedProductLoading: true })),
  on(fromActions.activateProductComplete, (state, { product }) => ({
    ...state,
    selectedProductLoading: false,
    selectedProduct: product,
    products: sortAlphabetically(
      findAndReplace<Product>(state.products, product, item => item.id === product.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.activateProductError, state => ({ ...state, selectedProductLoading: false })),

  on(fromActions.deactivateProduct, state => ({ ...state, selectedProductLoading: true })),
  on(fromActions.deactivateProductComplete, (state, { product }) => ({
    ...state,
    selectedProductLoading: false,
    selectedProduct: product,
    products: sortAlphabetically(
      findAndReplace<Product>(state.products, product, item => item.id === product.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.deactivateProductError, state => ({ ...state, selectedProductLoading: false })),

  on(fromActions.selectProduct, state => ({ ...state, selectedProductLoading: true })),
  on(fromActions.selectProductComplete, (state, { product }) => ({ ...state, selectedProductLoading: false, selectedProduct: product })),
  on(fromActions.selectProductError, state => ({ ...state, selectedProductLoading: false })),
);
