import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './functional-roles.actions';
import { FunctionalRolesService } from './functional-roles.service';

@Injectable()
export class FunctionalRolesEffects {
  onGetAllFuncRoleAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(({ subtype, attributes }) =>
        this.funcRolesService.get(subtype, attributes).pipe(
          map(functionalRolesAssignments => fromActions.getAllComplete({ functionalRolesAssignments })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onGetAllFuncRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllFuncRoles),
      switchMap(({ subtype }) =>
        this.funcRolesService.getRoles(subtype).pipe(
          map(functionalRoles => fromActions.getAllFuncRolesComplete({ functionalRoles })),
          catchError(err => of(fromActions.getAllFuncRolesError({ err }))),
        ),
      ),
    ),
  );

  onAddNewFuncRoleAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addFuncRoleAssignment),
      switchMap(({ addFuncRoleAssignment, subtype }) =>
        this.funcRolesService.add(addFuncRoleAssignment, subtype).pipe(
          map(functionalRole => fromActions.addFuncRoleAssignmentComplete({ functionalRole })),
          catchError(err => of(fromActions.addFuncRoleAssignmentError({ err }))),
        ),
      ),
    ),
  );

  onDeleteFuncRoleAsignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteFuncRoleAssignment),
      switchMap(({ id }) =>
        this.funcRolesService.delete(id).pipe(
          map(() => fromActions.deleteFuncRoleAssignmentComplete({ id })),
          catchError(err => of(fromActions.deleteFuncRoleAssignmentError({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly funcRolesService: FunctionalRolesService,
  ) {}
}
