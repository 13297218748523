import { NewsArticle, NewsArticleComment, NewsArticleListItem, NewsArticlePromotion, NewsArticleRegion } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndRemove, findAndReplace, sortAlphabetically } from '../helpers/helper';
import * as fromActions from './news-articles.actions';
export interface State {
  newsArticles: NewsArticleListItem[];
  selectedNewsArticle: NewsArticle | null;
  isLoading: boolean;
  isSelectedNewsArticleLoading: boolean;
  isNewsArticleCreateInProgress: boolean;

  comments: NewsArticleComment[];
  commentsLoading: boolean;

  selectedNewsArticleRegionId: string | null;
}

export const initialState: State = {
  newsArticles: [],
  selectedNewsArticle: null,
  isLoading: false,
  isSelectedNewsArticleLoading: false,
  isNewsArticleCreateInProgress: false,

  comments: [],
  commentsLoading: false,

  selectedNewsArticleRegionId: null,
};

export const newsArticlesReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllNewsArticles, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllNewsArticlesComplete, (state, { newsArticles }) => ({
    ...state,
    isLoading: false,
    newsArticles: sortAlphabetically(newsArticles, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticlesError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.selectNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: newsArticle,
  })),
  on(fromActions.selectNewsArticleError, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
  })),

  on(fromActions.addNewsArticle, state => ({
    ...state,
    isNewsArticleCreateInProgress: true,
    isSelectedNewsArticleLoading: true,
    isLoading: true,
  })),
  on(fromActions.addNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { ...newsArticle, content: null },
    newsArticles: sortAlphabetically([...state.newsArticles, newsArticle], sortByDateTime),
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
  })),
  on(fromActions.addNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isNewsArticleCreateInProgress: false,
    isLoading: false,
  })),

  on(fromActions.createNewsArticleThumbnailFile, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticleError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteSelectedNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.deleteSelectedNewsArticleComplete, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: null,
    selectedVersion: null,
  })),
  on(fromActions.deleteSelectedNewsArticleError, state => ({
    ...state,
    isSelectedNewsArticleLoading: false,
  })),

  on(fromActions.activateNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.activateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === state.selectedNewsArticle!.id),
      sortByDateTime,
    ),
  })),
  on(fromActions.activateNewsArticleError, state => ({ ...state, isSelectedNewsArticleLoading: false })),

  on(fromActions.deactivateNewsArticle, state => ({ ...state, isSelectedNewsArticleLoading: true })),
  on(fromActions.deactivateNewsArticleComplete, (state, { newsArticle }) => ({
    ...state,
    isSelectedNewsArticleLoading: false,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === state.selectedNewsArticle!.id),
      sortByDateTime,
    ),
  })),
  on(fromActions.deactivateNewsArticleError, state => ({ ...state, isSelectedNewsArticleLoading: false })),

  on(fromActions.setNewsArticleTopics, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleTopicsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleTopicsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleWorkloads, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleWorkloadsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleWorkloadsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleMembershipLevels, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleMembershipLevelsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleMembershipLevelsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.setNewsArticleContent, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.setNewsArticleContentComplete, (state, { newContent }) => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle!, content: newContent.content },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(
        state.newsArticles,
        state.selectedNewsArticle!,
        item => item.id === state.selectedNewsArticle!.id,
      ),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.setNewsArticleContentError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.getAllNewsArticleComments, state => ({ ...state, commentsLoading: true })),
  on(fromActions.getAllNewsArticleCommentsComplete, (state, { comments }) => ({
    ...state,
    commentsLoading: false,
    comments: sortAlphabetically(comments, sortByDateTime),
  })),
  on(fromActions.getAllNewsArticleCommentsError, state => ({ ...state, commentsLoading: false })),

  on(fromActions.deleteNewsArticleComment, state => ({ ...state, commentsLoading: true })),
  on(fromActions.deleteNewsArticleCommentComplete, (state, { commentId }) => ({
    ...state,
    comments: findAndRemoveComment(state.comments, comment => comment.id === commentId),
    commentsLoading: false,
  })),
  on(fromActions.deleteNewsArticleCommentError, state => ({
    ...state,
    commentsLoading: false,
  })),

  on(fromActions.selectNewsArticleRegion, (state, { regionId }) => ({ ...state, selectedNewsArticleRegionId: regionId })),

  on(fromActions.addNewsArticleRegions, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.addNewsArticleRegionsComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,

    selectedNewsArticleRegionId: null,
  })),
  on(fromActions.addNewsArticleRegionsError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.updateNewsArticleRegion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticleRegionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticleRegionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteNewsArticleRegion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.deleteNewsArticleRegionComplete, (state, { articleId, regionId }) => {
    const newsArticle = {
      ...state.selectedNewsArticle!,
      regions: findAndRemove<NewsArticleRegion>(state.selectedNewsArticle!.regions, item => item.id === regionId),
    };
    return {
      ...state,
      selectedNewsArticle: newsArticle,
      newsArticles: sortAlphabetically(
        findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === articleId),
        sortByDateTime,
      ),
      isSelectedNewsArticleLoading: false,
      isLoading: false,
    };
  }),
  on(fromActions.deleteNewsArticleRegionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.approveNewsArticleRegion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.approveNewsArticleRegionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.approveNewsArticleRegionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.addNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.addNewsArticlePromotionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.addNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.updateNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.updateNewsArticlePromotionComplete, (state, { newsArticle }) => ({
    ...state,
    selectedNewsArticle: { content: state.selectedNewsArticle!.content, ...newsArticle },
    newsArticles: sortAlphabetically(
      findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === newsArticle.id),
      sortByDateTime,
    ),
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
  on(fromActions.updateNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),

  on(fromActions.deleteNewsArticlePromotion, state => ({ ...state, isSelectedNewsArticleLoading: true, isLoading: true })),
  on(fromActions.deleteNewsArticlePromotionComplete, (state, { articleId, regionId }) => {
    const newsArticle = {
      ...state.selectedNewsArticle!,
      promotions: findAndRemove<NewsArticlePromotion>(state.selectedNewsArticle!.promotions, item => item.regionId === regionId),
    };
    return {
      ...state,
      selectedNewsArticle: newsArticle,
      newsArticles: sortAlphabetically(
        findAndReplace<NewsArticleListItem>(state.newsArticles, newsArticle, item => item.id === articleId),
        sortByDateTime,
      ),
      isSelectedNewsArticleLoading: false,
      isLoading: false,
    };
  }),
  on(fromActions.deleteNewsArticlePromotionError, state => ({
    ...state,
    selectedNewsArticle: { ...state.selectedNewsArticle! },
    isSelectedNewsArticleLoading: false,
    isLoading: false,
  })),
);

export const sortByDateTime = (a: NewsArticleListItem | NewsArticleComment, b: NewsArticleListItem | NewsArticleComment) =>
  a.createdDateTime < b.createdDateTime ? 1 : -1;

export const findAndRemoveComment = (
  comments: NewsArticleComment[],
  predicate: (comment: NewsArticleComment) => boolean,
): NewsArticleComment[] => {
  return comments.reduce((acc, comment) => {
    if (!predicate(comment)) {
      const newComment = { ...comment };

      if (newComment.comments) {
        newComment.comments = findAndRemoveComment(newComment.comments, predicate);
      }

      acc.push(newComment);
    }
    return acc;
  }, [] as NewsArticleComment[]);
};
