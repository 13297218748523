export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function convertBooleanToYesNo(value: boolean): string {
  return value ? 'Yes' : 'No';
}

/** Use in getQuickFilterText
 * @returns an empty string to exclude column from search
 */
export function excludeColumnFromSearch(): string {
  return '';
}

export const formatSizeFromBytes = (size: number, decimalPlaces: number): string =>
  size > 1024 * 1024 ? +(size / (1024 * 1024)).toFixed(decimalPlaces) + ' MB' : +(size / 1024).toFixed(decimalPlaces) + ' KB';
