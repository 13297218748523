import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddPermissionRequest,
  CreatePermissionAttributeRequest,
  GroupedRoles,
  Permission,
  PermissionAttribute,
  RoleType,
  UpdatePermissionRequest,
  UpdateRoleForEntityRequest,
} from '@models/authorization';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class PermissionService {
  constructor(private httpClient: HttpClient) {}

  private readonly url = `${environment.bffUrl}/authorization/permissions`;

  getPermissions(): Observable<Permission[]> {
    return this.httpClient.get<Permission[]>(this.url);
  }

  getPermissionRoles(id: string, type: RoleType = 'Regular'): Observable<GroupedRoles[]> {
    return this.httpClient.get<GroupedRoles[]>(`${this.url}/${id}/roles`, { params: { type } });
  }

  addPermission(permission: AddPermissionRequest): Observable<Permission> {
    return this.httpClient.post<Permission>(this.url, permission);
  }

  updatePermission(permission: UpdatePermissionRequest): Observable<Permission> {
    return this.httpClient.put<Permission>(`${this.url}/${permission.id}`, permission);
  }

  patchPermissionRoles(id: string, roles: UpdateRoleForEntityRequest[], type: RoleType = 'Regular'): Observable<GroupedRoles[]> {
    return this.httpClient.patch<GroupedRoles[]>(`${this.url}/${id}/roles`, roles, { params: { type } });
  }

  deletePermission(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  getPermissionAttributes(permissionId: string): Observable<PermissionAttribute[]> {
    return this.httpClient.get<PermissionAttribute[]>(`${this.url}-attributes`, {
      params: { permissionId: permissionId },
    });
  }
  getPermissionAttributeById(attributeId: string): Observable<PermissionAttribute> {
    return this.httpClient.get<PermissionAttribute>(`${this.url}-attributes/${attributeId}`);
  }

  addPermissionAttribute(attribute: CreatePermissionAttributeRequest): Observable<PermissionAttribute> {
    return this.httpClient.post<PermissionAttribute>(`${this.url}-attributes`, attribute);
  }

  deletePermissionAttribute(attributeId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}-attributes/${attributeId}`);
  }
}
