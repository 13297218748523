import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewsRegion, NewsRegionApproveAutomaticallyRequest, NewsRegionResponse } from '@models/index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class NewsRegionsService {
  private readonly url = `${environment.bffUrl}/news-regions`;
  constructor(private http: HttpClient) {}

  getAll(): Observable<NewsRegion[]> {
    return this.http.get<NewsRegionResponse[]>(this.url);
  }
  getOne(id: string): Observable<NewsRegion> {
    return this.http.get<NewsRegionResponse>(`${this.url}/${id}`);
  }
  toggleAutomaticApproval(id: string, req: NewsRegionApproveAutomaticallyRequest): Observable<NewsRegion> {
    return this.http.post<NewsRegionResponse>(`${this.url}/${id}/approve-news-automatically`, req);
  }
}
