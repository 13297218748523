import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { templatesSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './templates.actions';
import { TemplatesService } from './templates.service';

@Injectable()
export class TemplatesEffects {
  onGetTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.templatesService.getAll().pipe(
          map(templates => fromActions.getAllComplete({ templates })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onSelectTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectTemplate),
      switchMap(({ id }) =>
        this.store.pipe(
          select(templatesSelectors.selectTemplates),
          take(1),
          switchMap(templates => {
            if (templates.length) {
              const template = templates.find(t => t.id === id)!;
              return of(fromActions.selectTemplateComplete({ template }));
            } else {
              return this.templatesService.getOne(id).pipe(
                map(template => fromActions.selectTemplateComplete({ template })),
                catchError(err => of(fromActions.selectTemplateError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onAddNewTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTemplate),
      switchMap(({ template }) =>
        this.templatesService.create(template).pipe(
          map(template => fromActions.addTemplateComplete({ template })),
          catchError(err => of(fromActions.addTemplateError({ err }))),
        ),
      ),
    ),
  );

  onAddNewTemplateComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addTemplateComplete),
        tap(({ template }) => this.router.navigate(['notifications', 'templates', template.id, 'details'])),
      ),
    { dispatch: false },
  );

  onUpdateTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTemplate),
      switchMap(({ id, template }) =>
        this.templatesService.update(id, template).pipe(
          map(template => fromActions.updateTemplateComplete({ template })),
          catchError(err => of(fromActions.updateTemplateError({ err }))),
        ),
      ),
    ),
  );

  onDeleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTemplate),
      switchMap(() =>
        this.store.pipe(
          select(templatesSelectors.selectSelectedTemplateId),
          take(1),
          switchMap(id =>
            this.templatesService.delete(id!).pipe(
              map(() => fromActions.deleteSelectedTemplateComplete()),
              catchError(err => of(fromActions.deleteSelectedTemplateError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteTemplateComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTemplateComplete),
      tap(() => this.router.navigate(['notifications', 'templates'])),
      map(() => fromActions.getAll()),
    ),
  );

  onUpdateTemplateComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTemplateComplete),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToTemplateActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTemplateActions),
        tap(({ templateId }) => this.router.navigate(['notifications', 'templates', templateId])),
      ),
    { dispatch: false },
  );

  onGoToTemplateListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToTemplateListPage),
      tap(() => this.router.navigate(['notifications', 'templates'])),
      map(() => fromActions.resetState({ selectedTemplate: null })),
    ),
  );

  onGetAdditionalReceivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAdditionalReceivers),
      switchMap(({ templateId }) =>
        this.templatesService.getAllAdditionalReceivers(templateId).pipe(
          map(additionalReceivers => fromActions.getAdditionalReceiversComplete({ additionalReceivers })),
          catchError(err => of(fromActions.getAdditionalReceiversError({ err }))),
        ),
      ),
    ),
  );

  onAddAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAdditionalReceiver),
      switchMap(({ templateId, additionalReceiver }) =>
        this.templatesService.createAdditionalReceiver(templateId, additionalReceiver).pipe(
          map(additionalReceiver => fromActions.addAdditionalReceiverComplete({ additionalReceiver })),
          catchError(err => of(fromActions.addAdditionalReceiverError({ err }))),
        ),
      ),
    ),
  );

  onAddAdditionalReceiverComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addAdditionalReceiverComplete),
        switchMap(({ additionalReceiver }) => {
          return this.router.navigate([
            'notifications',
            'templates',
            additionalReceiver.templateId,
            'receivers',
            additionalReceiver.id,
            'details',
          ]);
        }),
      ),
    { dispatch: false },
  );

  onDeleteAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAdditionalReceiver),
      switchMap(({ templateId, additionalReceiverId }) =>
        this.templatesService.deleteAdditionalReceiver(templateId, additionalReceiverId).pipe(
          map(() => fromActions.deleteSelectedAdditionalReceiverComplete({ templateId })),
          catchError(err => of(fromActions.deleteSelectedAdditionalReceiverError({ err }))),
        ),
      ),
    ),
  );

  onDeleteAdditionalReceiverComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAdditionalReceiverComplete),
      tap(({ templateId }) => this.router.navigate(['notifications', 'templates', templateId, 'receivers'])),
      map(({ templateId }) => fromActions.getAdditionalReceivers({ templateId })),
    ),
  );

  onSelectAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAdditionalReceiver),
      switchMap(({ templateId, additionalReceiverId }) =>
        this.store.pipe(
          select(templatesSelectors.selectAdditionalReceivers),
          take(1),
          switchMap(additionalReceivers => {
            if (additionalReceivers.length) {
              const additionalReceiver = additionalReceivers.find(ar => ar.id === additionalReceiverId && ar.templateId === templateId)!;
              return of(fromActions.selectAdditionalReceiverComplete({ additionalReceiver }));
            } else {
              return this.templatesService.getOneAdditionalReceiver(templateId, additionalReceiverId).pipe(
                map(additionalReceiver => fromActions.selectAdditionalReceiverComplete({ additionalReceiver })),
                catchError(err => of(fromActions.selectAdditionalReceiverError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onUpdateAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAdditionalReceiver),
      switchMap(({ templateId, additionalReceiverId, additionalReceiver }) =>
        this.templatesService.updateAdditionalReceiver(templateId, additionalReceiverId, additionalReceiver).pipe(
          map(additionalReceiver => fromActions.updateAdditionalReceiverComplete({ additionalReceiver })),
          catchError(err => of(fromActions.updateAdditionalReceiverError({ err }))),
        ),
      ),
    ),
  );

  onUpdateAdditionalReceiverComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAdditionalReceiverComplete),
      map(({ additionalReceiver }) => fromActions.getAdditionalReceivers({ templateId: additionalReceiver.templateId })),
    ),
  );

  onGoToAdditionalReceiverActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToAdditionalReceiverActions),
        tap(({ templateId, additionalReceiverId }) =>
          this.router.navigate(['notifications', 'templates', templateId, 'receivers', additionalReceiverId]),
        ),
      ),
    { dispatch: false },
  );

  onGoToAdditionalReceiverListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToAdditionalReceiverListPage),
      tap(({ templateId }) => this.router.navigate(['notifications', 'templates', templateId, 'receivers'])),
      map(() => fromActions.resetState({ selectedAdditionalReceiver: null })),
    ),
  );

  onGetMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMappings),
      switchMap(({ templateId }) =>
        this.templatesService.getAllMappings(templateId).pipe(
          map(mappings => fromActions.getMappingsComplete({ mappings })),
          catchError(err => of(fromActions.getMappingsError({ err }))),
        ),
      ),
    ),
  );

  onAddMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addMapping),
      switchMap(({ templateId, mapping }) =>
        this.templatesService.createMapping(templateId, mapping).pipe(
          map(mapping => fromActions.addMappingComplete({ mapping })),
          catchError(err => of(fromActions.addMappingError({ err }))),
        ),
      ),
    ),
  );

  onAddMappingComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addMappingComplete),
        switchMap(({ mapping }) => {
          return this.router.navigate(['notifications', 'templates', mapping.templateId, 'mappings', mapping.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onDeleteMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedMapping),
      switchMap(({ templateId, mappingId }) =>
        this.templatesService.deleteMapping(templateId, mappingId).pipe(
          map(() => fromActions.deleteSelectedMappingComplete({ templateId })),
          catchError(err => of(fromActions.deleteSelectedMappingError({ err }))),
        ),
      ),
    ),
  );

  onDeleteMappingComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedMappingComplete),
      tap(({ templateId }) => this.router.navigate(['notifications', 'templates', templateId, 'mappings'])),
      map(({ templateId }) => fromActions.getMappings({ templateId })),
    ),
  );

  onActivateOrDeactivateMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activationOfSelectedMapping),
      switchMap(({ templateId, mappingId, activate }) =>
        this.templatesService.mappingActivation(templateId, mappingId, activate).pipe(
          map(mapping => fromActions.activationOfSelectedMappingComplete({ mapping })),
          catchError(err => of(fromActions.activationOfSelectedMappingError({ err }))),
        ),
      ),
    ),
  );

  onSelectMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectMapping),
      switchMap(({ templateId, mappingId }) =>
        this.store.pipe(
          select(templatesSelectors.selectMappings),
          take(1),
          switchMap(mappings => {
            if (mappings.length) {
              const mapping = mappings.find(ar => ar.id === mappingId && ar.templateId === templateId)!;
              return of(fromActions.selectMappingComplete({ mapping }));
            } else {
              return this.templatesService.getOneMapping(templateId, mappingId).pipe(
                map(mapping => fromActions.selectMappingComplete({ mapping })),
                catchError(err => of(fromActions.selectMappingError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onUpdateMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateMapping),
      switchMap(({ templateId, mappingId, mapping }) =>
        this.templatesService.updateMapping(templateId, mappingId, mapping).pipe(
          map(mapping => fromActions.updateMappingComplete({ mapping })),
          catchError(err => of(fromActions.updateMappingError({ err }))),
        ),
      ),
    ),
  );

  onUpdateMappingComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateMappingComplete),
      map(({ mapping }) => fromActions.getMappings({ templateId: mapping.templateId })),
    ),
  );

  onGetContents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMappingContents),
      switchMap(({ templateId, mappingId }) =>
        this.templatesService.getAllMappingContents(templateId, mappingId).pipe(
          map(contents => fromActions.getMappingContentsComplete({ contents })),
          catchError(err => of(fromActions.getMappingsError({ err }))),
        ),
      ),
    ),
  );

  onSelectMappingContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectMappingContent),
      switchMap(({ templateId, mappingId, tagName }) =>
        this.store.pipe(
          select(templatesSelectors.selectMappingContents),
          take(1),
          switchMap(contents => {
            if (contents.length) {
              const content = contents.find(c => c.name === tagName)!;
              return of(fromActions.selectMappingContentComplete({ content }));
            } else {
              return this.templatesService.getOneMappingContent(templateId, mappingId, tagName).pipe(
                map(content => fromActions.selectMappingContentComplete({ content })),
                catchError(err => of(fromActions.selectMappingContentError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onUpdateMappingContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateMappingContent),
      switchMap(({ templateId, mappingId, tagName, content }) =>
        this.templatesService.updateMappingContent(templateId, mappingId, tagName, content).pipe(
          map(content => fromActions.updateMappingContentComplete({ content, templateId, mappingId })),
          catchError(err => of(fromActions.updateMappingContentError({ err }))),
        ),
      ),
    ),
  );

  onUpdateMappingContentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateMappingContentComplete),
      map(({ templateId, mappingId }) => fromActions.getMappingContents({ templateId, mappingId })),
    ),
  );

  onDeleteMappingContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedMappingContent),
      switchMap(({ templateId, mappingId, tagName }) =>
        this.templatesService.deleteMappingContent(templateId, mappingId, tagName).pipe(
          map(() => fromActions.deleteSelectedMappingContentComplete({ templateId, mappingId })),
          catchError(err => of(fromActions.deleteSelectedMappingContentError({ err }))),
        ),
      ),
    ),
  );

  onDeleteMappingContentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedMappingContentComplete),
      tap(({ templateId, mappingId }) =>
        this.router.navigate(['notifications', 'templates', templateId, 'mappings', mappingId, 'content']),
      ),
      map(({ templateId, mappingId }) => fromActions.getMappingContents({ templateId, mappingId })),
    ),
  );

  onGoToMappingActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToMappingActions),
        tap(({ templateId, mappingId }) => this.router.navigate(['notifications', 'templates', templateId, 'mappings', mappingId])),
      ),
    { dispatch: false },
  );

  onGoToMappingContentActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToMappingContentActions),
        tap(({ templateId, mappingId, tagName }) =>
          this.router.navigate(['notifications', 'templates', templateId, 'mappings', mappingId, 'content', tagName]),
        ),
      ),
    { dispatch: false },
  );

  onGoToMappingContentListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToMappingContentListPage),
      tap(({ templateId, mappingId }) =>
        this.router.navigate(['notifications', 'templates', templateId, 'mappings', mappingId, 'content']),
      ),
      map(() => fromActions.resetState({ selectedMappingContent: null })),
    ),
  );

  onGoToMappingListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToMappingListPage),
      tap(({ templateId }) => this.router.navigate(['notifications', 'templates', templateId, 'mappings'])),
      map(() => fromActions.resetState({ selectedMapping: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private templatesService: TemplatesService,
  ) {}
}
