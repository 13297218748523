import { HttpErrorResponse } from '@angular/common/http';
import { AddRoleGroupRequest, GroupedRoles, RoleGroup, RoleType, UpdateRoleGroupRequestFrontend } from '@models/authorization';
import { createAction, props } from '@ngrx/store';
import { State } from './role-groups.reducer';

const NAMESPACE = '[Authorization Role Groups]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ roleGroups: RoleGroup[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addNewRoleGroup = createAction(`${NAMESPACE} AddNewRoleGroup`, props<{ roleGroup: AddRoleGroupRequest }>());
export const addNewRoleGroupComplete = createAction(`${NAMESPACE} AddNewRoleGroupComplete`, props<{ roleGroup: RoleGroup }>());
export const addNewRoleGroupError = createAction(`${NAMESPACE} AddNewRoleGroupError`, props<{ err: HttpErrorResponse }>());

export const selectRoleGroup = createAction(`${NAMESPACE} SelectRoleGroup`, props<{ id: string }>());
export const selectRoleGroupComplete = createAction(`${NAMESPACE} SelectRoleGroupComplete`, props<{ roleGroup: RoleGroup }>());
export const selectRoleGroupError = createAction(`${NAMESPACE} SelectRoleGroupError`, props<{ err: HttpErrorResponse }>());

export const updateRoleGroup = createAction(`${NAMESPACE} UpdateRoleGroup`, props<{ roleGroup: UpdateRoleGroupRequestFrontend }>());
export const updateRoleGroupComplete = createAction(`${NAMESPACE} UpdateRoleGroupComplete`, props<{ roleGroup: RoleGroup }>());
export const updateRoleGroupError = createAction(`${NAMESPACE} UpdateRoleGroupError`, props<{ err: HttpErrorResponse }>());

export const getRoleGroupRoles = createAction(`${NAMESPACE} GetRoleGroupRoles`, props<{ id: string }>());
export const getRoleGroupRolesComplete = createAction(`${NAMESPACE} GetRoleGroupRolesComplete`, props<{ roleNames: string[] }>());
export const getRoleGroupRolesError = createAction(`${NAMESPACE} GetRoleGroupRolesError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedRoleGroup = createAction(`${NAMESPACE} DeleteSelectedRoleGroup`);
export const deleteSelectedRoleGroupComplete = createAction(`${NAMESPACE} DeleteSelectedRoleGroupComplete`);
export const deleteSelectedRoleGroupError = createAction(`${NAMESPACE} DeleteSelectedRoleGroupError`, props<{ err: HttpErrorResponse }>());

export const getGroupedRoles = createAction(`${NAMESPACE} GetGroupedRoles`, props<{ filter: RoleType }>());
export const getGroupedRolesComplete = createAction(`${NAMESPACE} GetGroupedRolesComplete`, props<{ groupedRoles: GroupedRoles[] }>());
export const getGroupedRolesError = createAction(`${NAMESPACE} GetGroupedRolesError`, props<{ err: HttpErrorResponse }>());

export const goToRoleGroupActions = createAction(`${NAMESPACE} GoToRoleGroupActions`, props<{ id: string }>());
export const goToSelectedRoleGroupActions = createAction(`${NAMESPACE} GoToSelectedRoleGroupActions`);
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
