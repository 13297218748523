import { HttpErrorResponse } from '@angular/common/http';
import { Branch, BranchCreate, BranchUpdate } from '@models/file-management/branch';
import { createAction, props } from '@ngrx/store';
import { State } from './branches.reducer';

const NAMESPACE = '[FileManagement Branches]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ branches: Branch[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addBranch = createAction(`${NAMESPACE} AddBranch`, props<{ branch: BranchCreate }>());
export const addBranchComplete = createAction(`${NAMESPACE} AddBranchComplete`, props<{ branch: Branch }>());
export const addBranchError = createAction(`${NAMESPACE} AddBranchError`, props<{ err: HttpErrorResponse }>());

export const updateBranch = createAction(`${NAMESPACE} UpdateBranch`, props<{ id: string; branch: BranchUpdate }>());
export const updateBranchComplete = createAction(`${NAMESPACE} UpdateBranchComplete`, props<{ branch: Branch }>());
export const updateBranchError = createAction(`${NAMESPACE} UpdateBranchError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedBranch = createAction(`${NAMESPACE} DeleteSelectedBranch`);
export const deleteSelectedBranchComplete = createAction(`${NAMESPACE} DeleteSelectedBranchComplete`);
export const deleteSelectedBranchError = createAction(`${NAMESPACE} DeleteSelectedBranchError`, props<{ err: HttpErrorResponse }>());

export const selectBranch = createAction(`${NAMESPACE} SelectBranch`, props<{ id: string }>());
export const selectBranchComplete = createAction(`${NAMESPACE} SelectBranchComplete`, props<{ branch: Branch }>());
export const selectBranchError = createAction(`${NAMESPACE} SelectBranchError`, props<{ err: HttpErrorResponse }>());

export const goToBranchActions = createAction(`${NAMESPACE} GoToBranchActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
