import { Topic, TopicOwner } from '@models/marketing/topics/topics.model';
import { SubscriptionListItem } from '@models/subscriptions';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './topics.actions';

export interface State {
  isLoading: boolean;
  topicCreateLoading: boolean;
  topicOwnerCreateLoading: boolean;
  topics: Topic[];

  selectedTopic: Topic | null;
  selectedTopicLoading: boolean;

  subscribers: SubscriptionListItem[];
  subscribersLoading: boolean;

  owners: TopicOwner[];
  ownersLoading: boolean;

  selectedOwner: TopicOwner | null;
  selectedOwnerLoading: boolean;
}

export const initialState: State = {
  topicCreateLoading: false,
  topicOwnerCreateLoading: false,

  isLoading: false,
  topics: [],

  selectedTopic: null,
  selectedTopicLoading: false,

  subscribers: [],
  subscribersLoading: false,

  owners: [],
  ownersLoading: false,

  selectedOwner: null,
  selectedOwnerLoading: false,
};

export const topicsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { topics }) => ({
    ...state,
    topics: sortAlphabetically(topics, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addTopic, state => ({ ...state, topicCreateLoading: true, isLoading: true })),
  on(fromActions.addTopicComplete, (state, { topic }) => ({
    ...state,
    isLoading: false,
    topicCreateLoading: false,
    topics: sortAlphabetically([...state.topics, topic], predicateForSorting('name')),
  })),
  on(fromActions.addTopicError, state => ({ ...state, isLoading: false, topicCreateLoading: false })),

  on(fromActions.addTopicOwner, state => ({ ...state, ownersLoading: true, topicOwnerCreateLoading: true })),
  on(fromActions.addTopicOwnerComplete, (state, { owner }) => ({
    ...state,
    ownersLoading: false,
    topicOwnerCreateLoading: false,
    selectedOwner: owner,
    owners: sortAlphabetically<TopicOwner>([...state.owners, owner], predicateForSorting('name')),
  })),
  on(fromActions.addTopicOwnerError, state => ({ ...state, ownersLoading: false, topicOwnerCreateLoading: false })),

  on(fromActions.updateTopic, state => ({ ...state, selectedTopicLoading: true, isLoading: true })),
  on(fromActions.updateTopicComplete, (state, { topic }) => ({
    ...state,
    isLoading: false,
    selectedTopicLoading: false,
    selectedTopic: topic,
    topics: sortAlphabetically(
      findAndReplace<Topic>(state.topics, topic, item => item.id === topic.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.updateTopicError, state => ({
    ...state,
    selectedTopic: { ...state.selectedTopic! },
    isLoading: false,
    selectedTopicLoading: false,
  })),

  on(fromActions.selectTopic, state => ({ ...state, selectedTopicLoading: true })),
  on(fromActions.selectTopicComplete, (state, { topic }) => ({ ...state, selectedTopicLoading: false, selectedTopic: topic })),
  on(fromActions.selectTopicError, state => ({ ...state, selectedTopicLoading: false })),

  on(fromActions.selectTopicOwner, state => ({ ...state, selectedOwnerLoading: true })),
  on(fromActions.selectTopicOwnerComplete, (state, { owner }) => ({ ...state, selectedOwner: owner, selectedOwnerLoading: false })),
  on(fromActions.selectTopicOwnerError, state => ({ ...state, selectedOwnerLoading: false })),

  on(fromActions.getTopicSubscribers, state => ({ ...state, subscribers: [], subscribersLoading: true })),
  on(fromActions.getTopicSubscribersComplete, (state, { subscribers }) => ({
    ...state,
    subscribers: subscribers,
    subscribersLoading: false,
  })),
  on(fromActions.getTopicSubscribersError, state => ({ ...state, subscribersLoading: false })),

  on(fromActions.getTopicOwners, state => ({ ...state, owners: [], ownersLoading: true })),
  on(fromActions.getTopicOwnersComplete, (state, { owners }) => ({
    ...state,
    owners: owners,
    ownersLoading: false,
  })),
  on(fromActions.getTopicOwnersError, state => ({ ...state, ownersLoading: false })),

  on(fromActions.deleteSelectedTopic, state => ({ ...state, selectedTopicLoading: true })),
  on(fromActions.deleteSelectedTopicComplete, state => ({
    ...state,
    selectedTopicLoading: false,
    selectedTopic: null,
    selectedOwner: null,
    owners: [],
    subscribers: [],
  })),
  on(fromActions.deleteSelectedTopicError, state => ({
    ...state,
    selectedTopicLoading: false,
  })),

  on(fromActions.deleteSelectedTopicOwner, state => ({ ...state, selectedOwnerLoading: true })),
  on(fromActions.deleteSelectedTopicOwnerComplete, state => ({
    ...state,
    selectedOwnerLoading: false,
    selectedOwner: null,
  })),
  on(fromActions.deleteSelectedTopicOwnerError, state => ({
    ...state,
    selectedOwnerLoading: false,
  })),
);
