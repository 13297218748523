import { HttpErrorResponse } from '@angular/common/http';
import { CustomerListItem } from '@models/customers';
import { createAction, props } from '@ngrx/store';
import { State } from './customers.reducer';

const NAMESPACE = '[Customers]';

export const getAll = createAction(`${NAMESPACE} GetAll`, props<{ partnerId: string; includeMarkedForDeletion?: boolean }>());
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ customers: CustomerListItem[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
