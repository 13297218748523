import { Branch } from '@models/file-management/branch/branch.model';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './branches.actions';

export interface State {
  isLoading: boolean;
  branchCreateLoading: boolean;
  branches: Branch[];

  selectedBranch: Branch | null;
  selectedBranchLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  branchCreateLoading: false,
  branches: [],

  selectedBranch: null,
  selectedBranchLoading: false,
};

export const branchesReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { branches }) => ({
    ...state,
    branches: sortAlphabetically(branches, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addBranch, state => ({ ...state, branchCreateLoading: true, isLoading: true })),
  on(fromActions.addBranchComplete, (state, { branch }) => ({
    ...state,
    isLoading: false,
    branchCreateLoading: false,
    branches: sortAlphabetically([...state.branches, branch], predicateForSorting('name')),
  })),
  on(fromActions.addBranchError, state => ({ ...state, isLoading: false, branchCreateLoading: false })),

  on(fromActions.updateBranch, state => ({ ...state, selectedBranchLoading: true, isLoading: true })),
  on(fromActions.updateBranchComplete, (state, { branch }) => ({
    ...state,
    isLoading: false,
    selectedBranchLoading: false,
    selectedBranch: branch,
    branches: sortAlphabetically(
      findAndReplace<Branch>(state.branches, branch, item => item.id === branch.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.updateBranchError, state => ({
    ...state,
    selectedBranch: { ...state.selectedBranch! },
    isLoading: false,
    selectedBranchLoading: false,
  })),

  on(fromActions.selectBranch, state => ({ ...state, selectedBranchLoading: true })),
  on(fromActions.selectBranchComplete, (state, { branch }) => ({ ...state, selectedBranchLoading: false, selectedBranch: branch })),
  on(fromActions.selectBranchError, state => ({ ...state, selectedBranchLoading: false })),

  on(fromActions.deleteSelectedBranch, state => ({ ...state, selectedBranchLoading: true })),
  on(fromActions.deleteSelectedBranchComplete, state => ({
    ...state,
    selectedBranchLoading: false,
    selectedBranch: null,
    selectedOwner: null,
    owners: [],
    subscribers: [],
  })),
  on(fromActions.deleteSelectedBranchError, state => ({
    ...state,
    selectedBranchLoading: false,
  })),
);
