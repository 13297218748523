import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.fileManagementBranches);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsBranchCreateLoading = createSelector(selectModuleState, state => state.branchCreateLoading);
export const selectBranches = createSelector(selectModuleState, state => state.branches);

export const selectSelectedBranch = createSelector(selectModuleState, state => state.selectedBranch);
export const selectSelectedBranchId = createSelector(selectModuleState, state => state.selectedBranch && state.selectedBranch.id);
export const selectSelectedBranchLoading = createSelector(selectModuleState, state => state.selectedBranchLoading);
