import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { productsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './products.actions';
import { ProductsService } from './products.service';

@Injectable()
export class ProductsEffects {
  onGetProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.productsService.getAll().pipe(
          map(products => fromActions.getAllComplete({ products })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onSelectProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectProduct),
      switchMap(({ id }) =>
        this.store.pipe(
          select(productsSelectors.selectProducts),
          take(1),
          switchMap(products => {
            if (products.length) {
              const product = products.find(p => p.id === id)!;
              return of(fromActions.selectProductComplete({ product }));
            } else {
              return this.productsService.get(id).pipe(
                map(product => fromActions.selectProductComplete({ product })),
                catchError(err => of(fromActions.selectProductError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onActivateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateProduct),
      switchMap(({ id }) =>
        this.productsService.activate(id).pipe(
          map(product => fromActions.activateProductComplete({ product })),
          catchError(err => of(fromActions.activateProductError({ err }))),
        ),
      ),
    ),
  );

  onDeactivateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deactivateProduct),
      switchMap(({ id }) =>
        this.productsService.deactivate(id).pipe(
          map(product => fromActions.deactivateProductComplete({ product })),
          catchError(err => of(fromActions.deactivateProductError({ err }))),
        ),
      ),
    ),
  );

  onActivateProductComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.activateProductComplete),
        tap(({ product }) => this.router.navigate(['/products', product.id, 'details'])),
      ),
    { dispatch: false },
  );

  onDeactivateProductComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deactivateProductComplete),
        tap(({ product }) => this.router.navigate(['/products', product.id, 'details'])),
      ),
    { dispatch: false },
  );

  onUpdateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProduct),
      switchMap(({ id, product }) =>
        this.productsService.update(id, product).pipe(
          map(product => fromActions.updateProductComplete({ product })),
          catchError(err => of(fromActions.updateProductError({ err }))),
        ),
      ),
    ),
  );

  onGoToProductActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToProductActions),
        tap(({ id }) => this.router.navigate(['products', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['products'])),
      map(() => fromActions.resetState({ selectedProduct: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private productsService: ProductsService,
  ) {}
}
