import { HttpErrorResponse } from '@angular/common/http';
import { CommonData } from '@models/common';
import { createAction, props } from '@ngrx/store';
import { State } from './common-data.reducer';
import { CommonDataObj } from './common-data.service';

const NAMESPACE = '[CommonData]';

export const getLanguages = createAction(`${NAMESPACE} GetAllLanguages`);
export const getLanguagesComplete = createAction(`${NAMESPACE} GetAllLanguagesComplete`, props<{ languages: CommonData<'language'>[] }>());
export const getLanguagesError = createAction(`${NAMESPACE} GetAllLanguagesError`, props<{ err: HttpErrorResponse }>());

export const getMembershipLevels = createAction(`${NAMESPACE} GetAllMembershipLevels`);
export const getMembershipLevelsComplete = createAction(
  `${NAMESPACE} GetAllMembershipLevelsComplete`,
  props<{ membershipLevels: CommonData<'membershipLevel'>[] }>(),
);
export const getMembershipLevelsError = createAction(`${NAMESPACE} GetAllMembershipLevelsError`, props<{ err: HttpErrorResponse }>());

export const getRegions = createAction(`${NAMESPACE} GetAllRegions`);
export const getRegionsComplete = createAction(`${NAMESPACE} GetAllRegionsComplete`, props<{ regions: CommonData<'region'>[] }>());
export const getRegionsError = createAction(`${NAMESPACE} GetAllRegionsError`, props<{ err: HttpErrorResponse }>());

export const getAllCommonData = createAction(`${NAMESPACE} GetAllCommonData`);
export const getAllCommonDataComplete = createAction(`${NAMESPACE} GetAllCommonDataComplete`, props<{ commonData: CommonDataObj }>());
export const getAllCommonDataError = createAction(`${NAMESPACE} GetAllCommonDataError`, props<{ err: HttpErrorResponse }>());

export const selectCommonData = createAction(`${NAMESPACE} SelectCommonData`, props<{ id: string }>());
export const selectCommonDataComplete = createAction(`${NAMESPACE} SelectCommonDataComplete`, props<{ commonData: CommonData }>());
export const selectCommonDataError = createAction(`${NAMESPACE} SelectCommonDataError`, props<{ err: HttpErrorResponse }>());

export const goToCommonDataActions = createAction(`${NAMESPACE} GoToCommonDataActions`, props<{ id: string }>());
export const goToCommonDataListPage = createAction(`${NAMESPACE} GoToCommonDataListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
