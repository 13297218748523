import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {} from '@models/index';
import {
  AddNewsArticlePromotionRequest,
  CreateNewsArticleRequest,
  NewsArticle,
  NewsArticleComment,
  NewsArticleCreateFileResponse,
  NewsArticleListItem,
  NewsArticleListItemResponse,
  NewsArticleResponse,
  NewsArticleUpdateContentRequest,
  NewsArticleUpdateContentResponse,
  UpdateNewsArticlePromotionRequest,
  UpdateNewsArticleRegionRequest,
  UpdateNewsArticleRequest,
} from '@models/news-article/news-article.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class NewsArticlesService {
  private readonly url = `${environment.bffUrl}/news-articles`;
  constructor(private http: HttpClient) {}

  getAll(): Observable<NewsArticleListItem[]> {
    return this.http.get<NewsArticleListItemResponse[]>(this.url);
  }

  getOne(id: string, includeContent: boolean): Observable<NewsArticle> {
    return this.http.get<NewsArticleResponse>(`${this.url}/${id}`, { params: { includeContent: includeContent } });
  }

  create(req: CreateNewsArticleRequest): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(this.url, req);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  update(id: string, newsArticle: UpdateNewsArticleRequest): Observable<NewsArticleListItem> {
    return this.http.put<NewsArticleListItemResponse>(`${this.url}/${id}`, newsArticle);
  }

  activate(id: string): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/activate`, null);
  }

  deactivate(id: string): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/deactivate`, null);
  }

  setContent(id: string, req: NewsArticleUpdateContentRequest): Observable<NewsArticleUpdateContentResponse> {
    return this.http.post<NewsArticleUpdateContentResponse>(`${this.url}/${id}/content`, req);
  }

  setWorkloads(id: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/set-workloads`, req);
  }

  setTopics(id: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/set-topics`, req);
  }

  setMembershipLevels(id: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${id}/set-membership-levels`, req);
  }

  createThumbnailFile(id: string): Observable<NewsArticleCreateFileResponse> {
    return this.http.post<NewsArticleCreateFileResponse>(`${this.url}/${id}/files`, null);
  }

  getFile(articleId: string, fileId: string): Observable<any> {
    return this.http.get<string>(`${this.url}/${articleId}/files/${fileId}`);
  }

  getAllComments(articleId: string, includeSubComments: boolean): Observable<NewsArticleComment[]> {
    return this.http.get<NewsArticleComment[]>(`${this.url}/${articleId}/comments`, { params: { includeSubComments: includeSubComments } });
  }

  deleteComment(articleId: string, commentId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${articleId}/comments/${commentId}`);
  }

  addRegions(articleId: string, req: string[]): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${articleId}/set-regions`, req);
  }

  updateRegion(articleId: string, regionId: string, req: UpdateNewsArticleRegionRequest): Observable<NewsArticleListItem> {
    return this.http.put<NewsArticleListItemResponse>(`${this.url}/${articleId}/regions/${regionId}`, req);
  }

  deleteRegion(articleId: string, regionId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${articleId}/regions/${regionId}`);
  }

  approveRegion(articleId: string, regionId: string): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${articleId}/regions/${regionId}/approve`, null);
  }

  addPromotion(articleId: string, req: AddNewsArticlePromotionRequest): Observable<NewsArticleListItem> {
    return this.http.post<NewsArticleListItemResponse>(`${this.url}/${articleId}/promotion`, req);
  }

  updatePromotion(articleId: string, regionId: string, req: UpdateNewsArticlePromotionRequest): Observable<NewsArticleListItem> {
    return this.http.put<NewsArticleListItemResponse>(`${this.url}/${articleId}/promotion/${regionId}`, req);
  }

  deletePromotion(articleId: string, regionId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${articleId}/promotion/${regionId}`);
  }
}
