import { UserModel } from '@models/authentication/auth';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Identity]';

export const checkAuth = createAction(`${NAMESPACE} CheckAuth`);
export const checkAuthCompleted = createAction(`${NAMESPACE} CheckAuthCompleted`, props<UserModel>());

export const refresh = createAction(`${NAMESPACE} Refresh`);
export const refreshCompleted = createAction(`${NAMESPACE} RefreshCompleted`, props<{ expires_at: number }>());
export const refreshError = createAction(`${NAMESPACE} RefreshError`, props<{ err: any }>());

export const userNotSystemAdmin = createAction(`${NAMESPACE} UserNotSystemAdmin`);
export const userUnauthorized = createAction(`${NAMESPACE} UserUnauthorized`, props<{ err: any }>());
