import { HttpErrorResponse } from '@angular/common/http';
import { LogsQueryParams, NotificationLog, NotificationLogListItem } from '@models/notifications';
import { createAction, props } from '@ngrx/store';
import { State } from './logs.reducer';

const NAMESPACE = '[Notifications Logs]';

export const getAllLogs = createAction(`${NAMESPACE} GetAllLogs`, props<{ logsQuery: LogsQueryParams }>());
export const getAllLogsComplete = createAction(`${NAMESPACE} GetAllLogsComplete`, props<{ logs: NotificationLogListItem[] }>());
export const getAllLogsError = createAction(`${NAMESPACE} GetAllLogsError`, props<{ err: HttpErrorResponse }>());

export const selectLog = createAction(`${NAMESPACE} SelectLog`, props<{ logId: string }>());
export const selectLogComplete = createAction(`${NAMESPACE} SelectLogComplete`, props<{ log: NotificationLog }>());
export const selectLogError = createAction(`${NAMESPACE} SelectLogError`, props<{ err: HttpErrorResponse }>());

export const resendNotification = createAction(`${NAMESPACE} ResendNotification`, props<{ logId: string }>());
export const resendNotificationComplete = createAction(`${NAMESPACE} ResendNotificationComplete`, props<{ log: NotificationLog }>());
export const resendNotificationError = createAction(`${NAMESPACE} ResendNotificationError`, props<{ err: HttpErrorResponse }>());

export const sendNotificationToAnotherEmail = createAction(
  `${NAMESPACE} SendNotificationToAnotherEmail`,
  props<{ logId: string; email: string }>(),
);
export const sendNotificationToAnotherEmailComplete = createAction(`${NAMESPACE} SendNotificationToAnotherEmailComplete`);
export const sendNotificationToAnotherEmailError = createAction(
  `${NAMESPACE} SendNotificationToAnotherEmailError`,
  props<{ err: HttpErrorResponse }>(),
);

export const goToLogActions = createAction(`${NAMESPACE} GoToLogActions`, props<{ logId: string }>());
export const goToLogsListPage = createAction(`${NAMESPACE} GoToLogsListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());

export const openFilterModal = createAction(`${NAMESPACE} OpenFilterModal`);
export const submitFilterModal = createAction(`${NAMESPACE} SubmitFilterModal`, props<{ logsQuery: LogsQueryParams }>());
