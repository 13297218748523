import { MenuAccessPermissionTypes, PermissionTypes, RequiredPermissions } from '@models/authorization';
import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.identity);

export const selectIsAuthenticated = createSelector(selectModuleState, i => i.isAuthenticated);
export const selectIsSystemAdmin = createSelector(selectModuleState, i => i.isSystemAdmin);
export const selectUser = createSelector(selectModuleState, i => i.user);
export const selectIsLoading = createSelector(selectModuleState, i => i.isLoading);

export const selectUserId = createSelector(selectModuleState, state => state.user.userinfo.sub);

export const selectTokenExpiration = createSelector(selectModuleState, state => state.user.expires_at);

export const getPermissions = createSelector(selectModuleState, s => s.user.permissions);
export const getPermissionTypes = createSelector(getPermissions, permission =>
  permission.length ? permission.map(perm => perm.name) : [],
);

export const hasPermissions = (permissionNames: PermissionTypes[]) =>
  createSelector(getPermissionTypes, userPermissionNames => {
    let hasAllPermissions = true;
    for (const name of permissionNames) {
      if (!userPermissionNames.includes(name)) {
        hasAllPermissions = false;
        break;
      }
    }

    return hasAllPermissions;
  });

export const selectHasPermissionForMenuAccess = (requiredPermissions: MenuAccessPermissionTypes[]) =>
  createSelector(getPermissionTypes, userPermissions =>
    userPermissions.some(userPermission => requiredPermissions.includes(userPermission as MenuAccessPermissionTypes)),
  );

export const selectHasPermissionToAccessPage = ({ oneAtLeast, menuAccess }: RequiredPermissions) =>
  createSelector(getPermissionTypes, userPermissions => {
    let hasAllMenuPermissions = false;
    for (const permission of menuAccess) {
      const exists = userPermissions.find(existingPermission => existingPermission === permission);
      if (exists) {
        hasAllMenuPermissions = true;
      } else {
        hasAllMenuPermissions = false;
        break;
      }
    }
    const hasOtherAccess = oneAtLeast ? userPermissions.some(permType => oneAtLeast?.includes(permType)) : true;

    return hasAllMenuPermissions && hasOtherAccess;
  });
