import { HttpErrorResponse } from '@angular/common/http';
import { NewsRegion, NewsRegionApproveAutomaticallyRequest } from '@models/news-article';
import { createAction, props } from '@ngrx/store';
import { State } from './news-regions.reducer';

const NAMESPACE = '[NewsRegions]';

export const getAllNewsRegions = createAction(`${NAMESPACE} GetAllNewsRegions`);
export const getAllNewsRegionsComplete = createAction(`${NAMESPACE} GetAllNewsRegionsComplete`, props<{ newsRegions: NewsRegion[] }>());
export const getAllNewsRegionsError = createAction(`${NAMESPACE} GetAllNewsRegionsError`, props<{ err: HttpErrorResponse }>());

export const getOneNewsRegion = createAction(`${NAMESPACE} GetOneNewsRegion`, props<{ id: string }>());
export const getOneNewsRegionComplete = createAction(`${NAMESPACE} GetOneNewsRegionComplete`, props<{ newsRegion: NewsRegion }>());
export const getOneNewsRegionError = createAction(`${NAMESPACE} GetOneNewsRegionError`, props<{ err: HttpErrorResponse }>());

export const toggleNewsRegionAutomaticApproval = createAction(
  `${NAMESPACE} ToggleNewsRegionAutomaticApproval`,
  props<{ id: string; req: NewsRegionApproveAutomaticallyRequest }>(),
);
export const toggleNewsRegionAutomaticApprovalComplete = createAction(
  `${NAMESPACE} ToggleNewsRegionAutomaticApprovalComplete`,
  props<{ newsRegion: NewsRegion }>(),
);
export const toggleNewsRegionAutomaticApprovalError = createAction(
  `${NAMESPACE} ToggleNewsRegionAutomaticApprovalError`,
  props<{ err: HttpErrorResponse }>(),
);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
