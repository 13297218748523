import { NotificationLog, NotificationLogListItem } from '@models/notifications';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace } from '../../helpers/helper';
import * as fromActions from './logs.actions';

export interface State {
  isLoading: boolean;
  logs: NotificationLogListItem[];

  selectedLog: NotificationLog | null;
  selectedLogLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  logs: [],
  selectedLog: null,
  selectedLogLoading: false,
};

export const notificationLogsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllLogs, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllLogsComplete, (state, { logs }) => ({
    ...state,
    isLoading: false,
    logs,
  })),
  on(fromActions.getAllLogsError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectLog, state => ({ ...state, selectedLogLoading: true })),
  on(fromActions.selectLogComplete, (state, { log }) => ({
    ...state,
    selectedLogLoading: false,
    selectedLog: log,
  })),
  on(fromActions.selectLogError, state => ({ ...state, selectedLogLoading: false })),

  on(fromActions.resendNotification, state => ({ ...state, selectedLogLoading: true, isLoading: true })),
  on(fromActions.resendNotificationComplete, (state, { log }) => ({
    ...state,
    selectedLog: log,
    logs: findAndReplace<NotificationLogListItem>(state.logs, log, item => item.id === log.id),
    selectedLogLoading: false,
    isLoading: false,
  })),
  on(fromActions.resendNotificationError, state => ({ ...state, selectedLogLoading: false, isLoading: false })),

  on(fromActions.sendNotificationToAnotherEmail, state => ({ ...state, selectedLogLoading: true, isLoading: true })),
  on(fromActions.sendNotificationToAnotherEmailComplete, state => ({
    ...state,
    selectedLogLoading: false,
    isLoading: false,
  })),
  on(fromActions.sendNotificationToAnotherEmailError, state => ({ ...state, selectedLogLoading: false, isLoading: false })),
);
