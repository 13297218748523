import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { templateGroupsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './template-groups.actions';
import { TemplateGroupsService } from './template-groups.service';

@Injectable()
export class TemplateGroupsEffects {
  onGetTemplateGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.templateGroupsService.getAllTemplateGroups().pipe(
          map(templateGroups => fromActions.getAllComplete({ templateGroups })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onSelectTemplateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectTemplateGroup),
      switchMap(({ id }) =>
        this.store.pipe(
          select(templateGroupsSelectors.selectTemplateGroups),
          take(1),
          switchMap(templateGroups => {
            if (templateGroups.length) {
              const templateGroup = templateGroups.find(tg => tg.id === id)!;
              return of(fromActions.selectTemplateGroupComplete({ templateGroup }));
            } else {
              return this.templateGroupsService.getOneTemplateGroup(id).pipe(
                map(templateGroup => fromActions.selectTemplateGroupComplete({ templateGroup })),
                catchError(err => of(fromActions.selectTemplateGroupError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onAddNewTemplateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTemplateGroup),
      switchMap(({ templateGroup }) =>
        this.templateGroupsService.createTemplateGroup(templateGroup).pipe(
          map(templateGroup => fromActions.addTemplateGroupComplete({ templateGroup })),
          catchError(err => of(fromActions.addTemplateGroupError({ err }))),
        ),
      ),
    ),
  );

  onAddNewTemplateGroupComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addTemplateGroupComplete),
        tap(({ templateGroup }) => this.router.navigate(['notifications', 'template-groups', templateGroup.id, 'details'])),
      ),
    { dispatch: false },
  );

  onUpdateTemplateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTemplateGroup),
      switchMap(({ id, templateGroup }) =>
        this.templateGroupsService.updateTemplateGroup(id, templateGroup).pipe(
          map(templateGroup => fromActions.updateTemplateGroupComplete({ templateGroup })),
          catchError(err => of(fromActions.updateTemplateGroupError({ err }))),
        ),
      ),
    ),
  );

  onDeleteTemplateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTemplateGroup),
      switchMap(() =>
        this.store.pipe(
          select(templateGroupsSelectors.selectSelectedTemplateGroupId),
          take(1),
          switchMap(id =>
            this.templateGroupsService.deleteTemplateGroup(id!).pipe(
              map(() => fromActions.deleteSelectedTemplateGroupComplete()),
              catchError(err => of(fromActions.deleteSelectedTemplateGroupError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteTemplateGroupComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTemplateGroupComplete),
      tap(() => this.router.navigate(['notifications', 'template-groups'])),
      map(() => fromActions.getAll()),
    ),
  );

  onUpdateTemplateGroupComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTemplateGroupComplete),
      map(() => fromActions.getAll()),
    ),
  );

  onGoToTemplateGroupActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTemplateGroupActions),
        tap(({ templateGroupId }) => this.router.navigate(['notifications', 'template-groups', templateGroupId])),
      ),
    { dispatch: false },
  );

  onGoToTemplateGroupListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToTemplateGroupListPage),
      tap(() => this.router.navigate(['notifications', 'template-groups'])),
      map(() => fromActions.resetState({ selectedTemplateGroup: null })),
    ),
  );

  onGetAdditionalReceivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAdditionalReceivers),
      switchMap(({ templateGroupId }) =>
        this.templateGroupsService.getAllAdditionalReceivers(templateGroupId).pipe(
          map(additionalReceivers => fromActions.getAdditionalReceiversComplete({ additionalReceivers })),
          catchError(err => of(fromActions.getAdditionalReceiversError({ err }))),
        ),
      ),
    ),
  );

  onAddAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAdditionalReceiver),
      switchMap(({ templateGroupId, additionalReceiver }) =>
        this.templateGroupsService.createAdditionalReceiver(templateGroupId, additionalReceiver).pipe(
          map(additionalReceiver => fromActions.addAdditionalReceiverComplete({ additionalReceiver })),
          catchError(err => of(fromActions.addAdditionalReceiverError({ err }))),
        ),
      ),
    ),
  );

  onAddAdditionalReceiverComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addAdditionalReceiverComplete),
        switchMap(({ additionalReceiver }) => {
          return this.router.navigate([
            'notifications',
            'template-groups',
            additionalReceiver.templateGroupId,
            'receivers',
            additionalReceiver.id,
            'details',
          ]);
        }),
      ),
    { dispatch: false },
  );

  onDeleteAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAdditionalReceiver),
      switchMap(({ templateGroupId, additionalReceiverId }) =>
        this.templateGroupsService.deleteAdditionalReceiver(templateGroupId, additionalReceiverId).pipe(
          map(() => fromActions.deleteSelectedAdditionalReceiverComplete({ templateGroupId })),
          catchError(err => of(fromActions.deleteSelectedAdditionalReceiverError({ err }))),
        ),
      ),
    ),
  );

  onDeleteAdditionalReceiverComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAdditionalReceiverComplete),
      tap(({ templateGroupId }) => this.router.navigate(['notifications', 'template-groups', templateGroupId, 'receivers'])),
      map(({ templateGroupId }) => fromActions.getAdditionalReceivers({ templateGroupId })),
    ),
  );

  onSelectAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAdditionalReceiver),
      switchMap(({ templateGroupId, additionalReceiverId }) =>
        this.store.pipe(
          select(templateGroupsSelectors.selectAdditionalReceivers),
          take(1),
          switchMap(additionalReceivers => {
            if (additionalReceivers.length) {
              const additionalReceiver = additionalReceivers.find(
                ar => ar.id === additionalReceiverId && ar.templateGroupId === templateGroupId,
              )!;
              return of(fromActions.selectAdditionalReceiverComplete({ additionalReceiver }));
            } else {
              return this.templateGroupsService.getOneAdditionalReceiver(templateGroupId, additionalReceiverId).pipe(
                map(additionalReceiver => fromActions.selectAdditionalReceiverComplete({ additionalReceiver })),
                catchError(err => of(fromActions.selectAdditionalReceiverError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onUpdateAdditionalReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAdditionalReceiver),
      switchMap(({ templateGroupId, additionalReceiverId, additionalReceiver }) =>
        this.templateGroupsService.updateAdditionalReceiver(templateGroupId, additionalReceiverId, additionalReceiver).pipe(
          map(additionalReceiver => fromActions.updateAdditionalReceiverComplete({ additionalReceiver })),
          catchError(err => of(fromActions.updateAdditionalReceiverError({ err }))),
        ),
      ),
    ),
  );

  onUpdateAdditionalReceiverComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAdditionalReceiverComplete),
      map(({ additionalReceiver }) => fromActions.getAdditionalReceivers({ templateGroupId: additionalReceiver.templateGroupId })),
    ),
  );

  onGoToAdditionalReceiverActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToAdditionalReceiverActions),
        tap(({ templateGroupId, additionalReceiverId }) =>
          this.router.navigate(['notifications', 'template-groups', templateGroupId, 'receivers', additionalReceiverId]),
        ),
      ),
    { dispatch: false },
  );

  onGoToAdditionalReceiverListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToAdditionalReceiverListPage),
      tap(({ templateGroupId }) => this.router.navigate(['notifications', 'template-groups', templateGroupId, 'receivers'])),
      map(() => fromActions.resetState({ selectedAdditionalReceiver: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private templateGroupsService: TemplateGroupsService,
  ) {}
}
