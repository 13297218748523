<cui-card>
  <cui-card-header [showCloseBtn]="true" (close)="onCancel()">
    <h2>Action Logs Filter</h2>
  </cui-card-header>
  <cui-card-body>
    <form [formGroup]="form">
      <div class="date-fields">
        <cui-date-picker [label]="'Start Date'" formControlName="startDate" [required]="true"></cui-date-picker>
        <span class="flex-spacer"></span>
        <cui-date-picker [label]="'End Date'" formControlName="endDate" [required]="true"></cui-date-picker>
      </div>
      <cui-input [label]="'Partner ID'" [required]="true" formControlName="partnerId" type="text"></cui-input>
      <h3>Optional fields</h3>
      <cui-input [label]="'Customer ID'" [required]="false" formControlName="customerId" type="text"></cui-input>
    </form>
  </cui-card-body>
  <cui-card-footer>
    <cui-button (clicked)="onCancel()">Cancel</cui-button>
    <cui-button-cta [disabled]="!form.valid" (clicked)="onConfirm()">Submit</cui-button-cta>
  </cui-card-footer>
</cui-card>
