import { ActionLog } from '@models/index';
import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';
import * as fromReducer from './action-logs.reducer';

const selectModuleState = createSelector(getRootState, rootState => rootState.actionLogs);
export const { selectAll, selectEntities } = fromReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoadingList = createSelector(selectModuleState, state => state.isLoadingList);
export const selectIsLoadingItem = createSelector(selectModuleState, state => state.isLoadingItem);
export const selectActionLogsList = createSelector(selectAll, actionLogs => actionLogs ?? []);
export const selectCurrentActionLogId = createSelector(selectModuleState, state => state.selectedActionLogId);
export const selectCurrentActionLog = createSelector(
  selectEntities,
  selectCurrentActionLogId,
  (entities, id) => (id && entities?.[id]) as ActionLog | null,
);

export const selectCurrentActionLogDetails = createSelector(selectCurrentActionLog, currentActionLog => {
  return {
    ...currentActionLog,
    ...doesExistSecondLevelObjects(currentActionLog?.details).firstLevelDetails,
  } as ActionLog;
});

export const selectCurrentActionLogDetailsData = createSelector(selectCurrentActionLog, currentActionLog => {
  return renameKeys({
    ...doesExistSecondLevelObjects(currentActionLog?.details).secondLevelDetails,
  } as ActionLog);
});

const doesExistSecondLevelObjects = (obj: ActionLog) => {
  let firstLevelDetails = {};
  let secondLevelDetails = {};

  if (obj !== null && obj !== undefined) {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' || Array.isArray(value)) {
        Object.assign(secondLevelDetails, { [key]: value });
      } else {
        Object.assign(firstLevelDetails, { [key]: value });
      }
    }
  }

  return { firstLevelDetails, secondLevelDetails };
};

const renameKeys = (obj: ActionLog) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = convertCamelCaseToTitleCaseText(key);
    return { [newKey]: obj[key as keyof ActionLog] };
  });
  return Object.assign({}, ...keyValues);
};

const convertCamelCaseToTitleCaseText = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
