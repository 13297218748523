import { GroupedRoles, RoleGroup } from '@models/authorization';
import { createReducer, on } from '@ngrx/store';
import { sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './role-groups.actions';

export interface State {
  isLoading: boolean;
  roleGroups: RoleGroup[];
  groupedRoles: GroupedRoles[];

  selectedRoleGroup: null | RoleGroup;
  selectedRoleGroupLoading: boolean;

  roleGroupRoles: string[];
  roleGroupRolesLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  roleGroups: [],
  groupedRoles: [],

  selectedRoleGroup: null,
  selectedRoleGroupLoading: false,

  roleGroupRoles: [],
  roleGroupRolesLoading: false,
};

export const roleGroupsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.getAll, state => ({ ...state, isLoading: true, selectedRoleGroupLoading: true })),
  on(fromActions.getAllComplete, (state, { roleGroups }) => ({
    ...state,
    isLoading: false,
    selectedRoleGroupLoading: false,
    roleGroups: sortAlphabetically([...roleGroups], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false, selectedRoleGroupLoading: false })),

  on(fromActions.addNewRoleGroup, state => ({ ...state, isLoading: true })),
  on(fromActions.addNewRoleGroupComplete, (state, { roleGroup }) => ({
    ...state,
    isLoading: false,
    roleGroups: sortAlphabetically([...state.roleGroups, roleGroup], (a, b) => a.name.localeCompare(b.name)),
  })),
  on(fromActions.addNewRoleGroupError, state => ({ ...state, isLoading: false })),

  on(fromActions.deleteSelectedRoleGroup, state => ({ ...state, selectedRoleGroupLoading: true })),
  on(fromActions.deleteSelectedRoleGroupComplete, state => ({
    ...state,
    selectedRoleGroupLoading: false,
  })),
  on(fromActions.deleteSelectedRoleGroupError, state => ({
    ...state,
    selectedRoleGroupLoading: false,
  })),

  on(fromActions.selectRoleGroup, state => ({ ...state, selectedRoleGroupLoading: true, roleGroupRoles: [] })),
  on(fromActions.selectRoleGroupComplete, (state, { roleGroup }) => ({
    ...state,
    selectedRoleGroupLoading: false,
    selectedRoleGroup: roleGroup,
  })),
  on(fromActions.selectRoleGroupComplete, state => ({
    ...state,
    selectedRoleGroupLoading: false,
  })),

  on(fromActions.getRoleGroupRoles, state => ({ ...state, roleGroupRolesLoading: true })),
  on(fromActions.getRoleGroupRolesComplete, (state, { roleNames }) => ({
    ...state,
    roleGroupRolesLoading: false,
    roleGroupRoles: roleNames,
  })),
  on(fromActions.getRoleGroupRolesError, state => ({
    ...state,
    roleGroupRolesLoading: false,
  })),

  on(fromActions.updateRoleGroup, state => ({ ...state, selectedRoleGroupLoading: true })),
  on(fromActions.updateRoleGroupComplete, (state, { roleGroup }) => ({
    ...state,
    selectedRoleGroup: roleGroup,
    selectedRoleGroupLoading: false,
  })),
  on(fromActions.updateRoleGroupError, state => ({
    ...state,
    selectedRoleGroup: { ...state.selectedRoleGroup! },
    selectedRoleGroupLoading: false,
  })),

  on(fromActions.getGroupedRoles, state => ({ ...state, isLoading: true })),
  on(fromActions.getGroupedRolesComplete, (state, { groupedRoles }) => ({
    ...state,
    isLoading: false,
    groupedRoles,
  })),
  on(fromActions.getGroupedRolesError, state => ({ ...state, isLoading: false })),
);
