import { HttpErrorResponse } from '@angular/common/http';
import {
  AgreementListItem,
  AgreementLogListItem,
  AgreementVersion,
  AgreementVersionListItem,
  CreateAgreementRequest,
  CreateVersionRequest,
  UpdateAgreementRequest,
  UpdateVersionRequest,
} from '@models/index';
import { createAction, props } from '@ngrx/store';
import { State } from './agreements.reducer';

const NAMESPACE = '[Agreements]';

export const getAllAgreements = createAction(`${NAMESPACE} GetAllAgreements`);
export const getAllAgreementsComplete = createAction(`${NAMESPACE} GetAllAgreementsComplete`, props<{ agreements: AgreementListItem[] }>());
export const getAllAgreementsError = createAction(`${NAMESPACE} GetAllAgreementsError`, props<{ err: HttpErrorResponse }>());

export const selectAgreement = createAction(`${NAMESPACE} SelectAgreement`, props<{ id: string }>());
export const selectAgreementComplete = createAction(`${NAMESPACE} SelectAgreementComplete`, props<{ agreement: AgreementListItem }>());
export const selectAgreementError = createAction(`${NAMESPACE} SelectAgreementError`, props<{ err: HttpErrorResponse }>());

export const selectAgreementVersion = createAction(
  `${NAMESPACE} SelectAgreementVersion`,
  props<{ agreementId: string; versionId: string }>(),
);
export const selectAgreementVersionComplete = createAction(
  `${NAMESPACE} SelectAgreementVersionComplete`,
  props<{ version: AgreementVersion }>(),
);
export const selectAgreementVersionError = createAction(`${NAMESPACE} SelectAgreementVersionError`, props<{ err: HttpErrorResponse }>());

export const getLogs = createAction(`${NAMESPACE} GetLogs`, props<{ id: string }>());
export const getLogsComplete = createAction(`${NAMESPACE} GetLogsComplete`, props<{ logs: AgreementLogListItem[] }>());
export const getLogsError = createAction(`${NAMESPACE} GetLogsError`, props<{ err: HttpErrorResponse }>());

export const getVersions = createAction(`${NAMESPACE} GetVersions`, props<{ id: string }>());
export const getVersionsComplete = createAction(`${NAMESPACE} GetVersionsComplete`, props<{ versions: AgreementVersionListItem[] }>());
export const getVersionsError = createAction(`${NAMESPACE} GetVersionsError`, props<{ err: HttpErrorResponse }>());

export const addNewAgreement = createAction(`${NAMESPACE} AddNewAgreement`, props<{ agreement: CreateAgreementRequest }>());
export const addNewAgreementComplete = createAction(`${NAMESPACE} AddNewAgreementComplete`, props<{ agreement: AgreementListItem }>());
export const addNewAgreementError = createAction(`${NAMESPACE} AddNewAgreementError`, props<{ err: HttpErrorResponse }>());

export const addNewAgreementVersion = createAction(`${NAMESPACE} AddNewAgreementVersion`, props<{ version: CreateVersionRequest }>());
export const addNewAgreementVersionComplete = createAction(
  `${NAMESPACE} AddNewAgreementVersionComplete`,
  props<{ version: AgreementVersion }>(),
);
export const addNewAgreementVersionsError = createAction(`${NAMESPACE} AddNewAgreementVersionError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedAgreement = createAction(`${NAMESPACE} DeleteSelectedAgreement`);
export const deleteSelectedAgreementComplete = createAction(`${NAMESPACE} DeleteSelectedAgreementComplete`);
export const deleteSelectedAgreementError = createAction(`${NAMESPACE} DeleteSelectedAgreementError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedAgreementVersion = createAction(`${NAMESPACE} DeleteSelectedAgreementVersion`);
export const deleteSelectedAgreementVersionComplete = createAction(
  `${NAMESPACE} DeleteSelectedAgreementVersionComplete`,
  props<{ agreementId: string }>(),
);
export const deleteSelectedAgreementVersionError = createAction(
  `${NAMESPACE} DeleteSelectedAgreementVersionError`,
  props<{ err: HttpErrorResponse }>(),
);

export const publishSelectedAgreementVersion = createAction(`${NAMESPACE} PublishSelectedAgreementVersion`);
export const publishSelectedAgreementVersionComplete = createAction(
  `${NAMESPACE} PublishSelectedAgreementVersionComplete`,
  props<{ version: AgreementVersion }>(),
);
export const publishSelectedAgreementVersionError = createAction(
  `${NAMESPACE} PublishSelectedAgreementVersionError`,
  props<{ err: HttpErrorResponse }>(),
);

export const updateAgreement = createAction(`${NAMESPACE} UpdateAgreement`, props<{ id: string; agreement: UpdateAgreementRequest }>());
export const updateAgreementComplete = createAction(`${NAMESPACE} UpdateAgreementComplete`, props<{ agreement: AgreementListItem }>());
export const updateAgreementError = createAction(`${NAMESPACE} UpdateAgreementError`, props<{ err: HttpErrorResponse }>());

export const updateAgreementVersion = createAction(
  `${NAMESPACE} UpdateAgreementVersion`,
  props<{ agreementId: string; versionId: string; version: UpdateVersionRequest }>(),
);
export const updateAgreementVersionComplete = createAction(
  `${NAMESPACE} UpdateAgreementVersionComplete`,
  props<{ version: AgreementVersion }>(),
);
export const updateAgreementVersionError = createAction(`${NAMESPACE} UpdateAgreementVersionError`, props<{ err: HttpErrorResponse }>());

export const goToAgreementActions = createAction(`${NAMESPACE} GoToAgreementsActions`, props<{ id: string }>());
export const goToAgreementVersionActions = createAction(
  `${NAMESPACE} GoToAgreementVersionActions`,
  props<{ agreementId: string; versionId: string }>(),
);
export const goToSelectedAgreementActions = createAction(`${NAMESPACE} GoToSelectedAgreementActions`);
export const goToSelectedAgreementVersionActions = createAction(`${NAMESPACE} GoToSelectedAgreementVersionActions`);
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);
export const goToAgreementVersionListPage = createAction(`${NAMESPACE} GoToAgreementVersionListPage`, props<{ agreementId: string }>());

export const copySelectedAgreementPublicLink = createAction(`${NAMESPACE} CopySelectedAgreementPublicLink`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
