import { createReducer, on } from '@ngrx/store';
import * as fromActions from './assessments.actions';
import { AssessmentToggleOptions, IAssessment } from '@models/products-and-orders/assessments';

export interface State {
  assessments: IAssessment[];
  isLoading: boolean;
  assessmentListIsLoading: boolean;
  selectedAssessment: IAssessment | null;
  toggle: AssessmentToggleOptions;
}

const initialState: State = {
  assessments: [],
  isLoading: false,
  assessmentListIsLoading: false,
  selectedAssessment: null,
  toggle: AssessmentToggleOptions.Open,
};

export const assessmentsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.getAssessmentsList, (state, { toggle }) => ({
    ...state,
    toggle: toggle ?? state.toggle,
    assessmentListIsLoading: true,
  })),
  on(fromActions.getAssessmentsListComplete, (state, { assessments }) => ({
    ...state,
    assessmentListIsLoading: false,
    assessments: assessments,
  })),
  on(fromActions.getAssessmentsListError, state => ({ ...state, assessmentListIsLoading: false })),
  on(fromActions.selectAssessment, state => ({ ...state, isLoading: true })),
  on(fromActions.selectAssessmentComplete, (state, { assessment }) => ({
    ...state,
    selectedAssessment: assessment,
    isLoading: false,
  })),
  on(fromActions.selectAssessmentError, state => ({
    ...state,
    isLoading: false,
  })),
);
