import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { newsArticlesActions } from '@appState';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app-state/app.store';

@Injectable()
export class NewsArticlePreviewResolver {
  constructor(private readonly store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const id = route.paramMap.get('articleId')!;
    this.store.dispatch(newsArticlesActions.selectNewsArticle({ id, includeContent: true }));
  }
}
