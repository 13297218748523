import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateTemplateGroupAdditionalReceiverRequest,
  CreateTemplateGroupRequest,
  TemplateGroup,
  TemplateGroupAdditionalReceiver,
  UpdateTemplateGroupAdditionalReceiverRequest,
  UpdateTemplateGroupRequest,
} from '@models/notifications';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TemplateGroupsService {
  private readonly baseUrl = `${environment.bffUrl}/template-groups`;
  constructor(private httpClient: HttpClient) {}

  getAllTemplateGroups(): Observable<TemplateGroup[]> {
    return this.httpClient.get<TemplateGroup[]>(`${this.baseUrl}`);
  }

  getOneTemplateGroup(templateGroupId: string): Observable<TemplateGroup> {
    return this.httpClient.get<TemplateGroup>(`${this.baseUrl}/${templateGroupId}`);
  }

  createTemplateGroup(createReq: CreateTemplateGroupRequest): Observable<TemplateGroup> {
    return this.httpClient.post<TemplateGroup>(`${this.baseUrl}`, createReq);
  }

  updateTemplateGroup(templateGroupId: string, updateReq: UpdateTemplateGroupRequest): Observable<TemplateGroup> {
    return this.httpClient.put<TemplateGroup>(`${this.baseUrl}/${templateGroupId}`, updateReq);
  }

  deleteTemplateGroup(templateGroupId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${templateGroupId}`);
  }

  getAllAdditionalReceivers(templateGroupId: string): Observable<TemplateGroupAdditionalReceiver[]> {
    return this.httpClient.get<TemplateGroupAdditionalReceiver[]>(`${this.baseUrl}/${templateGroupId}/receivers`);
  }

  getOneAdditionalReceiver(templateGroupId: string, additionalReceiverId: string): Observable<TemplateGroupAdditionalReceiver> {
    return this.httpClient.get<TemplateGroupAdditionalReceiver>(`${this.baseUrl}/${templateGroupId}/receivers/${additionalReceiverId}`);
  }

  createAdditionalReceiver(
    templateGroupId: string,
    createReq: CreateTemplateGroupAdditionalReceiverRequest,
  ): Observable<TemplateGroupAdditionalReceiver> {
    return this.httpClient.post<TemplateGroupAdditionalReceiver>(`${this.baseUrl}/${templateGroupId}/receivers`, createReq);
  }

  updateAdditionalReceiver(
    templateGroupId: string,
    additionalReceiverId: string,
    updateReq: UpdateTemplateGroupAdditionalReceiverRequest,
  ): Observable<TemplateGroupAdditionalReceiver> {
    return this.httpClient.put<TemplateGroupAdditionalReceiver>(
      `${this.baseUrl}/${templateGroupId}/receivers/${additionalReceiverId}`,
      updateReq,
    );
  }

  deleteAdditionalReceiver(templateGroupId: string, additionalReceiverId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${templateGroupId}/receivers/${additionalReceiverId}`);
  }
}
