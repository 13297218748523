import { HttpErrorResponse } from '@angular/common/http';
import { AssessmentToggleOptions, IAssessment } from '@models/products-and-orders/assessments';
import { createAction, props } from '@ngrx/store';
import { State } from './assessments.reducer';

const NAMESPACE = '[Assessments]';

export const getAssessmentsList = createAction(`${NAMESPACE} GetAssessmentsList`, props<{ toggle: AssessmentToggleOptions }>());
export const getAssessmentsListComplete = createAction(`${NAMESPACE} GetAssessmentsListComplete`, props<{ assessments: IAssessment[] }>());
export const getAssessmentsListError = createAction(`${NAMESPACE} GetAssessmentsListError`, props<{ error: HttpErrorResponse }>());

export const selectAssessment = createAction(`${NAMESPACE} SelectAssessment`, props<{ id: string }>());
export const selectAssessmentComplete = createAction(`${NAMESPACE} SelectAssessmentComplete`, props<{ assessment: IAssessment }>());
export const selectAssessmentError = createAction(`${NAMESPACE} SelectAssessmentError`, props<{ error: HttpErrorResponse }>());

export const downloadFile = createAction(`${NAMESPACE} DownloadFile`, props<{ id: string; fileId: string }>());
export const downloadFileComplete = createAction(`${NAMESPACE} DownloadFileComplete`, props<{ url: string }>());
export const downloadFileError = createAction(`${NAMESPACE} DownloadFileError`, props<{ error: HttpErrorResponse }>());

export const goToAssessmentsListPage = createAction(`${NAMESPACE} GoToListPage`);
export const goToAssessmentActions = createAction(`${NAMESPACE} GoToAssessmentActions`, props<{ id: string }>());
export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
