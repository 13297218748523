import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateExternalTagRequest,
  CreateExternalTemplateRequest,
  ExternalTag,
  ExternalTagResponse,
  ExternalTemplate,
  ExternalTemplateResponse,
  UpdateExternalTagRequest,
  UpdateExternalTemplateRequest,
} from '@models/notifications/external-templates/external-templates.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExternalTemplatesService {
  private readonly url = `${environment.bffUrl}/external-templates`;
  constructor(private httpClient: HttpClient) {}

  getAllTemplates(): Observable<ExternalTemplateResponse[]> {
    return this.httpClient.get<ExternalTemplate[]>(this.url);
  }

  getTemplate(templateId: string): Observable<ExternalTemplateResponse> {
    return this.httpClient.get<ExternalTemplate>(`${this.url}/${templateId}`);
  }

  addTemplate(template: CreateExternalTemplateRequest): Observable<ExternalTemplateResponse> {
    return this.httpClient.post<ExternalTemplate>(this.url, template);
  }

  updateTemplate(templateId: string, template: UpdateExternalTemplateRequest): Observable<ExternalTemplateResponse> {
    return this.httpClient.put<ExternalTemplate>(`${this.url}/${templateId}`, template);
  }

  deleteTemplate(templateId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${templateId}`);
  }

  getAllTags(templateId: string): Observable<ExternalTagResponse[]> {
    return this.httpClient.get<ExternalTag[]>(`${this.url}/${templateId}/tags`);
  }

  getTag(templateId: string, tagId: string): Observable<ExternalTagResponse> {
    return this.httpClient.get<ExternalTag>(`${this.url}/${templateId}/tags/${tagId}`);
  }

  addTag(templateId: string, tag: CreateExternalTagRequest): Observable<ExternalTagResponse> {
    return this.httpClient.post<ExternalTag>(`${this.url}/${templateId}/tags`, tag);
  }

  updateTag(templateId: string, tagId: string, tag: UpdateExternalTagRequest): Observable<ExternalTagResponse> {
    return this.httpClient.put<ExternalTag>(`${this.url}/${templateId}/tags/${tagId}`, tag);
  }

  deleteTag(templateId: string, tagId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${templateId}/tags/${tagId}`);
  }
}
