import { ActionLog, ActionLogsListQuery } from '@models/index';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Action Logs]';

export const query = createAction(`${NAMESPACE} Query`, props<{ actionLogsQuery: ActionLogsListQuery }>());
export const queryComplete = createAction(`${NAMESPACE} QueryComplete`, props<{ actionLogs: ActionLog[] }>());
export const queryError = createAction(`${NAMESPACE} QueryError`);

export const getActionLogById = createAction(`${NAMESPACE} GetActionLogById`, props<{ actionId: string }>());
export const getActionLogComplete = createAction(`${NAMESPACE} GetActionLogComplete`, props<{ actionLog: ActionLog }>());
export const getActionLogError = createAction(`${NAMESPACE} GetActionLogError`);

export const openFilterModal = createAction(`${NAMESPACE} OpenFilterModal`);
export const submitFilterModal = createAction(`${NAMESPACE} SubmitFilterModal`, props<{ actionLogsQuery: ActionLogsListQuery }>());

export const goToActionLogsList = createAction(`${NAMESPACE} GoToActionLogsList`);
export const goToActionLogsDetails = createAction(`${NAMESPACE} GoToActionLogsDetails`, props<{ actionId: string }>());
