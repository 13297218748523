import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.notificationLogs);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectLogs = createSelector(selectModuleState, state => state.logs);

export const selectSelectedLog = createSelector(selectModuleState, state => state.selectedLog);
export const selectSelectedLogId = createSelector(selectModuleState, state => state.selectedLog && state.selectedLog.id);
export const selectSelectedLogLoading = createSelector(selectModuleState, state => state.selectedLogLoading);
