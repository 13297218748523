import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Branch, BranchCreate, BranchUpdate } from '@models/file-management/branch/branch.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BranchesService {
  private readonly url = `${environment.bffUrl}/file-management/branches`;
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<Branch[]> {
    return this.httpClient.get<Branch[]>(this.url);
  }

  get(id: string): Observable<Branch> {
    return this.httpClient.get<Branch>(`${this.url}/${id}`);
  }

  add(branch: BranchCreate): Observable<Branch> {
    return this.httpClient.post<Branch>(this.url, branch);
  }

  update(id: string, branch: BranchUpdate): Observable<Branch> {
    return this.httpClient.put<Branch>(`${this.url}/${id}`, branch);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }
}
