import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.permissions);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectPermissions = createSelector(selectModuleState, state => state.permissions);

export const selectSelectedPermission = createSelector(selectModuleState, state => state.selectedPermission);
export const selectSelectedPermissionId = createSelector(selectModuleState, state => state.selectedPermission?.id ?? null);
export const selectSelectedPermissionLoading = createSelector(selectModuleState, state => state.selectPermissionLoading);

export const selectRoles = createSelector(selectModuleState, state => state.roles);
export const selectIsRolesLoading = createSelector(selectModuleState, state => state.rolesLoading);

export const selectAttributes = createSelector(selectModuleState, state => state.attributes);
export const selectAttributesLoading = createSelector(selectModuleState, state => state.attributesLoading);
