import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddFuncRoleAssignment, Attribute, FuncRoleAssignment, RoleInfo } from '@models/authorization';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class FunctionalRolesService {
  constructor(private httpClient: HttpClient) {}

  private readonly url = `${environment.bffUrl}/authorization/functional-roles`;

  get(subtype = '', attributes: Attribute[]): Observable<FuncRoleAssignment[]> {
    return this.httpClient.get<FuncRoleAssignment[]>(`${this.url}/users`, {
      params: { subtype, ...this.mapAttributesToQueryParams(attributes) },
    });
  }

  getRoles(subtype = ''): Observable<RoleInfo[]> {
    return this.httpClient.get<RoleInfo[]>(this.url, { params: { subtype } });
  }

  add(funcRoleAssignment: AddFuncRoleAssignment, subtype = ''): Observable<FuncRoleAssignment> {
    return this.httpClient.post<FuncRoleAssignment>(`${this.url}`, funcRoleAssignment, { params: { subtype } });
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  private mapAttributesToQueryParams(attributes: Attribute[]): { [param: string]: string } {
    return attributes.reduce((prev, curr, i) => {
      return { ...prev, [`${curr.name}-Attribute`]: curr.value };
    }, {});
  }
}
