import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.announcements);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsLoadingSelectedAnnouncement = createSelector(selectModuleState, state => state.isLoadingSelectedAnnouncement);
export const selectAnnouncements = createSelector(selectModuleState, state => state.announcements);
export const selectCurrentAnnouncement = createSelector(selectModuleState, state => state.selectedAnnouncement);
export const selectCurrentAnnouncementId = createSelector(selectModuleState, state => state.selectedAnnouncement?.id ?? null);
export const selectToggleOption = createSelector(selectModuleState, state => state.toggle);

export const selectAffectedUsers = createSelector(selectModuleState, state => state.affectedUsers);
export const selectAffectedUsersCount = createSelector(selectModuleState, state => state.affectedUsersCount);
export const selectIsLoadingAffectedUsers = createSelector(selectModuleState, state => state.isAffectedUsersLoading);
