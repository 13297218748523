import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.notificationTemplateGroups);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectTemplateGroups = createSelector(selectModuleState, state => state.templateGroups);

export const selectSelectedTemplateGroup = createSelector(selectModuleState, state => state.selectedTemplateGroup);
export const selectSelectedTemplateGroupId = createSelector(
  selectModuleState,
  state => state.selectedTemplateGroup && state.selectedTemplateGroup.id,
);
export const selectSelectedTemplateGroupLoading = createSelector(selectModuleState, state => state.selectedTemplateGroupLoading);
export const selectIsTemplateGroupCreateLoading = createSelector(selectModuleState, state => state.templateGroupCreateLoading);

export const selectAdditionalReceivers = createSelector(selectModuleState, state => state.additionalReceivers);
export const selectAdditionalReceiversLoading = createSelector(selectModuleState, state => state.additionalReceiversLoading);
export const selectSelectedAdditionalReceiver = createSelector(selectModuleState, state => state.selectedAdditionalReceiver);
export const selectSelectedAdditionalReceiverId = createSelector(
  selectModuleState,
  state => state.selectedAdditionalReceiver && state.selectedAdditionalReceiver.id,
);
export const selectSelectedAdditionalReceiverLoading = createSelector(selectModuleState, state => state.selectedAdditionalReceiverLoading);
export const selectIsAdditionalReceiverCreateLoading = createSelector(selectModuleState, state => state.additionalReceiverCreateLoading);
