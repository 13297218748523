import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.roleGroups);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectRoleGroups = createSelector(selectModuleState, state => state.roleGroups);
export const selectGroupedRoles = createSelector(selectModuleState, state => state.groupedRoles);

export const selectActiveRoleGroup = createSelector(selectModuleState, state => state.selectedRoleGroup);
export const selectActiveRoleGroupId = createSelector(selectModuleState, state => state.selectedRoleGroup?.id ?? null);
export const isActiveRoleGroupLoading = createSelector(selectModuleState, state => state.selectedRoleGroupLoading);

export const selectedRoleGroupRoles = createSelector(selectModuleState, state => state.roleGroupRoles);
export const isSelectedRoleGroupRolesLoading = createSelector(selectModuleState, state => state.roleGroupRolesLoading);
