import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { identitySelectors } from '@appState';
import { Store, select } from '@ngrx/store';
import { Observable, filter, map, pipe, switchMap } from 'rxjs';
import { AppState } from '../app-state/app.store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.store.pipe(
      select(identitySelectors.selectIsLoading),
      pipe(
        filter(isLoading => isLoading !== true),
        switchMap(() =>
          this.store.pipe(
            select(identitySelectors.selectIsAuthenticated),
            map(isAuthenticated => {
              if (!isAuthenticated && window.location.pathname !== '/') {
                localStorage.setItem('redirectUri', window.location.pathname);
                return false;
              }

              const redirectUri = localStorage.getItem('redirectUri');
              if (redirectUri) {
                localStorage.removeItem('redirectUri');
                this.router.navigateByUrl(redirectUri);
              }

              return true;
            }),
          ),
        ),
      ),
    );
  }
}
