import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isNotNullOrUndefined } from '@highlander/common/helpers/util';
import { ActionLogsListQuery } from '@models/action-log/action-log.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ActionLogsFiltersComponent } from 'src/app/app-pages/action-logs/pages/action-logs-filters/action-logs-filters.component';
import * as fromActions from './action-logs.actions';
import { ActionLogsService } from './action-logs.service';

@Injectable()
export class ActionLogsEffects {
  onGetActionLogsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.query),
      switchMap(({ actionLogsQuery }) => {
        return this.actionLogsService.getActionLogs(actionLogsQuery).pipe(
          map(actionLogs => fromActions.queryComplete({ actionLogs })),
          catchError(() => of(fromActions.queryError())),
        );
      }),
    ),
  );

  onGetActionLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getActionLogById),
      switchMap(({ actionId }) => {
        return this.actionLogsService.getActionLog(actionId).pipe(
          map(actionLog => fromActions.getActionLogComplete({ actionLog })),
          catchError(() => of(fromActions.getActionLogError())),
        );
      }),
    ),
  );

  onShowActionLogsFilterModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openFilterModal),
      switchMap(() =>
        this.dialog
          .open(ActionLogsFiltersComponent, {
            data: JSON.parse(localStorage.getItem('actionLogFilter')!),
            panelClass: 'custom-modal-container',
          })
          .afterClosed()
          .pipe(
            take(1),
            // "undefined" = closed via backdrop, "null" = closed via "Cancel" button
            filter(isNotNullOrUndefined),
            map((actionLogsQuery: ActionLogsListQuery) => fromActions.query({ actionLogsQuery })),
          ),
      ),
    ),
  );

  onSubmitActionLogsFilterModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.submitFilterModal),
        map(({ actionLogsQuery }) => localStorage.setItem('actionLogFilter', JSON.stringify(actionLogsQuery))),
      ),
    { dispatch: false },
  );

  onGoToActionLogsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToActionLogsList),
        switchMap(() => this.router.navigate(['/', 'action-logs'])),
      ),
    { dispatch: false },
  );

  onGoToActionLogDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToActionLogsDetails),
        switchMap(({ actionId }) => this.router.navigate(['/', 'action-logs', actionId])),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private actionLogsService: ActionLogsService,
    private dialog: MatDialog,
    private router: Router,
  ) {}
}
