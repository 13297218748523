import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { externalTemplatesSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, combineLatest, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './external-templates.actions';
import { ExternalTemplatesService } from './external-templates.service';

@Injectable()
export class ExternalTemplatesEffects {
  onGetTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllTemplates),
      switchMap(() =>
        this.externalTemplatesService.getAllTemplates().pipe(
          map(templates => fromActions.getAllTemplatesComplete({ templates })),
          catchError(err => of(fromActions.getAllTemplatesError({ err }))),
        ),
      ),
    ),
  );

  onAddTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTemplate),
      switchMap(({ template }) =>
        this.externalTemplatesService.addTemplate(template).pipe(
          map(template => fromActions.addTemplateComplete({ template })),
          catchError(err => of(fromActions.addTemplateError({ err }))),
        ),
      ),
    ),
  );

  onAddTemplateComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addTemplateComplete),
        switchMap(response => {
          return this.router.navigate(['notifications', 'external-templates', response.template.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUpdateTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTemplate),
      switchMap(({ templateId, template }) =>
        this.externalTemplatesService.updateTemplate(templateId, template).pipe(
          map(template => fromActions.updateTemplateComplete({ template })),
          catchError(err => of(fromActions.updateTemplateError({ err }))),
        ),
      ),
    ),
  );

  onSelectTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectTemplate),
      switchMap(({ templateId }) =>
        this.store.pipe(
          select(externalTemplatesSelectors.selectTemplates),
          take(1),
          switchMap(templates => {
            if (templates.length) {
              const template = templates.find(t => t.id === templateId)!;
              return of(fromActions.selectTemplateComplete({ template }));
            } else {
              return this.externalTemplatesService.getTemplate(templateId).pipe(
                map(template => fromActions.selectTemplateComplete({ template })),
                catchError(err => of(fromActions.selectTemplateError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onDeleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTemplate),
      switchMap(() =>
        this.store.pipe(
          select(externalTemplatesSelectors.selectSelectedTemplate),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(template =>
            this.externalTemplatesService.deleteTemplate(template.id).pipe(
              map(() => fromActions.deleteSelectedTemplateComplete()),
              catchError(err => of(fromActions.deleteSelectedTemplateError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteTemplateComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTemplateComplete),
      tap(() => this.router.navigate(['notifications', 'external-templates'])),
      map(() => fromActions.getAllTemplates()),
    ),
  );

  onGetTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllTags),
      switchMap(({ templateId }) =>
        this.externalTemplatesService.getAllTags(templateId).pipe(
          map(tags => fromActions.getAllTagsComplete({ tags })),
          catchError(err => of(fromActions.getAllTagsError({ err }))),
        ),
      ),
    ),
  );

  onAddTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTag),
      switchMap(({ templateId, tag }) =>
        this.externalTemplatesService.addTag(templateId, tag).pipe(
          map(tag => fromActions.addTagComplete({ templateId, tag })),
          catchError(err => of(fromActions.addTagError({ err }))),
        ),
      ),
    ),
  );

  onAddTagComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addTagComplete),
        switchMap(({ templateId, tag }) => {
          return this.router.navigate(['notifications', 'external-templates', templateId, 'tags', tag.id, 'details']);
        }),
      ),
    { dispatch: false },
  );

  onUpdateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTag),
      switchMap(({ templateId, tagId, tag }) =>
        this.externalTemplatesService.updateTag(templateId, tagId, tag).pipe(
          map(tag => fromActions.updateTagComplete({ tag })),
          catchError(err => of(fromActions.updateTagError({ err }))),
        ),
      ),
    ),
  );

  onSelectTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectTag),
      switchMap(({ templateId, tagId }) =>
        this.store.pipe(
          select(externalTemplatesSelectors.selectTags),
          take(1),
          switchMap(tags => {
            if (tags.length) {
              const tag = tags.find(t => t.id === tagId)!;
              return of(fromActions.selectTagComplete({ tag }));
            } else {
              return this.externalTemplatesService.getTag(templateId, tagId).pipe(
                map(tag => fromActions.selectTagComplete({ tag })),
                catchError(err => of(fromActions.selectTagError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onDeleteTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTag),
      switchMap(() =>
        combineLatest({
          templateId: this.store.pipe(select(externalTemplatesSelectors.selectSelectedTemplateId), filter(isNotNullOrUndefined), take(1)),
          tagId: this.store.pipe(select(externalTemplatesSelectors.selectSelectedTagId), filter(isNotNullOrUndefined), take(1)),
        }).pipe(
          switchMap(({ templateId, tagId }) =>
            this.externalTemplatesService.deleteTag(templateId, tagId).pipe(
              map(() => fromActions.deleteSelectedTagComplete({ templateId })),
              catchError(err => of(fromActions.deleteSelectedTagError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteTagComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedTagComplete),
      tap(({ templateId }) => this.router.navigate(['notifications', 'external-templates', templateId, 'tags'])),
      map(({ templateId }) => fromActions.getAllTags({ templateId })),
    ),
  );

  onGoToTemplateActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTemplateActions),
        tap(({ templateId }) => this.router.navigate(['notifications', 'external-templates', templateId])),
      ),
    { dispatch: false },
  );

  onGoToTemplatesListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToTemplatesListPage),
      tap(() => this.router.navigate(['notifications', 'external-templates'])),
      map(() => fromActions.resetState({ selectedTemplate: null })),
    ),
  );

  onGoToTagActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTagActions),
        switchMap(({ tagId }) =>
          this.store.pipe(
            select(externalTemplatesSelectors.selectSelectedTemplate),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['notifications', 'external-templates', id, 'tags', tagId])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToTagListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToTagsListPage),
        switchMap(() =>
          this.store.pipe(
            select(externalTemplatesSelectors.selectSelectedTemplate),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['notifications', 'external-templates', id, 'tags'])),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private externalTemplatesService: ExternalTemplatesService,
  ) {}
}
