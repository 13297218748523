import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { agreementSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { NotificationsService } from 'cui-components';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppConfigService } from 'src/app/infrastructure';
import { AppState } from '../app.store';
import * as fromActions from './agreements.actions';
import { AgreementsService } from './agreements.service';
@Injectable()
export class AgreementsEffects {
  onGetAgreements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllAgreements),
      switchMap(() =>
        this.agreementsService.getAgreements().pipe(
          map(agreements => fromActions.getAllAgreementsComplete({ agreements })),
          catchError(err => of(fromActions.getAllAgreementsError({ err }))),
        ),
      ),
    ),
  );

  onGetLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getLogs),
      switchMap(({ id }) =>
        this.agreementsService.getLogs(id).pipe(
          map(logs => fromActions.getLogsComplete({ logs })),
          catchError(err => of(fromActions.getLogsError({ err }))),
        ),
      ),
    ),
  );

  onGetVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getVersions),
      switchMap(({ id }) =>
        this.agreementsService.getVersions(id).pipe(
          map(versions => fromActions.getVersionsComplete({ versions })),
          catchError(err => of(fromActions.getVersionsError({ err }))),
        ),
      ),
    ),
  );

  onSelectAgreementListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAgreement),
      switchMap(({ id }) =>
        this.agreementsService.getAgreement(id).pipe(
          map(agreement => fromActions.selectAgreementComplete({ agreement })),
          catchError(err => of(fromActions.selectAgreementError({ err }))),
        ),
      ),
    ),
  );

  onSelectAgreementVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAgreementVersion),
      switchMap(({ agreementId, versionId }) =>
        this.agreementsService.getAgreementVersion(agreementId, versionId).pipe(
          map(version => fromActions.selectAgreementVersionComplete({ version })),
          catchError(err => of(fromActions.selectAgreementVersionError({ err }))),
        ),
      ),
    ),
  );

  onAddNewAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewAgreement),
      switchMap(({ agreement }) =>
        this.agreementsService.addAgreement(agreement).pipe(
          map(agreement => fromActions.addNewAgreementComplete({ agreement })),
          catchError(err => of(fromActions.addNewAgreementError({ err }))),
        ),
      ),
    ),
  );

  onAddNewAgreementVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addNewAgreementVersion),
      switchMap(({ version }) =>
        this.store.pipe(
          select(agreementSelectors.selectSelectedAgreementId),
          take(1),
          switchMap(id =>
            this.agreementsService.addAgreementVersion(id!, version).pipe(
              map(version => fromActions.addNewAgreementVersionComplete({ version })),
              catchError(err => of(fromActions.addNewAgreementVersionsError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onUpdateAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAgreement),
      switchMap(({ agreement, id }) =>
        this.agreementsService.updateAgreement(id, agreement).pipe(
          map(agreement => fromActions.updateAgreementComplete({ agreement })),
          catchError(err => of(fromActions.updateAgreementError({ err }))),
        ),
      ),
    ),
  );

  onUpdateAgreementVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateAgreementVersion),
      switchMap(({ version, agreementId, versionId }) =>
        this.agreementsService.updateAgreementVersion(agreementId, versionId, version).pipe(
          map(version => fromActions.updateAgreementVersionComplete({ version })),
          catchError(err => of(fromActions.updateAgreementVersionError({ err }))),
        ),
      ),
    ),
  );

  onDeleteAgreement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAgreement),
      switchMap(() =>
        this.store.pipe(
          select(agreementSelectors.selectSelectedAgreementId),
          take(1),
          switchMap(id =>
            this.agreementsService.deleteAgreement(id!).pipe(
              map(() => fromActions.deleteSelectedAgreementComplete()),
              catchError(err => of(fromActions.deleteSelectedAgreementError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteAgreementVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAgreementVersion),
      switchMap(() =>
        this.store.pipe(
          select(agreementSelectors.selectSelectedVersion),
          take(1),
          switchMap(version =>
            this.agreementsService.deleteAgreementVersion(version!.agreementId, version!.id).pipe(
              map(() => fromActions.deleteSelectedAgreementVersionComplete({ agreementId: version!.agreementId })),
              catchError(err => of(fromActions.deleteSelectedAgreementVersionError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onPublishAgreementVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.publishSelectedAgreementVersion),
      switchMap(() =>
        this.store.pipe(
          select(agreementSelectors.selectSelectedVersion),
          take(1),
          switchMap(version =>
            this.agreementsService.publishAgreementVersion(version!.agreementId, version!.id).pipe(
              map(version => fromActions.publishSelectedAgreementVersionComplete({ version })),
              catchError(err => of(fromActions.publishSelectedAgreementVersionError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onSelectAgreementError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAgreementError),
      map(() => fromActions.goToListPage()),
    ),
  );

  onDeleteAgreementComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAgreementComplete),
      tap(() => this.router.navigate(['agreements'])),
      map(() => fromActions.getAllAgreements()),
    ),
  );

  onDeleteAgreementVersionComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedAgreementVersionComplete),
      tap(({ agreementId }) => this.router.navigate(['agreements', agreementId, 'versions'])),
      map(({ agreementId }) => fromActions.getVersions({ id: agreementId })),
    ),
  );

  onPublishAgreementVersionComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.publishSelectedAgreementVersionComplete),
      tap(({ version }) => this.router.navigate(['agreements', version.agreementId, 'versions', version.id, 'details'])),
      map(({ version }) => fromActions.getVersions({ id: version.agreementId })),
    ),
  );

  onAddAgreementComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewAgreementComplete),
        tap(({ agreement }) => this.router.navigate(['agreements', agreement.id, 'details'])),
      ),
    { dispatch: false },
  );

  onAddAgreementVersionComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewAgreementVersionComplete),
        tap(({ version }) => this.router.navigate(['agreements', version.agreementId, 'versions', version.id, 'details'])),
      ),
    { dispatch: false },
  );

  onCopySelectedAgreementPublicLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.copySelectedAgreementPublicLink),
        switchMap(() =>
          this.store.pipe(
            select(agreementSelectors.selectSelectedAgreement),
            take(1),
            map(agreement => {
              this.clipboard.copy(`${this.appConfig.config['FRONTEND_PORTAL_URL']}/agreements/${agreement!.publicLink}`);
              this.notifications.showNotification('Copied successfuly!');
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToAgreementActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToAgreementActions),
        tap(({ id }) => this.router.navigate(['agreements', id])),
      ),
    { dispatch: false },
  );

  onGoToAgreementVersionActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToAgreementVersionActions),
        tap(({ agreementId, versionId }) => this.router.navigate(['agreements', agreementId, 'versions', versionId])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['agreements'])),
      map(() => fromActions.resetState({ selectedAgreement: null, logs: [], versions: [], selectedVersion: null })),
    ),
  );

  onGoToAgreementVersionListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToAgreementVersionListPage),
      tap(({ agreementId }) => this.router.navigate(['agreements', agreementId, 'versions'])),
      map(() => fromActions.resetState({ selectedVersion: null })),
    ),
  );

  onGoToSelectedAgreementActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToSelectedAgreementActions),
      switchMap(() =>
        this.store.pipe(
          select(agreementSelectors.selectSelectedAgreementId),
          take(1),
          tap(id => this.router.navigate(['agreements', id])),
          map(() => fromActions.resetState({ versions: [], selectedVersion: null })),
        ),
      ),
    ),
  );

  onGoToSelectedAgreementVersionActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedAgreementVersionActions),
        switchMap(() =>
          this.store.pipe(
            select(agreementSelectors.selectSelectedVersion),
            take(1),
            tap(version => this.router.navigate(['agreements', version!.agreementId, 'versions', version!.id])),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly agreementsService: AgreementsService,
    private readonly router: Router,
    private readonly clipboard: Clipboard,
    private readonly appConfig: AppConfigService,
    private readonly notifications: NotificationsService,
  ) {}
}
