import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonDataType, GUID } from '@models/common';
import {
  CreateDocumentRequest,
  CreateDocumentResponse,
  Document,
  DocumentCommonDataObject,
  DocumentDownloadResponse,
  DocumentListItem,
  NewFileUploadResponse,
  UpdateDocumentRequest,
} from '@models/documents';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentsService {
  private readonly url = `${environment.bffUrl}/documents`;

  constructor(private httpClient: HttpClient) {}

  getDocuments(): Observable<DocumentListItem[]> {
    return this.httpClient.get<DocumentListItem[]>(this.url);
  }

  updateCommonData(id: GUID, type: CommonDataType, data: DocumentCommonDataObject[]): Observable<Document> {
    return this.httpClient.put<Document>(`${this.url}/${id}/common-data`, { type, data });
  }

  uploadNewFileToExistingDoc(id: GUID, fileName: string): Observable<NewFileUploadResponse> {
    return this.httpClient.post<NewFileUploadResponse>(`${this.url}/${id}/upload-file`, { fileName });
  }

  updateDocument(doc: UpdateDocumentRequest): Observable<Document> {
    return this.httpClient.put<Document>(`${this.url}/${doc.id}`, doc);
  }

  documentPublishment(id: GUID, publishDate: string | undefined, wantToPublish: boolean): Observable<Document> {
    return this.httpClient.post<Document>(`${this.url}/${id}/${wantToPublish ? 'publish' : 'unpublish'}`, { publishDate });
  }

  getDocument(id: string): Observable<Document> {
    return this.httpClient.get<Document>(`${this.url}/${id}`);
  }

  createDocument(docReq: CreateDocumentRequest): Observable<CreateDocumentResponse> {
    return this.httpClient.post<CreateDocumentResponse>(this.url, docReq);
  }

  deleteDocument(id: GUID): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  download(id: GUID): Observable<DocumentDownloadResponse> {
    return this.httpClient.get<DocumentDownloadResponse>(`${this.url}/${id}/download`);
  }
}
